import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import React, { useState } from "react";
import AddItemModal from "./addNewItemTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ClearIcon from '@mui/icons-material/Clear';
import { Form, Formik } from "formik";
import * as yup from "yup";

const tableHeaderCellStyle = {
    textAlign: "center",
    fontWeight: 400,
    verticalAlign: "middle",
    color: "white",
    padding: "5px 5px",
    borderRight: "1px solid white",
};
const tableBodyCellStyle = {
    textAlign: "center",
    fontWeight: 400,
    verticalAlign: "middle",
    padding: "5px 5px",
    width: "100%",
    borderRight: "1px solid #7352C7",
};

const initialData = {
    item_sr_no: 0,
    supplier_item_name: "",
    supplier_log_no: "",
    item_name: "",
    item_sub_category: "",
    log_no: "",
    log_formula: "",
    invoice_length: 0,
    invoice_diameter: 0,
    invoice_cmt: 0,
    indian_cmt: 0,
    physical_length: 0,
    physical_diameter: 0,
    physical_cmt: 0,
    exchange_rate: 0,
    rate_in_currency: 0,
    rate_in_inr: 0,
    amount: 0,
    remark: "",
}

const EditItemDetailsTable = ({ setAddedItemDetailsList, addedItemDetailsList }) => {
    const itemNameList = [];
    const removeRow = (value, index) => {
        // setLoader({ ...loader, removeProduct: true });
        // setTimeout(() => {
        const updateData = [...addedItemDetailsList];
        updateData.splice(index, 1);
        setAddedItemDetailsList(updateData);
        // setLoader({ ...loader, removeProduct: false });
        // }, 200);
    };
    const updateRow = (values, index) => {
        // setLoader({ ...loader, removeProduct: true });
        // setTimeout(() => {
        const updateData = [...addedItemDetailsList];
        updateData.splice(index, 1,values);
        setAddedItemDetailsList(updateData);
        // setLoader({ ...loader, removeProduct: false });
        // }, 200);
    };
    const [data, setData] = useState(initialData);

    const validationSchema = yup.object({
        supplier_item_name: yup.string("Enter Supplier Name").required("Supplier Name is required"),
        supplier_log_no: yup.string("Enter Supplier Name").required("Supplier Name is required"),
        // item_name: yup.string().required("Required"),
        // item_sub_category: yup.string().required("Required"),
        log_no: yup.string().required("Required"),
        // log_formula: yup.string().required("Required"),
        invoice_length: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
        invoice_diameter: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
        invoice_cmt: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
        indian_cmt: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
        physical_length: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
        physical_diameter: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
        physical_cmt: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
        exchange_rate: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
        rate_in_currency: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
        rate_in_inr: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
        amount: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    });

    const handleSubmit = async (values) => {

    };
    return (
        <div>
            <Formik
                validateOnChange={false}
                initialValues={data}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, values, errors, initialValues }) => (
                    <Form noValidate autoComplete="off">
                        {console.log(addedItemDetailsList)}
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow
                                        sx={{
                                            bgcolor: "#7352C7",
                                            color: "white",
                                            "& .MuiTableCell-root": {
                                                // py: 2,
                                            },
                                        }}
                                    >
                                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "60px" }}>Sr No.</TableCell>
                                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Item Name</TableCell>
                                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Log No.</TableCell>
                                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Name</TableCell>
                                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Sub Category</TableCell>
                                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Log No.</TableCell>
                                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Log Formula</TableCell>
                                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Invoice Length</TableCell>
                                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Invoice Diameter</TableCell>
                                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Invoice CMT</TableCell>

                                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Indian CMT</TableCell>
                                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Physical Length</TableCell>
                                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Physical Diameter</TableCell>
                                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Physical CMT</TableCell>
                                        {/* {values?.currency !== "INR" ? ( */}
                                        {true ? <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Exchange Rate</TableCell> : ""}
                                        {/* {values?.currency !== "INR" ? ( */}
                                        {true ? <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate in Currency</TableCell> : ""}
                                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate in INR</TableCell>
                                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Amount</TableCell>
                                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
                                        <TableCell
                                            sx={{
                                                textAlign: "center",
                                                minWidth: "100px",
                                                verticalAlign: "middle",
                                                color: "white",
                                                position: "sticky",
                                                right: 0,
                                                zIndex: 1,
                                                bgcolor: "#7352C7",
                                            }}
                                        >
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {addedItemDetailsList && addedItemDetailsList.length ? (
                                    <TableBody>
                                        {addedItemDetailsList &&
                                            addedItemDetailsList?.map((row, i) => (
                                                values?.item_sr_no !== row?.item_sr_no ?
                                                    <TableRow key={i}>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>{row?.item_sr_no}</TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>{row.supplier_item_name}</TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>{row.supplier_log_no}</TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>{row?.item_name}</TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>{row?.item_sub_category}</TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>{row.log_no}</TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>{row?.log_formula}</TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>{row.invoice_length}</TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>{row.invoice_diameter}</TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>{row.invoice_cmt}</TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>{row.indian_cmt}</TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>{row.physical_length}</TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>{row.physical_diameter}</TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>{row.physical_cmt}</TableCell>

                                                        {row?.currency !== "INR" ? <TableCell sx={{ ...tableBodyCellStyle }}>{row.exchange_rate}</TableCell> : ""}

                                                        {row?.currency !== "INR" ? <TableCell sx={{ ...tableBodyCellStyle }}>{row.rate_in_currency}</TableCell> : ""}
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>{row.rate_in_inr}</TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>{row.amount}</TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>{row.remark}</TableCell>
                                                        <TableCell
                                                            sx={{
                                                                // textAlign: "center",
                                                                minWidth: "100%",
                                                                verticalAlign: "middle",
                                                                color: "white",
                                                                px: 1,
                                                                position: "sticky",
                                                                right: 0,
                                                                zIndex: 1,
                                                                bgcolor: "white",
                                                                display: "flex",
                                                            }}
                                                        >
                                                            {/* <LoadingButton
                        //   loading={loader.removeProduct}
                        size="small"
                        variant="outlined"
                        color="warning"
                        onClick={() => removeRow(row, i)}
                        sx={{
                          padding:"0px"
                        }}
                      >
                        <EditIcon />
                      </LoadingButton> */}
                                                            {/* <LoadingButton
                        //   loading={loader.removeProduct}
                        size="small"
                        variant="outlined"
                        color="error"
                        onClick={() => removeRow(row, i)}
                        sx={{
                          padding:"0px"
                        }}
                      >
                        <DeleteIcon />
                      </LoadingButton> */}
                                                            <IconButton color="warning" size="small" onClick={() => setData(row)}>
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton color="error" size="small" onClick={() => removeRow(row, i)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow> :
                                                    <TableRow sx={{background:"rgb(115 82 199 / 20%) !important"}}>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>{values?.item_sr_no}</TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>
                                                            <TextField
                                                                // type="number"
                                                                size="small"
                                                                value={values.supplier_item_name}
                                                                error={!!errors?.supplier_item_name}
                                                                helperText={errors?.supplier_item_name || ""}
                                                                name="supplier_item_name"
                                                                onChange={(e) => setFieldValue("supplier_item_name", e.target.value)}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>
                                                            <TextField
                                                                // type="number"
                                                                size="small"
                                                                value={values.supplier_log_no}
                                                                error={!!errors?.supplier_log_no}
                                                                helperText={errors?.supplier_log_no || ""}
                                                                name="supplier_log_no"
                                                                onChange={(e) => setFieldValue("supplier_log_no", e.target.value)}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>
                                                            <Autocomplete
                                                                size="small"
                                                                id="tags-standard"
                                                                options={itemNameList || []}
                                                                name="item_name"
                                                                value={itemNameList.filter((item) => item.item_name === values?.item_name)?.[0] || null}
                                                                getOptionLabel={(option) => option?.item_name}
                                                                onChange={(e, newValue) => {
                                                                    if (newValue == null) {
                                                                        // const updateData = [...values["item_details"]];
                                                                        // updateData[i] = initialValues.item_details[0];
                                                                        // setFieldValue("item_details", updateData);
                                                                    } else {
                                                                        // handleOnChange(e, i, setFieldValue, values, "item_name", newValue?.item_name);
                                                                    }
                                                                }}
                                                                renderOption={(props, option) => (
                                                                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                                                        {option?.item_name}
                                                                    </Box>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        error={!!errors?.item_name}
                                                                        helperText={errors?.item_name || ""}
                                                                        {...params}
                                                                        variant="outlined"
                                                                        sx={{
                                                                            width: "100%",
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>
                                                            <Autocomplete
                                                                size="small"
                                                                id="tags-standard"
                                                                options={itemNameList || []}
                                                                name="item_sub_category"
                                                                value={itemNameList.filter((item) => item.item_sub_category === values?.item_sub_category)?.[0] || null}
                                                                getOptionLabel={(option) => option?.item_sub_category}
                                                                onChange={(e, newValue) => {
                                                                    if (newValue == null) {
                                                                        // const updateData = [...values["item_details"]];
                                                                        // updateData[i] = initialValues.item_details[0];
                                                                        // setFieldValue("item_details", updateData);
                                                                    } else {
                                                                        // handleOnChange(e, i, setFieldValue, values, "item_sub_category", newValue?.item_sub_category);
                                                                    }
                                                                }}
                                                                renderOption={(props, option) => (
                                                                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                                                        {option?.item_sub_category}
                                                                    </Box>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        error={!!errors?.item_sub_category}
                                                                        helperText={errors?.item_sub_category || ""}
                                                                        {...params}
                                                                        variant="outlined"
                                                                        sx={{
                                                                            width: "100%",
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>
                                                            <TextField
                                                                // type="number"
                                                                size="small"
                                                                value={values.log_no}
                                                                error={!!errors?.log_no}
                                                                helperText={errors?.log_no || ""}
                                                                name="log_no"
                                                                onChange={(e) => setFieldValue("log_no", e.target.value)}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>
                                                            <Autocomplete
                                                                size="small"
                                                                id="tags-standard"
                                                                options={itemNameList || []}
                                                                name="log_formula"
                                                                value={itemNameList.filter((item) => item.log_formula === values?.log_formula)?.[0] || null}
                                                                getOptionLabel={(option) => option?.log_formula}
                                                                onChange={(e, newValue) => {
                                                                    if (newValue == null) {
                                                                        // const updateData = [...values["item_details"]];
                                                                        // updateData[i] = initialValues.item_details[0];
                                                                        // setFieldValue("item_details", updateData);
                                                                    } else {
                                                                        // handleOnChange(e, i, setFieldValue, values, "log_formula", newValue?.log_formula);
                                                                    }
                                                                }}
                                                                renderOption={(props, option) => (
                                                                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                                                        {option?.log_formula}
                                                                    </Box>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        error={!!errors?.log_formula}
                                                                        helperText={errors?.log_formula || ""}
                                                                        {...params}
                                                                        variant="outlined"
                                                                        sx={{
                                                                            width: "100%",
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>
                                                            <TextField
                                                                type="number"
                                                                size="small"
                                                                value={values.invoice_length}
                                                                error={!!errors?.invoice_length}
                                                                helperText={errors?.invoice_length || ""}
                                                                name="invoice_length"
                                                                onChange={(e) => setFieldValue("invoice_length", e.target.value)}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>
                                                            <TextField
                                                                type="number"
                                                                size="small"
                                                                value={values.invoice_diameter}
                                                                error={!!errors?.invoice_diameter}
                                                                helperText={errors?.invoice_diameter || ""}
                                                                name="invoice_diameter"
                                                                onChange={(e) => setFieldValue("invoice_diameter", e.target.value)}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>
                                                            <TextField
                                                                type="number"
                                                                size="small"
                                                                //   disabled={!values?.item_received_pattas > 0}
                                                                error={!!errors?.invoice_cmt}
                                                                helperText={errors?.invoice_cmt || ""}
                                                                value={values.invoice_cmt}
                                                                name="invoice_cmt"
                                                                onChange={(e) => setFieldValue("invoice_cmt", e.target.value)}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>
                                                            <TextField
                                                                type="number"
                                                                size="small"
                                                                //   disabled={!values?.item_received_pattas > 0}
                                                                error={!!errors?.indian_cmt}
                                                                helperText={errors?.indian_cmt || ""}
                                                                value={values.indian_cmt}
                                                                name="indian_cmt"
                                                                onChange={(e) => setFieldValue("indian_cmt", e.target.value)}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>
                                                            <TextField
                                                                type="number"
                                                                size="small"
                                                                //   disabled={!values?.item_received_pattas > 0}
                                                                error={!!errors?.physical_length}
                                                                helperText={errors?.physical_length || ""}
                                                                value={values.physical_length}
                                                                name="physical_length"
                                                                onChange={(e) => setFieldValue("physical_length", e.target.value)}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>
                                                            <TextField
                                                                type="number"
                                                                size="small"
                                                                //   disabled={!values?.item_received_pattas > 0}
                                                                error={!!errors?.physical_diameter}
                                                                helperText={errors?.physical_diameter || ""}
                                                                value={values.physical_diameter}
                                                                name="physical_diameter"
                                                                onChange={(e) => setFieldValue("physical_diameter", e.target.value)}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>
                                                            <TextField
                                                                type="number"
                                                                size="small"
                                                                //   disabled={!values?.item_received_pattas > 0}
                                                                error={!!errors?.physical_cmt}
                                                                helperText={errors?.physical_cmt || ""}
                                                                value={values.physical_cmt}
                                                                name="physical_cmt"
                                                                onChange={(e) => setFieldValue("physical_cmt", e.target.value)}
                                                            />
                                                        </TableCell>

                                                        {values?.currency !== "INR" ? (
                                                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                                                <TextField
                                                                    type="number"
                                                                    size="small"
                                                                    value={values.exchange_rate}
                                                                    error={!!errors?.exchange_rate}
                                                                    helperText={errors?.exchange_rate || ""}
                                                                    name="exchange_rate"
                                                                    onChange={(e) => setFieldValue("exchange_rate", e.target.value)}
                                                                // onChange={(e) => {
                                                                // let itemDetailArray = [...values.item_details];
                                                                // itemDetailArray[i] = {
                                                                //   ...itemDetailArray[i],
                                                                //   exchange_rate: e.target.value,
                                                                // };
                                                                // if (itemDetailArray[i].item_rate_per_sqm_for_currency > 0) {
                                                                //   itemDetailArray[i] = {
                                                                //     ...itemDetailArray[i],
                                                                //     item_rate_per_sqm: e.target.value * itemDetailArray[i].item_rate_per_sqm_for_currency,
                                                                //   };
                                                                // }
                                                                // setFieldValue("exchange_rate", itemDetailArray);
                                                                // }}
                                                                />
                                                            </TableCell>
                                                        ) : (
                                                            ""
                                                        )}

                                                        {values?.currency !== "INR" ? (
                                                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                                                <TextField
                                                                    type="number"
                                                                    size="small"
                                                                    value={values.rate_in_currency}
                                                                    error={!!errors?.rate_in_currency}
                                                                    helperText={errors?.rate_in_currency || ""}
                                                                    name="rate_in_currency"
                                                                    onChange={(e) => setFieldValue("rate_in_currency", e.target.value)}
                                                                // onChange={(e) => {
                                                                // let itemDetailArray = [...values.item_details];
                                                                // itemDetailArray[i] = {
                                                                //   ...itemDetailArray[i],
                                                                //   rate_in_currency: e.target.value,
                                                                // };
                                                                // if (itemDetailArray[i].conversion_rate > 0) {
                                                                //   itemDetailArray[i] = {
                                                                //     ...itemDetailArray[i],
                                                                //     item_rate_per_sqm: e.target.value * itemDetailArray[i].conversion_rate,
                                                                //   };
                                                                // }
                                                                // setFieldValue("item_details", itemDetailArray);
                                                                // }}
                                                                />
                                                            </TableCell>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>
                                                            <TextField
                                                                type="number"
                                                                size="small"
                                                                value={values.rate_in_inr}
                                                                error={!!errors?.rate_in_inr}
                                                                helperText={errors?.rate_in_inr || ""}
                                                                name="rate_in_inr"
                                                                onChange={(e) => setFieldValue("rate_in_inr", e.target.value)}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>
                                                            <TextField
                                                                type="number"
                                                                size="small"
                                                                value={values.amount}
                                                                error={!!errors?.amount}
                                                                helperText={errors?.amount || ""}
                                                                name="amount"
                                                                onChange={(e) => setFieldValue("amount", e.target.value)}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ ...tableBodyCellStyle }}>
                                                            <TextField
                                                                multiline
                                                                size="small"
                                                                value={values.remark}
                                                                error={!!errors?.remark}
                                                                sx={{ width: "100%" }}
                                                                helperText={errors?.remark || ""}
                                                                name="remark"
                                                                onChange={(e) => setFieldValue("remark", e.target.value)}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                // textAlign: "center",
                                                                minWidth: "100%",
                                                                verticalAlign: "middle",
                                                                color: "white",
                                                                px: 1,
                                                                position: "sticky",
                                                                right: 0,
                                                                zIndex: 1,
                                                                bgcolor: "white",
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <IconButton color="success" size="small" onClick={() => updateRow(values, i)}>
                                                                <TaskAltIcon />
                                                            </IconButton>
                                                            <IconButton color="error" size="small" onClick={() => {
                                                                setData(initialData)
                                                            }}>
                                                                <ClearIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                            ))}
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={12} align="center">
                                                Data Not Found !!
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EditItemDetailsTable;
