import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

import { LoadingButton } from "@mui/lab";
import FormTextField1 from "app/components/InputField/FormTextField1";
import { addItemName } from "app/services/apis/addItemName";
import { updateItemName } from "app/services/apis/updateItemName";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Axios } from "index";
import updateMachine from "app/services/apis/updateMachine";
import addMachine from "app/services/apis/addMachine";

function AddMachinePage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state } = useLocation();
  console.log("state => ", state);
  const [isSubmitting, setSubmitting] = useState(false);
  const [departments, setDepartments] = useState([]);
  const deptName = {
    machine_name: state?.machine_name ? state?.machine_name : "",
    department: state?.departmentDetails?._id
      ? state?.departmentDetails?._id
      : "",
  };
  const validationSchema = yup.object({
    machine_name: yup
      .string("Enter machine Name")
      .required("Machine Name is required"),
    department: yup
      .string("Select department ")
      .required("Department is required"),
  });

  const onUserSave = async (values) => {
    const body = {
      ...values,
      machine_name: values?.machine_name?.toUpperCase(),
    };
    console.log("machine body => ", body);

    setSubmitting(true);
    if (pathname === "/master/machine/edit") {
      const data = await updateMachine(body, state._id);
      if (data?.data?.statusCode === 200) {
        navigate("/master/machine");
        Swal.fire({
          icon: "success",
          title: "Machine Updated Successfully",
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    } else {
      values.category = values.category?.toUpperCase();
      body.machine_name = body.machine_name.toUpperCase();
      const data = await addMachine(body);
      console.log("data => ", data);
      if (data?.data?.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: "Machine Added Successfully",
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/master/machine");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };

  useEffect(() => {
    const fetchAllCategories = async () => {
      try {
        const res = await Axios.get("department-master/all-depts");
        setDepartments(res?.data?.result);
      } catch (error) {
        console.log("er fetching all categories -> ", error.message);
      }
    };
    fetchAllCategories();
  }, []);
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname === "/master/machine/add"
          ? "Add New Machine "
          : "Edit Machine"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={deptName}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ setFieldValue, values }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    columnGap: 5,
                  }}
                >
                  <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={6}>
                      <FormTextField1
                        name="machine_name"
                        label="Machine Name*"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h5" sx={{}}>
                        Department*
                      </Typography>
                      {/* <Autocomplete
                        multiple
                        name="department"
                        sx={{ width: "100%" }}
                        size="small"
                        id="department"
                        value={departments?.filter((d) =>
                          values?.department?.includes(d?._id)
                        )}
                        options={departments}
                        getOptionLabel={(option) => option.dept_name || ""}
                        onChange={(e, newValue) => {
                          setFieldValue(
                            "department",
                            newValue.map((dept) => dept._id)
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Departments"
                          />
                        )}
                      /> */}
                      <Select
                        fullWidth
                        size="small"
                        value={values?.department}
                        onChange={(e) => {
                          setFieldValue("department", e.target.value);
                        }}
                      >
                        {departments.map((dept) => (
                          <MenuItem value={dept._id}>{dept.dept_name}</MenuItem>
                        ))}
                      </Select>

                      <Div style={{ height: "30px" }}>
                        <ErrorMessage
                          name="department"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Grid>
                  </Grid>
                </Div>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/master/machine");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    size="small"
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}

export default AddMachinePage;
