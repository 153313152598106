import Div from '@jumbo/shared/Div';
import { Box, Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import FilterAccordian from 'app/components/FilterAccordian';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomDatePicker, InwardSrNo, ItemName, SupplierList } from './Filters/FiltersComponents';
import LogTableListing from './LogTableListing';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from 'lodash';
import { fetchLogInventory } from 'app/redux/actions/Inventory/LogInventory/logDispatch';
import FullScreenLoader from 'app/components/ListingPageLoader';
import LogFilters from './Filters';

const ListingLog = () => {
    const permissions = useSelector((state) => state?.userReducer?.user?.[0]?.role_id?.permissions);
    const logInventoryReducer = useSelector((state) => state?.logInventoryReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [sortBy, setSortBy] = useState('updatedAt');
    const [sort, setSort] = useState("desc");
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filters, setFilters] = useState({})
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearch = (search) => {
        setPage(1);
        dispatch(fetchLogInventory({
            search: search,
            sort: sort,
            sortBy: sortBy,
            page: 1,
            limit: limit,
            filter: filters
        }))
    };

    const handleChangeLimit = (event) => {
        setLimit(parseInt(event.target.value));
        setPage(1);
    };

    const debouncedHandleSearch = debounce(handleSearch, 1000);

    useEffect(() => {
        //to deal with search lagging
        if (searchTerm !== "") {
            debouncedHandleSearch(searchTerm);
        }
        return () => {
            debouncedHandleSearch.cancel();
        };
    }, [searchTerm]);

    
    const handleApplyFilter = useCallback((filtersData) => {
        console.log(limit, "opioihisduhviuhfuivb")
        setPage(1);
        dispatch(fetchLogInventory({
            search: searchTerm,
            page: 1,
            limit: limit,
            filter: filtersData
        }))
        setFilters(filtersData)
    }, [limit, searchTerm]);
    
    const handleClearFilter = useCallback((filtersData) => {
        setFilters(filtersData)
        dispatch(fetchLogInventory({
            limit: limit,
            search: searchTerm,
        }))
    }, [limit, searchTerm])
    
    useEffect(() => {
        dispatch(fetchLogInventory({
            search: searchTerm,
            sort: sort,
            sortBy: sortBy,
            page: page,
            limit: limit,
            filter: filters
        }))
    }, [sort, sortBy, page, limit])

    return (
        <Box>
            {logInventoryReducer?.loading && <FullScreenLoader />}
            <Div sx={{ mt: -4 }}>
                <Typography variant="h1">Log Inventory</Typography>
                <Box sx={{ mt: 2 }}>
                    <LogFilters
                        handleSubmit={handleApplyFilter}
                        handleClear={handleClearFilter}
                    />
                </Box>
                <Box sx={{ display: "flex", gap: 1, my: 2, justifyContent: "space-between", flexWrap: "wrap-reverse" }}>
                    {/* <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto">
                        <Tab label="Log" {...a11yProps(0)} />
                        <Tab label="Log History" {...a11yProps(4)} />
                    </Tabs> */}

                    <Box>
                        <TextField
                            size="small"
                            id="search"
                            type="search"
                            label="Search"
                            value={searchTerm}
                            onChange={(e) => {
                                setLoaded(false);
                                setSearchTerm(e.target.value);
                                if (!e.target.value) {
                                    setPage(1)
                                    setSort("desc");
                                    setSortBy("updatedAt");

                                    dispatch(fetchLogInventory({
                                        limit: limit,
                                        page: 1,
                                        filter: filters
                                    }))
                                }
                            }}
                            sx={{
                                width: 300
                            }}
                            InputProps={{
                                endAdornment: (
                                    <Div sx={{ cursor: "pointer" }}>
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    </Div>
                                ),
                            }}
                        />
                    </Box>

                    <Box display={"flex"} gap={2} flexWrap={"wrap"}>
                        {permissions?.log_inventory_create && (
                            <Button size="small" variant="contained" onClick={() => navigate("/inventory/log/add")}>
                                Add Stock
                            </Button>
                        )}

                        {permissions?.log_inventory_view && (
                            <Button
                                size="small"
                                variant="contained"
                                onClick={async () => { }}
                            >
                                Export CSV
                            </Button>
                        )}

                        {/* {permissions?.log_inventory_create && (
                        <Div>
                            <form>
                                <input type="file" onChange={handleFileChange} style={{ display: "none" }} id="fileInput" />
                                <label htmlFor="fileInput">
                                    <Button size="small" variant="contained" color="primary" component="span" sx={{ height: "100%" }}>
                                        Import
                                    </Button>
                                </label>
                            </form>
                        </Div>
                    )} */}

                        <TextField
                            sx={{ width: "100px" }}
                            type="number"
                            size="small"
                            inputProps={{
                                min: 1,
                            }}
                            label="Page No"
                            onKeyDown={(e) => {
                                const inputValue = parseInt(e.target.value + e.key, 10);
                                if (isNaN(inputValue) || inputValue < 0) {
                                    e.preventDefault();
                                }
                            }}
                            value={page}
                            onChange={(e) => {
                                let value = parseInt(e.target.value);
                                // if(value > logInventoryReducer?.totalPage){
                                //     return;
                                // }
                                setLoaded(false);
                                setPage(value);
                            }}
                        ></TextField>

                        <FormControl size="small">
                            <InputLabel id="limit-label">Limit</InputLabel>
                            <Select
                                labelId="limit-label"
                                id="limit"
                                value={limit}
                                label="Limit"
                                onChange={handleChangeLimit}
                                sx={{ width: "80px" }}
                            >
                                {/* <MenuItem value={1}>1</MenuItem> */}
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={75}>75</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <LogTableListing
                    filters={filters}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    sort={sort}
                    setSort={setSort}
                    page={page}
                    setPage={setPage}
                    limit={limit}
                    setLimit={setLimit}
                />
            </Div>
        </Box>
    )
}

export default ListingLog