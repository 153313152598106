import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import { listSeries } from "app/redux/actions/masterAction";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ListSeries from "./ListSeries/ListSeries";
import SeriesModal from "./AddSeries/AddSeries";

// import { LoadingButton } from "@mui/lab";
// import { handleLogs } from "app/components/Function/logsDownloadFunction";

function Series() {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("");
  const [open, setopen] = useState(false);
  const rowsPerPage = 10;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  //debouncing for search
  const handleSearch = (value) => {
    setPage(1);
    dispatch(listSeries(value, page, rowsPerPage, sort, sortBy));
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  useEffect(() => {
    dispatch(listSeries(searchTerm, page, rowsPerPage, sortBy, sort));
  }, [sort, page, sortBy, toggle]);

  const handleOpen = () => setopen(true);
  const handleClose = () => setopen(false);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Series Master</Typography>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            if (e.target.value === "") {
              setSort("desc");
              setSortBy("updated_at");
              dispatch(listSeries("", "desc", "updated_at", 1));
            }
          }}
          sx={{ width: 300, mb: 5, mt: 4 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
        <Div>
          {permissions?.series_master_create === true && (
            <Button
              variant="contained"
              // sx={{ p: 1, pl: 4, pr: 4 }}
              size="small"
              // onClick={() => navigate("/master/item-subcategory/add")}
              onClick={handleOpen}
            >
              Add Series
            </Button>
          )}
        </Div>
      </Div>
      <ListSeries
        searchTerm={searchTerm}
        setPage={setPage}
        page={page}
        sort={sort}
        sortBy={sortBy}
        setSort={setSort}
        setSortBy={setSortBy}
        rowsPerPage={rowsPerPage}
      />
      <SeriesModal
        open={open}
        handleClose={handleClose}
        setToggle={setToggle}
      />
    </Div>
  );
}

export default Series;
