import Div from "@jumbo/shared/Div/Div";
import InfoIcon from "@mui/icons-material/Info";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { displayDateFun } from "app/utils/constants/functions";
import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import AllApis from "app/Apis";
import ImageUpload from "app/components/UploadImages";
import { GroupBundlesModal } from "app/pages/Grouping/Modal/groupBundlesDetails";
import { Axios } from "index";
import { getCurrentDate } from "app/components/Function/getCurrentDate";

export default function CreateGroupDying() {
  const [groupNo, setGroupNo] = useState(0);
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [gradeList, setGradeList] = useState([]);
  const [palleteList, setPalleteList] = useState([]);
  const [itemNameList, setItemNameList] = useState([]);

  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [selectedBundles, setSelectedBundles] = useState(state);
  const createGroup = {
    group_data: [],
    dying_images: [],
    in_time: "",
    out_time: "",
    process_time: 0,
    consumed_item_name: "",
    consumed_item_name_id: "",
    consumption_remark: "",
    liters_of_ammonia_used: "",
    date_of_dying: getCurrentDate(),
    group_dying_remarks: "",
  };

  const validationSchema = yup.object().shape({
    in_time: yup.date().required("In Time is required"),
    out_time: yup
      .date()
      .required("Out Time is required")
      .min(yup.ref("in_time"), ({ min }) => `Out Time must be greater than In Time`, { excludeEmptyString: true, exclusive: true })
      .test("not-same", "In and Out Time cannot be the same", function (value) {
        const inTime = this.resolve(yup.ref("in_time"));
        return !inTime || !value || value.getTime() !== inTime.getTime();
      }),
    process_time: yup.string().required("Process Time is required"),
    liters_of_ammonia_used: yup.number().typeError("Litres Of Ammonia must be a Number").required("Litres of Ammonia Used is required"),
    date_of_dying: yup.date().required("Date of dying is required"),
    consumed_item_name: yup.string().required("Consumed Item Name is required."),
  });

  const handleSubmit = async (values) => {
    const value = {
      ...values,
      group_data: selectedBundles.map((ele) => {
        return { group_details: [ele._id] };
      }),
    };
    const form = new FormData();
    // Iterate over the entries of the object and append each key-value pair to the FormData object
    Object.entries(value).forEach((entry) => {
      if (entry[0] == "dying_images") {
        // form.append(entry[0], JSON.stringify(entry[1]));
        entry[1].forEach((file, index) => {
          form.append(entry[0], file);
        });
      } else if (entry[0] === "group_data") {
        form.append(entry[0], JSON.stringify(entry[1])); // Convert to JSON string here
      } else {
        form.append(entry[0], entry[1]);
      }
    });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    try {
      setSubmitting(true);
      const response = await Axios.post(AllApis.dying.createDyingGrouping, form, config);
      if (response?.data?.status) {
        Swal.fire({
          title: "Group Dying Created",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("factory/dying");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const removeRow = (index, setFieldValue, values) => {
    const updateData = [...selectedBundles];
    updateData.splice(index, 1);
    setSelectedBundles(updateData);
  };

  const calculateTimeSpent = (name, value, data, setFieldValue) => {
    const arrivalTime = name == "in_time" ? value?.$d : data?.in_time;
    const departureTime = name == "out_time" ? value?.$d : data?.out_time;
    if (arrivalTime && departureTime) {
      const timeDifference = Math.abs(departureTime.getTime() - arrivalTime.getTime());
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      setFieldValue("process_time", `${hours}.${minutes}`);
    }
    setFieldValue([name], value?.$d);
  };

  useEffect(() => {
    (async () => {
      const itemNameList = await Axios.get(`${AllApis.dropdownList.item_name("ammonia")}`);
      setItemNameList(itemNameList?.data?.result);
    })();
  }, []);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Create Group Dying</Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={createGroup}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, initialValues }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ width: "100%" }}>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow
                        sx={{
                          bgcolor: "#7352C7",
                          color: "white",
                          "& .MuiTableCell-root": {
                            // py: 2,
                          },
                        }}
                      >
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Group No
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "250px",
                            verticalAlign: "middle",
                            color: "white",
                            px: 1,
                          }}
                        >
                          Item Name
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "110px",
                            verticalAlign: "middle",
                            color: "white",
                            px: 1,
                          }}
                        >
                          Item Type
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "120px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Group Length (cm)
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "90px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Group Width (cm)
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "108px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          No of Pattas
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "108px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          No of Pcs
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "90px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Group Sq.m.
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "108px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Total Item Sq.m. Avl
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "70px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Grade
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "105px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Orientation
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "130px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Formation Type
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "75px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Group Pallet No
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "110px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Physical Location
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "155px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Date of Grouping
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "85px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Bundle Details
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                            position: "sticky",
                            right: 0,
                            height: "58px",
                            zIndex: 1,
                            bgcolor: "#7352C7",
                          }}
                        >
                          Remove
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedBundles?.map((row, i) => (
                        <TableRow key={i}>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.group_id?.group_no}</TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.group_id?.item_details?.[0]?.item_name}</TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.group_id?.item_details?.[0]?.item_code}</TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.group_id?.group_length}</TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.group_id?.group_width}</TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.group_id?.group_no_of_pattas_available}</TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.group_id?.group_pcs}</TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.group_id?.group_sqm_available?.toFixed(2)}</TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.group_id?.total_item_sqm_available?.toFixed(2)}</TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.group_id?.group_grade}</TableCell>
                          <TableCell
                            sx={{
                              textAlign: "left",
                              px: 1,
                              textTransform: "capitalize",
                            }}
                          >
                            {row?.group_id?.orientation}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "left",
                              px: 1,
                              textTransform: "capitalize",
                            }}
                          >
                            {row?.group_id?.book_type}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.group_id?.group_pallete_no}</TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.group_id?.group_physical_location}</TableCell>
                          <TableCell
                            sx={{
                              textAlign: "left",
                              px: 1,
                              textTransform: "capitalize",
                            }}
                          >
                            {displayDateFun(row?.group_id?.date_of_grouping)}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 2 }}>
                            <Div
                              onClick={() => {
                                setOpen(true);
                                setRowData(row?.group_id);
                              }}
                            >
                              <InfoIcon sx={{ cursor: "pointer" }} />
                            </Div>
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "left",
                              px: 1,
                              position: "sticky",
                              right: 0,
                              zIndex: 1,
                              bgcolor: "white",
                            }}
                          >
                            <RemoveCircleOutlineIcon
                              sx={{ color: "red", ml: 2, cursor: "pointer" }}
                              onClick={() => selectedBundles.length > 1 && removeRow(i, setFieldValue, values)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Div>
              <Div sx={{ mt: 4, width: "100%", display: "flex", columnGap: 5 }}>
                <Div
                  sx={{
                    width: "70%",
                  }}
                >
                  <Div
                    sx={{
                      width: "100%",
                      display: "flex",
                      columnGap: 3,
                      flexWrap: "wrap",
                    }}
                  >
                    <Div sx={{ width: "30%" }}>
                      <Typography variant="h5">Date of Dying*</Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{
                            width: "100%",
                            "& .MuiInputBase-input": {
                              padding: 1,
                            },
                          }}
                          format="DD-MM-YYYY"
                          defaultValue={values?.date_of_dying !== "" ? dayjs(values?.date_of_dying) : null}
                          onChange={(newValue) => {
                            setFieldValue("date_of_dying", newValue.startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
                          }}
                        />
                        <Div sx={{ height: "30px", mt: 0.5 }}>
                          <ErrorMessage name="date_of_dying" component="div" style={{ color: "red" }} />
                        </Div>
                      </LocalizationProvider>
                    </Div>

                    <Div sx={{ width: "30%" }}>
                      <Typography variant="h5">In Time*</Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          format="DD-MM-YYYY   hh:mm a"
                          name="in_time"
                          value={values?.in_time ? dayjs(values?.in_time) : null}
                          sx={{
                            width: "100%",
                            "& .MuiInputBase-input": {
                              padding: 1,
                            },
                          }}
                          label={values?.in_time ? "In Time" : null}
                          onChange={(newValue) => {
                            calculateTimeSpent("in_time", newValue, values, setFieldValue);
                          }}
                        />
                      </LocalizationProvider>
                      <Div sx={{ height: "30px", mt: 0.5 }}>
                        <ErrorMessage name="in_time" component="div" style={{ color: "red" }} />
                      </Div>
                    </Div>

                    <Div sx={{ width: "30%" }}>
                      <Typography variant="h5">Out Time*</Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          format="DD-MM-YYYY   hh:mm a"
                          name="out_time"
                          sx={{
                            width: "100%",
                            "& .MuiInputBase-input": {
                              padding: 1,
                            },
                          }}
                          value={values?.out_time ? dayjs(values?.out_time) : null}
                          // referenceDate={dayjs('2024-04-17T15:30')}
                          onChange={(newValue) => {
                            calculateTimeSpent("out_time", newValue, values, setFieldValue);
                          }}
                        />
                      </LocalizationProvider>
                      <Div sx={{ height: "30px", mt: 0.5 }}>
                        <ErrorMessage name="out_time" component="div" style={{ color: "red" }} />
                      </Div>
                    </Div>

                    <Div sx={{ width: "30%" }}>
                      <Typography variant="h5">Process Time in Hrs</Typography>
                      <TextField
                        name="process_time"
                        sx={{ ...disabledStyling, width: "100%" }}
                        disabled
                        value={values?.process_time}
                        size="small"
                      />
                      <Div sx={{ height: "30px", mt: 0.5 }}></Div>
                    </Div>

                    <Div sx={{ width: "63%" }}>
                      <Typography variant="h5">Remark</Typography>
                      <TextField
                        multiline
                        size="small"
                        value={values?.group_dying_remarks}
                        sx={{ width: "100%" }}
                        name="group_dying_remarks"
                        onChange={(e) => setFieldValue("group_dying_remarks", e.target.value)}
                      />
                      <Div sx={{ height: "30px", mt: 0.5 }}></Div>
                    </Div>

                    <Div sx={{ width: "30%" }}>
                      <Typography variant="h5">Consumed Item*</Typography>
                      <Autocomplete
                        size="small"
                        id="tags-standard"
                        options={itemNameList || []}
                        sx={{ width: "100%" }}
                        name="consumed_item_name"
                        // value={
                        //   itemNameList.filter(
                        //     (item) => item.item_name == row?.item_name
                        //   )?.[0] || null
                        // }
                        getOptionLabel={(option) => option?.item_name}
                        onChange={(e, newValue) => {
                          setFieldValue("consumed_item_name", newValue?.item_name);
                          setFieldValue("consumed_item_name_id", newValue?._id);
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                            {option?.item_name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            // error={!!errors?.consumed_item_name}
                            // helperText={
                            //   errors?.consumed_item_name || ""
                            // }
                            {...params}
                            variant="outlined"
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                      <Div sx={{ height: "30px", mt: 0.5 }}>
                        <ErrorMessage name="consumed_item_name" component="div" style={{ color: "red" }} />
                      </Div>
                    </Div>

                    <Div sx={{ width: "30%" }}>
                      <Typography variant="h5">Consumed Quantity (Ltr)*</Typography>
                      <TextField
                        type="number"
                        inputProps={{
                          min: 0,
                        }}
                        onKeyDown={(e) => {
                          const inputValue = parseInt(e.target.value + e.key, 10);
                          if (isNaN(inputValue) || inputValue < 0) {
                            e.preventDefault();
                          }
                        }}
                        size="small"
                        sx={{ width: "100%" }}
                        value={values?.liters_of_ammonia_used}
                        onChange={(e) => setFieldValue("liters_of_ammonia_used", e.target.value)}
                      />
                      <Div sx={{ height: "30px", mt: 0.5 }}>
                        <ErrorMessage name="liters_of_ammonia_used" component="div" style={{ color: "red" }} />
                      </Div>
                    </Div>

                    <Div sx={{ width: "30%" }}>
                      <Typography variant="h5">Consumption Remark</Typography>
                      <TextField
                        multiline
                        size="small"
                        value={values?.consumption_remark}
                        sx={{ width: "100%" }}
                        name="consumption_remark"
                        onChange={(e) => setFieldValue("consumption_remark", e.target.value)}
                      />
                    </Div>
                  </Div>
                </Div>
                <Div sx={{ width: "30%" }}>
                  <Div sx={{ width: "100%" }}>
                    <ImageUpload setFieldValue={setFieldValue} values={values} name={"dying_images"} />
                    <Div sx={{ height: "30px", mt: -2, textAlign: "center" }}>
                      <ErrorMessage name="dying_images" component="div" style={{ color: "red" }} />
                    </Div>
                  </Div>
                </Div>
              </Div>
              {open && <GroupBundlesModal open={open} setOpen={setOpen} rowData={rowData} />}
              <Div
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                  mt: 5,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("factory/dying");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>

                <LoadingButton loading={isSubmitting} variant="contained" type="submit">
                  Create Dying
                </LoadingButton>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
