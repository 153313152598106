import { LoadingButton } from "@mui/lab";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddItemModal from "./addNewItemTable";
import EditItemModal from "./EditItemTable";
import EditItemDetailsTable from "./EditItemTable";

const ItemDetailsTable = ({ setAddedItemDetailsList, addedItemDetailsList }) => {
  const tableHeaderCellStyle = {
    textAlign: "center",
    fontWeight: 400,
    verticalAlign: "middle",
    color: "white",
    padding: "5px 5px",
    borderRight: "1px solid white",
  };
  const tableBodyCellStyle = {
    textAlign: "center",
    fontWeight: 400,
    verticalAlign: "middle",
    padding: "5px 5px",
    width: "100%",
    borderRight: "1px solid #7352C7",
  };
  const removeRow = (value, index) => {
    // setLoader({ ...loader, removeProduct: true });
    // setTimeout(() => {
    const updateData = [...addedItemDetailsList];
    updateData.splice(index, 1);
    setAddedItemDetailsList(updateData);
    // setLoader({ ...loader, removeProduct: false });
    // }, 200);
  };
  return (
    <div>
      <AddItemModal setAddedItemDetailsList={setAddedItemDetailsList} addedItemDetailsList={addedItemDetailsList} />
      <EditItemDetailsTable setAddedItemDetailsList={setAddedItemDetailsList} addedItemDetailsList={addedItemDetailsList}/>
    </div>
  );
};

export default ItemDetailsTable;
