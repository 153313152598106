import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

import { LoadingButton } from "@mui/lab";
import FormTextField1 from "app/components/InputField/FormTextField1";
import { addItemName } from "app/services/apis/addItemName";
import { updateItemName } from "app/services/apis/updateItemName";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Axios } from "index";

export default function AddItemName() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state } = useLocation();
  console.log("state => ", state);
  const [isSubmitting, setSubmitting] = useState(false);
  const [categories, setCategories] = useState([]);
  const itemName = {
    item_name: state?.item_name ? state?.item_name : "",
    category: state?.categoryDetails.map((i) => i?._id)
      ? state?.categoryDetails.map((i) => i?._id)
      : "",
  };
  const validationSchema = yup.object({
    item_name: yup.string("Enter Item Name").required("Item Name is required"),
    category: yup.array().required("At least one category is requied"),
  });

  const onUserSave = async (values) => {
    const body = { ...values };

    console.log(body);
    setSubmitting(true);
    if (pathname === "/master/item-name/edit") {
      const data = await updateItemName(body, state._id);
      if (data?.data?.statusCode === 200) {
        navigate("/master/item-name");
        Swal.fire({
          icon: "success",
          title: "Item Name Edited Successfully",
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: data?.message,
          text: "",
        });
      }
    } else {
      const data = await addItemName(body);
      console.log("data => ", data);
      if (data?.data?.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: "Item Name Added Successfully",
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/master/item-name");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };

  useEffect(() => {
    const fetchAllCategories = async () => {
      try {
        const res = await Axios.get("/item-category/all-categories");
        setCategories(res?.data?.result);
      } catch (error) {
        console.log("er fetching all categories -> ", error.message);
      }
    };
    fetchAllCategories();
  }, []);
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname === "/master/item-name/add"
          ? "Add New Item Name"
          : "Edit Item Name"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={itemName}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ setFieldValue, values }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    columnGap: 5,
                  }}
                >
                  <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={6}>
                      <FormTextField1 name="item_name" label="Item Name*" />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h5" sx={{}}>
                        Category*
                      </Typography>
                      <Autocomplete
                        multiple
                        name="category"
                        sx={{ width: "100%" }}
                        size="small"
                        id="category"
                        value={categories.filter((category) =>
                          values.category.includes(category._id)
                        )}
                        options={categories}
                        getOptionLabel={(option) => option.category || ""}
                        onChange={(e, newValue) => {
                          setFieldValue(
                            "category",
                            newValue.map((category) => category._id)
                          );
                          console.log(
                            "newval => ",
                            newValue.map((cat) => cat._id)
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Categories"
                          />
                        )}
                      />

                      <Div style={{ height: "30px" }}>
                        <ErrorMessage
                          name="category"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Grid>
                  </Grid>
                </Div>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/master/item-name");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    variant="contained"
                    size="small"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
