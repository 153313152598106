import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, Button, Grid, InputAdornment, Switch, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AllApis from "app/Apis";
import { addUser } from "app/services/apis/addUser";
import { updateUserDetailsAdmin } from "app/services/apis/updateUserDetailsAdmin";
import { bloodGroup, codeList, genderList, outerDiv1 } from "app/utils/constants/dropdowns.js";
import { City, Country, State } from "country-state-city";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

const disabledStyling = {
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#475259",
  },
  "& .MuiInputLabel-root": {
    color: "#475259", // Change label color to blue
  },
};

export default function AddUser() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [roles, setRoles] = useState([]);
  // const [empid, setempid] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const [countryList, setCountryList] = useState(Country.getAllCountries());
  const [StateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const { pathname } = useLocation();
  const data = state;
  console.log(data, "4111");

  const user = {
    user_name: data?.user_name ? data.user_name : '',
    first_name: data?.first_name ? data?.first_name : "",
    last_name: data?.last_name ? data?.last_name : "",
    email_id: data?.email_id ? data?.email_id : "",
    gender: data?.gender ? data?.gender : "",
    blood_group: data?.blood_group ? data?.blood_group : "",
    age: data?.age ? data?.age : "",
    mobile_no: data?.mobile_no ? data?.mobile_no : "",
    country_code: data?.country_code ? data?.country_code : "+91",
    country: data?.country ? data?.country : "",
    state: data?.state ? data?.state : "",
    city: data?.city ? data?.city : "",
    pincode: data?.pincode ? data?.pincode : "",
    role_id: data?.role_id?._id ? data?.role_id?._id : "",
    address: data?.address ? data?.address : "",
    dob: data?.dob ? data?.dob : "",
    status: data?.status == false || data?.status == true ? data?.status : true,
    user_remarks: data?.user_remarks ? data?.user_remarks : "",
  };
  const validationSchema = yup.object({
    user_name: yup.string("Enter User Name.").required("User Name is required"),
    first_name: yup
      .string("Enter First Name")
      .required("First Name is required")
      .matches(/^[A-Za-z\s]+$/, "First Name must contain only alphabetic characters"),
    last_name: yup
      .string("Enter Last Name")
      .required("Last Name is required")
      .matches(/^[A-Za-z\s]+$/, "Last Name must contain only alphabetic characters"),
    gender: yup
      .string()
      .required("Gender is required")
      .test("gender-not-select", "Please select a valid Gender", (value) => value !== "Select"),
    role_id: yup
      .string()
      .required("Role is Required")
      .test(
        // "role-not-select",
        "",
        "Please select a valid Role",
        (value) => value !== "Select"
      ),
  });

  const onUserSave = async (values) => {
    const body = { ...values };
    // for (let key in body) {
    //   if (
    //     key !== "email_id" &&
    //     key !== "country" &&
    //     key !== "state" &&
    //     key !== "city" &&
    //     key !== "country_code" &&
    //     key !== "countryCode" &&
    //     key !== "gender" &&
    //     key !== "user_remarks"
    //   ) {
    //     if (typeof body[key] === "string") {
    //       body[key] = body[key].toUpperCase();
    //     }
    //   }
    // }
    setSubmitting(true);
    if (pathname == "/user/edit") {
      const data = await updateUserDetailsAdmin({
        ...body,
        id: state._id,
      });
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "User Edited Successfully",
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/user");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.message,
          text: "",
        });
      }
    } else {
      const data = await addUser(body);
      sessionStorage.removeItem("userMasterFilter");
      if (data?.status == 201) {
        Swal.fire({
          icon: "success",
          title: "User Added Successfully",
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/user");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };

  const filterStates = (countryIsoCode) => {
    // const filteredStates = State.getAllStates().filter((state) => state?.countryCode === countryIsoCode);
    const filteredStates = State.getStatesOfCountry(countryIsoCode);
    setStateList(filteredStates);
  };

  const filterCities = (countryCode, stateIsoCode) => {
    // const filteredCities = City.getAllCities().filter((city) => city.stateCode === stateIsoCode && city.countryCode == countryCode);
    const filteredCities = City.getCitiesOfState(countryCode, stateIsoCode);
    setCityList(filteredCities);
  };

  function calculateAge(selectedDate) {
    const currentDate = new Date();
    const birthDate = new Date(selectedDate);

    // Calculate the difference in milliseconds
    const ageDifference = currentDate - birthDate;

    // Convert the difference to years
    const age = Math.floor(ageDifference / (1000 * 60 * 60 * 24 * 365.25));

    return age;
  }

  useEffect(() => {
    if (state) {
      const country = Country.getAllCountries().filter((country) => country.name === state.country);
      const stateList = State.getAllStates().filter((country) => country.name === state.state);
      filterStates(country[0]?.isoCode);
      filterCities(stateList[0]?.isoCode, country[0]?.isoCode);
    }
  }, []);

  useEffect(() => {
    (async () => {
      // setRoles(await getRoles());
      const roles = await Axios.get(`${AllApis.dropdownList.roles}`);
      setRoles(roles?.data?.result);
    })();
  }, []);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">{pathname === "/user/add" ? "Add New User" : "Edit User"}</Typography>
      <Div>
        <Formik
          validateOnChange={false}
          initialValues={user}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ setFieldValue, values, errors, touched, handleBlur, setFieldTouched }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      User Name*
                    </Typography>
                    <TextField
                      size="small"
                      value={values?.user_name}
                      sx={{ width: "100%" }}
                      name="user_name"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("user_name", e.target.value)}
                      error={touched?.user_name && errors?.user_name}
                      helperText={touched?.user_name && errors?.user_name}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      First Name*
                    </Typography>
                    <TextField
                      size="small"
                      value={values?.first_name}
                      sx={{ width: "100%" }}
                      name="first_name"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("first_name", e.target.value)}
                      error={touched?.first_name && errors?.first_name}
                      helperText={touched?.first_name && errors?.first_name}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Last Name*
                    </Typography>
                    <TextField
                      size="small"
                      value={values?.last_name}
                      sx={{ width: "100%" }}
                      name="last_name"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("last_name", e.target.value)}
                      error={touched?.last_name && errors?.last_name}
                      helperText={touched?.last_name && errors?.last_name}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Email
                    </Typography>
                    <TextField
                      size="small"
                      value={values?.email_id}
                      sx={{ width: "100%" }}
                      name="email_id"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("email_id", e.target.value)}
                      error={touched?.email_id && errors?.email_id}
                      helperText={touched?.email_id && errors?.email_id}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Country
                    </Typography>
                    <Autocomplete
                      size="small"
                      options={countryList}
                      name="country"
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option?.name;
                        } else {
                          return option;
                        }
                      }}
                      value={values?.country}
                      onChange={(e, value) => {
                        setFieldValue("country", value?.name);
                        setFieldValue("state", "");
                        setFieldValue("city", "");
                        setFieldValue("countryCode", value?.phonecode || "");
                        filterStates(value?.isoCode);
                      }}
                      onBlur={(e) => {
                        setFieldTouched("country", true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.country && Boolean(errors.country)} // Show error if touched and there's an error
                          helperText={touched.country && errors.country} // Display helper text for the error
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      State
                    </Typography>
                    <Autocomplete
                      size="small"
                      options={StateList}
                      name="state"
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option?.name;
                        } else {
                          return option;
                        }
                      }}
                      value={values?.state}
                      onChange={(e, value) => {
                        setFieldValue("state", value?.name);
                        setFieldValue("city", "");
                        filterCities(value?.countryCode, value?.isoCode);
                      }}
                      onBlur={(e) => {
                        setFieldTouched("state", true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.state && Boolean(errors.state)} // Show error if touched and there's an error
                          helperText={touched.state && errors.state} // Display helper text for the error
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      City
                    </Typography>
                    <Autocomplete
                      size="small"
                      options={cityList}
                      name="city"
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option?.name;
                        } else {
                          return option;
                        }
                      }}
                      value={values?.city}
                      onChange={(e, value) => {
                        setFieldValue("city", value?.name);
                      }}
                      onBlur={(e) => {
                        setFieldTouched("city", true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.city && Boolean(errors.city)} // Show error if touched and there's an error
                          helperText={touched.city && errors.city} // Display helper text for the error
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Address
                    </Typography>
                    <TextField
                      // type="number"
                      size="small"
                      value={values?.address}
                      sx={{ width: "100%" }}
                      name="address"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("address", e.target.value)}
                      error={touched?.address && errors?.address}
                      helperText={touched?.address && errors?.address}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Pincode
                    </Typography>
                    <TextField
                      // type="number"
                      size="small"
                      value={values?.pincode}
                      sx={{ width: "100%" }}
                      name="pincode"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("pincode", e.target.value)}
                      error={touched?.pincode && errors?.pincode}
                      helperText={touched?.pincode && errors?.pincode}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Gender*
                    </Typography>
                    <Autocomplete
                      size="small"
                      options={genderList}
                      name="gender"
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option;
                        } else {
                          return option;
                        }
                      }}
                      value={values?.gender}
                      onChange={(e, value) => {
                        setFieldValue("gender", value);
                      }}
                      onBlur={(e) => {
                        setFieldTouched("gender", true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.gender && Boolean(errors.gender)} // Show error if touched and there's an error
                          helperText={touched.gender && errors.gender} // Display helper text for the error
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Blood Group
                    </Typography>
                    <Autocomplete
                      size="small"
                      options={bloodGroup}
                      name="blood_group"
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option;
                        } else {
                          return option;
                        }
                      }}
                      value={values?.blood_group}
                      onChange={(e, value) => {
                        setFieldValue("blood_group", value);
                      }}
                      onBlur={(e) => {
                        setFieldTouched("blood_group", true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.blood_group && Boolean(errors.blood_group)} // Show error if touched and there's an error
                          helperText={touched.blood_group && errors.blood_group} // Display helper text for the error
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Date Of Birth
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        format="DD-MM-YYYY"
                        defaultValue={values?.dob !== "" ? dayjs(values?.dob) : null}
                        onBlur={(e) => {
                          setFieldTouched("dob", true);
                        }}
                        onChange={(newValue) => {
                          setFieldValue("dob", newValue.startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
                          const age = calculateAge(newValue.startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
                          setFieldValue("age", age);
                        }}
                        error={touched.dob && Boolean(errors.dob)} // Show error if touched and there's an error
                        helperText={touched.dob && errors.dob}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Age
                    </Typography>
                    <TextField
                      // type="number"
                      size="small"
                      value={values?.age}
                      sx={{ width: "100%" }}
                      name="age"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("age", e.target.value)}
                      error={touched?.age && errors?.age}
                      helperText={touched?.age && errors?.age}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Phone No
                    </Typography>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.mobile_no}
                      sx={{ width: "100%", "& .MuiInputBase-root": { paddingLeft: "0px" } }}
                      name="mobile_no"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("mobile_no", e.target.value)}
                      error={touched?.mobile_no && errors?.mobile_no}
                      helperText={touched?.mobile_no && errors?.mobile_no}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ padding: "0px" }}>
                            <Autocomplete
                              sx={{
                                width: "100px",
                                "& .MuiOutlinedInput-root": {
                                  padding: "0px", // Remove padding inside Autocomplete's TextField
                                },
                              }}
                              clearIcon={null}
                              size="small"
                              options={codeList}
                              name="country_code"
                              getOptionLabel={(option) => option}
                              value={values?.country_code}
                              onChange={(e, value) => {
                                setFieldValue("country_code", value);
                              }}
                              onBlur={(e) => {
                                setFieldTouched("country_code", true);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  error={touched.country_code && Boolean(errors.country_code)} // Show error if touched and there's an error
                                  helperText={touched.country_code && errors.country_code} // Display helper text for the error
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              )}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Role*
                    </Typography>
                    <Autocomplete
                      size="small"
                      options={roles}
                      name="role_id"
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option?.role_name;
                        } else {
                          return option;
                        }
                      }}
                      // value={values?.role_id}
                      value={
                        roles.find((role) => role._id === values.role_id) || null
                      } 
                      onChange={(e, value) => {
                        setFieldValue("role_id", value?.role_id);
                      }}
                      onBlur={(e) => {
                        setFieldTouched("role_id", true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.role_id && Boolean(errors.role_id)} // Show error if touched and there's an error
                          helperText={touched.role_id && errors.role_id} // Display helper text for the error
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Div sx={outerDiv1}>
                      <Typography variant="h6" fontSize="14px">
                        Remarks
                      </Typography>
                      <TextField
                        multiline
                        size="small"
                        value={values?.user_remarks}
                        sx={{ width: "100%" }}
                        name="user_remarks"
                        onBlur={async (e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => setFieldValue("user_remarks", e.target.value)}
                        error={touched?.user_remarks && errors?.user_remarks}
                        helperText={touched?.user_remarks && errors?.user_remarks}
                      />
                    </Div>
                  </Grid>
                </Grid>
                <Div sx={{ mt: 5 }}>
                  <Typography variant="h6" fontSize="14px">
                    Status
                  </Typography>
                  <Switch
                    onChange={(e) => {
                      setFieldValue("status", values.status == true ? false : true);
                    }}
                    defaultChecked={values.status == true ? true : false}
                    sx={{
                      p: 0,
                      width: "70px",
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: values.status === true ? "green" : "red",
                        width: "90%",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                        backgroundColor: values.status === true ? "green" : "red",
                      },
                    }}
                  />
                </Div>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/user");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton variant="contained" type="submit" sx={{ width: "100px" }} loading={isSubmitting}>
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
