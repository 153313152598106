import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import {
  getAllBranchesBySupplierId,
  getAllSuppliers,
} from "app/redux/actions/masterAction";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BranchesAccordion from "./BranchesAccordion";

// import { LoadingButton } from "@mui/lab";
// import { handleLogs } from "app/components/Function/logsDownloadFunction";

function ListBranches() {
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("");
  const rowsPerPage = 10;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  //debouncing for search
  const handleSearch = (value) => {
    setPage(1);
    dispatch(
      getAllBranchesBySupplierId(value, page, rowsPerPage, sort, sortBy, id)
    );
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  useEffect(() => {
    dispatch(
      getAllBranchesBySupplierId(
        searchTerm,
        page,
        rowsPerPage,
        sortBy,
        sort,
        id
      )
    );
  }, [sort, page, sortBy, id]);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Manage Branch</Typography>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            if (e.target.value === "") {
              setSort("desc");
              setSortBy("updated_at");
              dispatch(getAllSuppliers("", "desc", "updated_at", 1));
            }
          }}
          sx={{ width: 300, mb: 5, mt: 4 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />

        {permissions?.supplier_master_create === true && (
          <Button
            variant="contained"
            // sx={{ p: 1, pl: 4, pr: 4 }}
            size="small"
            onClick={() => navigate(`/master/supplier/branch/add/${id}`)}
          >
            Add Branch
          </Button>
        )}
      </Div>
      <BranchesAccordion
        searchTerm={searchTerm}
        setPage={setPage}
        page={page}
        sort={sort}
        sortBy={sortBy}
        setSort={setSort}
        setSortBy={setSortBy}
        rowsPerPage={rowsPerPage}
      />
    </Div>
  );
}

export default ListBranches;
