import Div from '@jumbo/shared/Div';
import { Box, Button, Grid } from '@mui/material';
import FilterAccordian from 'app/components/FilterAccordian';
import { fetchLogInventory } from 'app/redux/actions/Inventory/LogInventory/logDispatch';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomDatePicker, InwardSrNo, ItemName, SupplierList } from './FiltersComponents';

const supplier_name = "log_invoice_details.supplier_details.company_details.supplier_name"

const filterObj = {
    item_sr_no: "",
    'log_invoice_details.inward_sr_no': "",
    item_name: "",
    [supplier_name]: "",
    range: {
        date: {
            'log_invoice_details.inward_date': {
                from: "",
                to: ""
            }
        }
    }
}

function LogFilters({ handleSubmit, handleClear,sx }) {
    const [filters, setFilters] = useState(JSON.parse(JSON.stringify(filterObj)))

    const handleFilter = useMemo(() => {
        return setFilters
    }, [])

    return (
        <Box sx={{...sx}}>
            <FilterAccordian
                actions={
                    <Div>
                        <Button variant="contained" size="small" sx={{ marginRight: 1 }} onClick={() => {
                            handleSubmit(filters)
                        }}>
                            Apply
                        </Button>

                        <Button variant="outlined" size="small" onClick={() => {
                            setFilters(filterObj)
                            handleClear(filterObj)
                        }}>
                            Clear
                        </Button>
                    </Div>
                }
            >
                <Grid container spacing={3}>
                    {/* <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                            <ItemSrNo value={filters?.item_sr_no} setFilters={handleFilter}/>
                        </Grid> */}
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <InwardSrNo value={filters?.['log_invoice_details.inward_sr_no']} setFilters={handleFilter} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <ItemName value={filters?.item_name} setFilters={handleFilter} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <SupplierList name={supplier_name} value={filters?.[supplier_name]} setFilters={handleFilter} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <CustomDatePicker title={"Inward Date From"} name="log_invoice_details.inward_date" position={"from"} value={filters?.range?.date?.['log_invoice_details.inward_date']?.from} setFilters={handleFilter} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <CustomDatePicker title={"Inward Date To"} name="log_invoice_details.inward_date" position={"to"} value={filters?.range?.date?.['log_invoice_details.inward_date']?.to} setFilters={handleFilter} />
                    </Grid>
                </Grid>
            </FilterAccordian>
        </Box>
    )
}

export default React.memo(LogFilters)