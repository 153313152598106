import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import { Checkbox, FormControlLabel, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material'
import React, { useState } from 'react';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';

const tableHeadCellSx = {
    textAlign: "left",
    minWidth: "100px",
    verticalAlign: "middle",
    px: 1,
}

const tableHeadSortSx = {
    color: "white",
    "&:hover": { color: "white" },
    "&.MuiTableSortLabel-root.Mui-active": {
        color: "white !important",
    },
    "& .MuiTableSortLabel-icon": {
        color: "white !important", // Set the color for the sorting icon
    },
    "&:hover": {
        color: "white !important", // Set the color when hovering
    },
}

const tableBodyCell = { textAlign: "left", px: 1 };

const tableRowHeadSticky = {
    textAlign: "left",
    minWidth: "95px",
    verticalAlign: "middle",
    color: "white",
    px: 1,
    position: "sticky",
    right: 0,
    height: "58px",
    zIndex: 1,
    bgcolor: "#7352C7",
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const tableRowBodySticky = {
    textAlign: "left",
    px: 1,
    position: "sticky",
    right: 0,
    zIndex: 1,
    bgcolor: "white",
}

const FlitchTableListing = ({filters}) => {
    console.log("ppppppppppppp")
    const [sortBy, setSortBy] = useState('updatedAt');
    const [sort, setSort] = useState("desc");
    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const navigate = useNavigate();


    const handleSort = function (name) {
        setSortBy(name);
        setSort((prev) => prev === 'desc' ? 'asc' : 'desc')
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case "edit":
                navigate("/inventory/flitch");
                break;
            case "issueForCrosscutting":

                break;
            case "issueForFlitching":

                break;
            default:
                navigate("/inventory/flitch");

        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow sx={{
                        bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                            // py: 2,
                        },
                    }}>
                        <TableCell sx={{ ...tableHeadCellSx, color: 'white', minWidth:"120px"}}>
                            <FormControlLabel
                                sx={{
                                    "& .MuiCheckbox-root.Mui-checked": {
                                        color: "white",
                                        borderColor: "white"
                                    }
                                }}
                                control={<Checkbox defaultChecked size='small'/>}
                                label="Select All"
                            />
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx }}>
                            <TableSortLabel
                                active={sortBy === "item_sr_no"}
                                direction={sort}
                                onClick={(e) => handleSort("item_sr_no")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Sr No
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx, minWidth:"130px" }}>
                            <TableSortLabel
                                active={sortBy === "inward_sr_no"}
                                direction={sort}
                                onClick={(e) => handleSort("inward_sr_no")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Inward Sr No
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx, minWidth:"130px" }}>
                            <TableSortLabel
                                active={sortBy === "inward_date"}
                                direction={sort}
                                onClick={(e) => handleSort("inward_date")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Inward Date
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx, minWidth:"180px" }}>
                            <TableSortLabel
                                active={sortBy === "supplier_item_name"}
                                direction={sort}
                                onClick={(e) => handleSort("supplier_item_name")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Supplier Item Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx,  minWidth:"150px" }}>
                            <TableSortLabel
                                active={sortBy === "supplier_log_no"}
                                direction={sort}
                                onClick={(e) => handleSort("supplier_log_no")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Supplier Log No
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx,  minWidth:"120px" }}>
                            <TableSortLabel
                                active={sortBy === "item_name"}
                                direction={sort}
                                onClick={(e) => handleSort("item_name")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Item Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx }}>
                            <TableSortLabel
                                active={sortBy === "log_no"}
                                direction={sort}
                                onClick={(e) => handleSort("log_no")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Log No
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx, minWidth:"140px" }}>
                            <TableSortLabel
                                active={sortBy === "invoice_length"}
                                direction={sort}
                                onClick={(e) => handleSort("invoice_length")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Invoice Length
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx, minWidth:"160px" }}>
                            <TableSortLabel
                                active={sortBy === "invoice_diameter"}
                                direction={sort}
                                onClick={(e) => handleSort("invoice_diameter")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Invoice Diameter
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx, minWidth:"120px" }}>
                            <TableSortLabel
                                active={sortBy === "invoice_cmt"}
                                direction={sort}
                                onClick={(e) => handleSort("invoice_cmt")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Invoice Cmt
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx, minWidth:"120px" }}>
                            <TableSortLabel
                                active={sortBy === "indian_cmt"}
                                direction={sort}
                                onClick={(e) => handleSort("indian_cmt")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Indian Cmt
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx, minWidth:"150px" }}>
                            <TableSortLabel
                                active={sortBy === "physical_length"}
                                direction={sort}
                                onClick={(e) => handleSort("physical_length")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Physical Length
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx, minWidth:"160px" }}>
                            <TableSortLabel
                                active={sortBy === "physical_diameter"}
                                direction={sort}
                                onClick={(e) => handleSort("physical_diameter")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Physical Diameter
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx, minWidth:"130px" }}>
                            <TableSortLabel
                                active={sortBy === "physical_cmt"}
                                direction={sort}
                                onClick={(e) => handleSort("physical_cmt")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Physical Cmt
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx, minWidth:"140px" }}>
                            <TableSortLabel
                                active={sortBy === "exchange_rate"}
                                direction={sort}
                                onClick={(e) => handleSort("exchange_rate")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Exchange Rate
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx, minWidth:"160px" }}>
                            <TableSortLabel
                                active={sortBy === "rate_in_currency"}
                                direction={sort}
                                onClick={(e) => handleSort("rate_in_currency")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Rate In Currency
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx, minWidth:"120px" }}>
                            <TableSortLabel
                                active={sortBy === "rate_in_inr"}
                                direction={sort}
                                onClick={(e) => handleSort("rate_in_inr")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Rate In Inr
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx }}>
                            <TableSortLabel
                                active={sortBy === "amount"}
                                direction={sort}
                                onClick={(e) => handleSort("amount")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Amount
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx }}>
                            <TableSortLabel
                                active={sortBy === "remark"}
                                direction={sort}
                                onClick={(e) => handleSort("remark")}
                                sx={{ ...tableHeadSortSx }}
                            >
                                Remark
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ ...tableHeadCellSx, ...tableRowHeadSticky }}>
                            Action
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Array.from({ length: 10 }).map((e, i) => {
                            return (
                                <TableRow>
                                    <TableCell sx={{ ...tableBodyCell, textAlign:"center" }}>
                                        <Checkbox {...label} defaultChecked size="small" />
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyCell }}>
                                        {"N/A"}
                                    </TableCell>
                                    <TableCell
                                        sx={{ ...tableBodyCell, ...tableRowBodySticky }}
                                    >
                                        <JumboDdMenu
                                            icon={<MoreHorizIcon />}
                                            menuItems={[
                                                {
                                                    icon: <EditIcon />,
                                                    title: "Edit",
                                                    action: "edit",
                                                    show: true
                                                },
                                                {
                                                    icon: <AddCircleOutlineIcon />,
                                                    title: "Issue For Crosscutting",
                                                    action: "issueForCrosscutting",
                                                    show: true
                                                },
                                                {
                                                    icon: <AddCircleOutlineIcon />,
                                                    title: "Issue For Flitching",
                                                    action: "issueForFlitching",
                                                    show: true
                                                },
                                            ].filter((ele) => ele?.show)}
                                            onClickCallback={handleItemAction}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={100}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    position: "sticky",
                    bottom: 0,
                    left: 0,
                    backgroundColor: "white",
                    borderTop: "1px solid #ddd",
                    py: 1,
                }}
            />
        </TableContainer>
    )
}

export default React.memo(FlitchTableListing)