// import React, { useEffect, useState } from "react";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Modal from "@mui/material/Modal";
// import { Close } from "@mui/icons-material";

// import {
//     TextField,
//     Typography,
// } from "@mui/material";
// // import ToastAlerts from '../Toast';
// import { useNavigate } from "react-router-dom";
// import { Axios } from "index";
// import Swal from "sweetalert2";
// const style = {
//     position: "absolute",
//     top: "40%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     // width: 400,
//     bgcolor: "background.paper",
//     border: "1px solid #000",
//     boxShadow: 24,
//     p: 4,
// };

// function SeriesModal({ open, handleClose, seriesData, setToggle }) {
//     console.log("values got => ", seriesData);
//     // const nvaigate = useNavigate();
//     const [series, setseries] = useState({
//         series_name: "",
//     });

//     useEffect(() => {
//         if (seriesData) {
//             setseries({ series_name: seriesData?.data?.series_name })
//         } else {
//             setseries({ series_name: "" })
//         }
//     }, [seriesData])

//     const handleSubmit = async () => {

//         try {
//             let res;
//             series.series_name = series.series_name?.toUpperCase();
//             if (seriesData) {
//                 res = await Axios.post(`/series-master/update-series/${seriesData.id}`, series);
//             } else {
//                 res = await Axios.post('/series-master/add-series', series);
//             }

//             if (res.data.statusCode === 200) {
//                 Swal.fire({
//                     icon: "success",
//                     title: `${seriesData ? "series Updated Successfully" : "series Created Successfully"} `,
//                     text: "",
//                     timer: 1000,
//                     showConfirmButton: false,
//                 });
//                 setToggle(prevState => !prevState);
//                 handleClose();
//             } else {
//                 Swal.fire({
//                     icon: "error",
//                     title: res.data.message,
//                     text: "",
//                     timer: 1000,
//                     showConfirmButton: false,
//                 });
//             }
//         } catch (error) {
//             console.log("err adding series => ", error.message)
//         }
//     }

//     return (
//         <div>
//             <Modal
//                 open={open}
//                 onClose={handleClose}
//                 aria-labelledby="modal-modal-title"
//                 aria-describedby="modal-modal-description"
//             >
//                 <Box sx={style} className="lg:w-1/3 w-[20rem]">
//                     <div className="flex flex-row items-center justify-between">
//                         <Typography variant="h2" component="h2">
//                             Add Series
//                         </Typography>
//                         <Close
//                             onClick={handleClose}
//                             className="mb-4"
//                             style={{ cursor: "pointer", float: "right" }}
//                         />
//                     </div>
//                     <div className='flex flex-col items-start mt-6 justify-center '
//                     >
//                         <label >Series Name*</label>
//                         <TextField
//                             size="small"
//                             fullWidth
//                             margin="normal"
//                             required
//                             name="name"
//                             value={series?.series_name}
//                             onChange={(e) => setseries({ ...series, series_name: e.target.value })}
//                         />

//                     </div>
//                     <div className="flex items-center justify-center mt-5">
//                         <Button variant="contained" color="primary" onClick={handleSubmit}>
//                             {seriesData ? "Update" : "Add"}
//                         </Button>
//                     </div>
//                 </Box>
//             </Modal>
//         </div>
//     );
// }

// export default SeriesModal;
import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Typography, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { Axios } from "index"; // Assuming Axios is properly configured
import { LoadingButton } from "@mui/lab";
import FormTextField1 from "app/components/InputField/FormTextField1";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object({
  series_name: yup
    .string("Enter Series Name")
    .required("Series Name is required"),
  remark: yup.string("Enter reamrks"),
});

function SeriesModal({ open, handleClose, seriesData, setToggle }) {
  const initialValues = {
    series_name: seriesData?.data?.series_name
      ? seriesData?.data?.series_name
      : "",
    remark: seriesData?.data?.remark ? seriesData?.data?.remark : "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let res;
      values.series_name = values.series_name.toUpperCase();

      if (seriesData) {
        res = await Axios.post(
          `/series-master/update-series/${seriesData.id}`,
          values
        );
      } else {
        res = await Axios.post("/series-master/add-series", values);
      }

      if (res.data.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: seriesData
            ? "Series Updated Successfully"
            : "Series Created Successfully",
          timer: 1000,
          showConfirmButton: false,
        });
        setToggle((prevState) => !prevState);
        handleClose();
      } else {
        Swal.fire({
          icon: "error",
          title: res.data.message,
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.log("Error adding/updating series => ", error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
      <Box sx={style} className="lg:w-1/3 w-[20rem]">
        <div className="flex flex-row items-center justify-between">
          <Typography variant="h2">
            {seriesData ? "Edit Series" : "Add Series"}
          </Typography>
          <Close onClick={handleClose} style={{ cursor: "pointer" }} />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, handleChange, isSubmitting }) => (
            <Form noValidate>
              <div className="grid grid-flow-row lg:grid-cols-2 grid-cols-1 gap-5 mt-5">
                <FormTextField1 name="series_name" label="Series Name*" />
                <FormTextField1 name="remark" label="Remark" />
              </div>

              <div className="flex items-center justify-center mt-5">
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={isSubmitting}
                >
                  {seriesData ? "Update" : "Add"}
                </LoadingButton>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

export default SeriesModal;
