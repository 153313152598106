import React, { useCallback, useMemo, useState } from 'react'
import FlitchTableListing from './FlitchTableListing'
import { Box, Button, Grid, Typography } from '@mui/material';
import FilterAccordian from 'app/components/FilterAccordian';
import Div from '@jumbo/shared/Div';
import { CustomDatePicker, InwardSrNo, ItemName, ItemSrNo, SupplierList } from './Filters';

const supplier_name = "flitch_invoice_details.supplier_details.company_details.supplier_name"

const filterObj = {
    item_sr_no:"",
    inward_sr_no:"",
    supplier_name:"",
    item_name:"",
    [supplier_name]:"",
    range:{
        date:{
            inward_date:{
                from:"",
                to:""
            }
        }
    }
}

const ListingFlitch = () => {
    const [filters,setFilters] = useState(JSON.parse(JSON.stringify(filterObj)))

    const handleFilter = useMemo(()=>{
        return setFilters
    },[])

    console.log("filter",filters)
    return (
        <Div sx={{ mt: -4 }}>
            <Typography variant="h1">Log Inventory</Typography>
            <Box sx={{ my: 4 }}>
                <FilterAccordian
                    actions={
                        <Div>
                            <Button variant="contained" size="small" sx={{ marginRight: 1 }}>
                                Apply
                            </Button>

                            <Button variant="outlined" size="small" onClick={()=> setFilters(filterObj)}>
                                Clear
                            </Button>
                        </Div>
                    }
                >
                    <Grid container spacing={3}>
                        {/* <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                            <ItemSrNo value={filters?.item_sr_no} setFilters={handleFilter}/>
                        </Grid> */}
                        <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                            <InwardSrNo value={filters?.inward_sr_no} setFilters={handleFilter}/>
                        </Grid>
                        <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                            <ItemName value={filters?.item_name} setFilters={handleFilter}/>
                        </Grid>
                        <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                            <SupplierList name={supplier_name} value={filters?.[supplier_name]} setFilters={handleFilter}/>
                        </Grid>
                        <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                            <CustomDatePicker title={"Inward Date From"} name="inward_date" position={"from"} value={filters?.range?.date?.inward_date?.from} setFilters={handleFilter}/>
                        </Grid>
                        <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                            <CustomDatePicker title={"Inward Date To"} name="inward_date" position={"to"} value={filters?.range?.date?.inward_date?.to} setFilters={handleFilter}/>
                        </Grid>
                    </Grid>
                </FilterAccordian>
            </Box>
            <FlitchTableListing filters={filters}/>
        </Div>
    )
}

export default ListingFlitch