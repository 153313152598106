import Page from "@jumbo/shared/Page";
import dispatchMiddleware from "./middleware/auth/dispatchValidRoute";
import ReadyForDispatchList from "app/pages/Inventory/ReadyForDispatch/List";
import ListDispatch from "app/pages/VeneerOrder/Dispatch/List";
import CreateGroupDispatch from "app/pages/VeneerOrder/Dispatch/Create/createGroupDispatch";
import CreateRawDispatch from "app/pages/VeneerOrder/Dispatch/Create/createRawDispatch";

export const dispatchRoutes = [
  {
    middleware: [
      {
        element: dispatchMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "inventory/readyForDispatch",
        element: (
          <Page component={ReadyForDispatchList} layout={"vertical-default"} />
        ),
      },
      {
        path: "dispatch",
        element: <Page component={ListDispatch} layout={"vertical-default"} />,
      },
      {
        path: "dispatch/create-group-dispatch",
        element: <Page component={CreateGroupDispatch} layout={"vertical-default"} />,
      },
      {
        path: "dispatch/create-raw-dispatch",
        element: <Page component={CreateRawDispatch} layout={"vertical-default"} />,
      },
    ],
  },
];
