import apiUrl from "app/utils/Axios.Config";
import {
  ALL_GRADE_MASTER_FAIL,
  ALL_GRADE_MASTER_REQUEST,
  ALL_GRADE_MASTER_SUCCESS,
  ALL_CURRENCY_MASTER_FAIL,
  ALL_CURRENCY_MASTER_REQUEST,
  ALL_CURRENCY_MASTER_SUCCESS,
  ALL_CUT_MASTER_FAIL,
  ALL_CUT_MASTER_REQUEST,
  ALL_CUT_MASTER_SUCCESS,
  ALL_GST_MASTER_FAIL,
  ALL_GST_MASTER_REQUEST,
  ALL_GST_MASTER_SUCCESS,
  ALL_EXPENSE_TYPE_MASTER_FAIL,
  ALL_EXPENSE_TYPE_MASTER_REQUEST,
  ALL_EXPENSE_TYPE_MASTER_SUCCESS,
  ALL_ITEM_CODE_MASTER_FAIL,
  ALL_ITEM_CODE_MASTER_REQUEST,
  ALL_ITEM_CODE_MASTER_SUCCESS,
  ALL_ITEM_NAME_MASTER_FAIL,
  ALL_ITEM_NAME_MASTER_REQUEST,
  ALL_ITEM_NAME_MASTER_SUCCESS,
  ALL_PALLETE_MASTER_FAIL,
  ALL_PALLETE_MASTER_REQUEST,
  ALL_PALLETE_MASTER_SUCCESS,
  ALL_PARTY_MASTER_FAIL,
  ALL_PARTY_MASTER_REQUEST,
  ALL_PARTY_MASTER_SUCCESS,
  ALL_SUPPLIER_MASTER_SUCCESS,
  ALL_UNIT_MASTER_FAIL,
  ALL_UNIT_MASTER_REQUEST,
  ALL_UNIT_MASTER_SUCCESS,
  FETCH_ALL_ITEMS_CATEGORY_FAIL,
  FETCH_ALL_ITEMS_CATEGORY_REQUEST,
  FETCH_ALL_ITEMS_CATEGORY_SUCCESS,
  FETCH_ALL_ITEMS_SUBCATEGORY_FAIL,
  FETCH_ALL_ITEMS_SUBCATEGORY_REQUEST,
  FETCH_ALL_ITEMS_SUBCATEGORY_SUCCESS,
  FETCH_ALL_SUPPLIER_MASTER_BRANCHES__FAIL,
  FETCH_ALL_SUPPLIER_MASTER_BRANCHES_LIST_REQUEST,
  FETCH_ALL_SUPPLIER_MASTER_BRANCHES_LIST_SUCCESS,
  FETCH_ALL_SUPPLIER_MASTER_FAIL,
  FETCH_ALL_SUPPLIER_MASTER_LIST_REQUEST,
  FETCH_ALL_SUPPLIER_MASTER_LIST_SUCCESS,
  LOAD_SUPPLIER_MASTER_FAIL,
  LOAD_SUPPLIER_MASTER_REQUEST,
  FETCH_ALL_SERIES_REQUEST,
  FETCH_ALL_SERIES_SUCCESS,
  FETCH_ALL_SERIES_FAIL,
  FETCH_ALL_DEPARTMENT_REQUEST,
  FETCH_ALL_DEPARTMENT_SUCCESS,
  FETCH_ALL_DEPARTMENT_FAIL,
  FETCH_ALL_MACHINE_REQUEST,
  FETCH_ALL_MACHINE_SUCCESS,
  FETCH_ALL_MACHINE_FAIL,
} from "app/utils/constants/masterConstants";
import axios from "axios";
import { Axios } from "index";

//supplier master

export const getAllSuppliers =
  (query, page, limit, sortField, sortOrder) => async (dispatch) => {
    try {
      // const body = {
      //   filters: {},
      //   searchFields: {
      //     string: [
      //       "supplier_name",
      //       "email_id",
      //       "pan_no",
      //       "contact_Person_name",
      //       "bill_address",
      //       "city",
      //       "state",
      //       "country",
      //       "gst_no",
      //       "created_employee_id.first_name",
      //       "created_employee_id.last_name",
      //       "status",
      //       "supplier_remarks",
      //     ],
      //     numbers: ["contact_Person_number", "pincode", "gst_no"],
      //   },
      // };
      if (!query) {
        query = "";
      }
      // search=govind&page=desc&limit=updated_at&sortOrder=undefined&sortField=1&query=govind
      const urlParams = new URLSearchParams({
        query: query.trim(),
        page: page,
        limit: limit,
        sortOrder: sortOrder,
        sortField: sortField,
      });
      dispatch({ type: LOAD_SUPPLIER_MASTER_REQUEST });
      // const config = {
      //   withCredentials: true,
      //   headers: {
      //     withCredentials: true,
      //   },
      // };

      // const data = await axios.post(
      //   `${process.env.REACT_APP_URL
      //   }/supplier-master/list-supplier-master?${urlParams.toString()}`,
      //   body,
      //   config
      // );
      const { data } = await apiUrl.get(
        `/supplier-master/list-supplier-master?${urlParams.toString()}`
      );
      dispatch({
        type: ALL_SUPPLIER_MASTER_SUCCESS,
        payload: {
          data: data?.result?.allDetails,
          totalPage: data?.result?.totalPage,
        },
      });
    } catch (error) {
      dispatch({
        type: LOAD_SUPPLIER_MASTER_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

export const getAllSupplierList =
  ({ query, page, limit, sortField, sortOrder }) =>
  async (dispatch) => {
    const body = {
      filters: {},
      searchFields: {
        string: ["grade_name", "grade_remarks", "status"],
        numbers: ["created_employee_id._id"],
        arrayField: [
          "created_employee_id.first_name",
          "created_employee_id.last_name",
        ],
      },
    };
    try {
      dispatch({ type: FETCH_ALL_SUPPLIER_MASTER_LIST_REQUEST });
      const params = { query, page, limit, sortField, sortOrder };
      const { data } = await apiUrl.get(
        "/supplier-master/supplier-master-list",
        { params }
      );
      dispatch({
        type: FETCH_ALL_SUPPLIER_MASTER_LIST_SUCCESS,
        payload: data?.result?.allDetails,
        totalPage: data?.result?.totalPage,
      });
    } catch (error) {
      dispatch({
        type: FETCH_ALL_SUPPLIER_MASTER_FAIL,
        payload: error.message,
      });
    }
  };

export const getAllBranchesBySupplierId =
  (query, page, limit, sortField, sortOrder, id) => async (dispatch) => {
    try {
      if (!query) {
        query = "";
      }

      const urlParams = new URLSearchParams({
        query: query.trim(),
        page: page,
        limit: limit,
        sortOrder: sortOrder,
        sortField: sortField,
      });
      dispatch({ type: FETCH_ALL_SUPPLIER_MASTER_BRANCHES_LIST_REQUEST });

      const { data } = await apiUrl.get(
        `/supplier-master/branchs-by-supplier/${id}?${urlParams.toString()}`
      );
      dispatch({
        type: FETCH_ALL_SUPPLIER_MASTER_BRANCHES_LIST_SUCCESS,
        payload: {
          data: data?.result?.allDetails,
          totalPage: data?.result?.totalPage,
        },
      });
    } catch (error) {
      dispatch({
        type: FETCH_ALL_SUPPLIER_MASTER_BRANCHES__FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//item-category master

export const listItemCatgory =
  (query, page, limit, sortField, sortOrder) => async (dispatch) => {
    console.log("listItemCatgory called with:", {
      query,
      page,
      limit,
      sortField,
      sortOrder,
    });
    try {
      if (!query) {
        query = "";
      }
      const urlParams = new URLSearchParams({
        query: query.trim(),
        page: page,
        limit: limit,
        sortOrder: sortOrder,
        sortField: sortField,
      });
      dispatch({ type: FETCH_ALL_ITEMS_CATEGORY_REQUEST });

      const { data } = await Axios.get(
        `/item-category/list?${urlParams.toString()}`
      );
      console.log("data => ", data);
      dispatch({
        type: FETCH_ALL_ITEMS_CATEGORY_SUCCESS,
        payload: {
          data: data?.result?.allDetails || [],
          totalPage: data?.result.totalPage || 0,
        },
      });
    } catch (error) {
      dispatch({
        type: FETCH_ALL_ITEMS_CATEGORY_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };

//item subcategory
export const listItemSubCatgory =
  (query, page, limit, sortField, sortOrder) => async (dispatch) => {
    try {
      if (!query) {
        query = "";
      }
      const body = {
        filters: {},
        searchFields: {
          string: ["name", "remark"],
          numbers: ["sr_no"],
          arrayField: ["userDetails.first_name", "userDetails.last_name"],
        },
      };
      const urlParams = new URLSearchParams({
        query: query.trim(),
        page: page,
        limit: limit,
        sortOrder: sortOrder,
        sortField: sortField,
      });
      dispatch({ type: FETCH_ALL_ITEMS_SUBCATEGORY_REQUEST });

      const { data } = await Axios.post(
        `/item-subcategory/list?${urlParams.toString()}`,
        body
      );
      console.log("data => ", data);
      dispatch({
        type: FETCH_ALL_ITEMS_SUBCATEGORY_SUCCESS,
        payload: {
          data: data?.result?.allDetails || [],
          totalPage: data?.result.totalPage || 0,
        },
      });
    } catch (error) {
      dispatch({
        type: FETCH_ALL_ITEMS_SUBCATEGORY_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };

//grade master
export const getAllGrade =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: ["grade_name", "grade_remarks", "status"],
          numbers: ["created_employee_id._id"],
          arrayField: [
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      dispatch({ type: ALL_GRADE_MASTER_REQUEST });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const data = await axios.post(
        `${
          process.env.REACT_APP_URL
        }/grade-master/list-grade-master?${urlParams.toString()}`,
        { ...body },
        config
      );
      // console.log(data);
      dispatch({
        type: ALL_GRADE_MASTER_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_GRADE_MASTER_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

// currency master
export const getAllCurrency =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: ["currency_name", "currency_remarks", "status"],
          numbers: ["created_employee_id._id"],
          arrayField: [
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      dispatch({ type: ALL_CURRENCY_MASTER_REQUEST });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const data = await axios.post(
        `${
          process.env.REACT_APP_URL
        }/currency-master/list-currency-master?${urlParams.toString()}`,
        { ...body },
        config
      );
      // console.log(data);
      dispatch({
        type: ALL_CURRENCY_MASTER_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_CURRENCY_MASTER_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

// cut master
export const getAllCut =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: ["cut_name", "cut_remarks", "status"],
          numbers: ["created_employee_id._id"],
          arrayField: [
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      dispatch({ type: ALL_CUT_MASTER_REQUEST });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const data = await axios.post(
        `${
          process.env.REACT_APP_URL
        }/cut-master/list-cut-master?${urlParams.toString()}`,
        { ...body },
        config
      );
      // console.log(data);
      dispatch({
        type: ALL_CUT_MASTER_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_CUT_MASTER_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

// gst master
export const getAllGst =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: ["gst_remarks", "status"],
          numbers: ["gst_percentage", "created_employee_id._id"],
          arrayField: [
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      dispatch({ type: ALL_GST_MASTER_REQUEST });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const data = await axios.post(
        `${
          process.env.REACT_APP_URL
        }/gst-master/list-gst-master?${urlParams.toString()}`,
        { ...body },
        config
      );
      // console.log(data);
      dispatch({
        type: ALL_GST_MASTER_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_GST_MASTER_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

// expense type master
export const getAllExpenseType =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: ["expense_type_name", "expense_type_remarks", "status"],
          numbers: [],
          arrayField: [
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      dispatch({ type: ALL_EXPENSE_TYPE_MASTER_REQUEST });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const data = await axios.post(
        `${
          process.env.REACT_APP_URL
        }/expenseType-master/list-expenseType-master?${urlParams.toString()}`,
        { ...body },
        config
      );
      // console.log(data);
      dispatch({
        type: ALL_EXPENSE_TYPE_MASTER_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_EXPENSE_TYPE_MASTER_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//party master
export const getAllParty =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        searchFields: {
          string: ["customer_name", "customer_place"],
          numbers: ["created_employee_id"],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      dispatch({ type: ALL_PARTY_MASTER_REQUEST });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.post(
        `${
          process.env.REACT_APP_URL
        }/party-name-master/list-party-name-master?${urlParams.toString()}`,
        { ...body },
        config
      );

      dispatch({
        type: ALL_PARTY_MASTER_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_PARTY_MASTER_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//unit master
export const getAllUnit =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: [
            "unit_name",
            "unit_symbolic_name",
            "unit_gst_code",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
            "status",
            "unit_remarks",
          ],
          numbers: [],
        },
      };
      if (!search_value) {
        search_value = "";
      }
      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });
      dispatch({ type: ALL_UNIT_MASTER_REQUEST });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const data = await axios.post(
        `${
          process.env.REACT_APP_URL
        }/unit-master/list-unit-master?${urlParams.toString()}`,
        { ...body },
        config
      );

      dispatch({
        type: ALL_UNIT_MASTER_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_UNIT_MASTER_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//pallete master
export const getAllPallete =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: [
            "pallete_no",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
            "status",
            "pallete_remarks",
          ],
          numbers: ["pallete_no"],
        },
      };
      if (!search_value) {
        search_value = "";
      }
      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });
      dispatch({ type: ALL_PALLETE_MASTER_REQUEST });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const data = await axios.post(
        `${
          process.env.REACT_APP_URL
        }/pallete-master/list-pallete-master?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_PALLETE_MASTER_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_PALLETE_MASTER_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//Item name master
export const getAllItemName =
  (query, page, limit, sortField, sortOrder) => async (dispatch) => {
    try {
      if (!query) {
        query = "";
      }
      const body = {
        filters: {},
        searchFields: {
          string: ["item_name"],
          numbers: ["sr_no"],
          arrayField: [
            "userDetails.first_name",
            "userDetails.last_name",
            "categoryDetails.category",
          ],
        },
      };
      const urlParams = new URLSearchParams({
        query: query.trim(),
        page: page,
        limit: limit,
        sortOrder: sortOrder,
        sortField: sortField,
      });

      dispatch({ type: ALL_ITEM_NAME_MASTER_REQUEST });

      const data = await Axios.post(
        `/item-name-master/list-item-name-master?${urlParams.toString()}`,
        body
      );

      console.log("payload => ", data?.data?.result?.allDetails);
      dispatch({
        type: ALL_ITEM_NAME_MASTER_SUCCESS,
        payload: {
          data: data?.data?.result?.allDetails,
          totalPage: data?.data?.result?.totalPage,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_ITEM_NAME_MASTER_FAIL,
        payload: error?.result?.message,
      });
    }
  };

//item Type master
export const getAllItemCode =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        searchFields: {
          string: ["item_code", "item_code_remarks", "status"],
          numbers: ["created_employee_id._id", "item_code"],
          arrayField: [
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }
      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });
      dispatch({ type: ALL_ITEM_CODE_MASTER_REQUEST });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const data = await axios.post(
        `${
          process.env.REACT_APP_URL
        }/item-code-master/list-item-code-master?${urlParams.toString()}`,
        { ...body },
        config
      );

      dispatch({
        type: ALL_ITEM_CODE_MASTER_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_ITEM_CODE_MASTER_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//series-master
export const listSeries =
  (query, page, limit, sortField, sortOrder) => async (dispatch) => {
    try {
      if (!query) {
        query = "";
      }
      const body = {
        searchFields: {
          string: ["series_name", "remark"],
          numbers: ["sr_no"],
          arrayField: ["userDetails.first_name", "userDetails.last_name"],
        },
      };
      const urlParams = new URLSearchParams({
        query: query.trim(),
        page: page,
        limit: limit,
        sortOrder: sortOrder,
        sortField: sortField,
      });
      dispatch({ type: FETCH_ALL_SERIES_REQUEST });

      const { data } = await Axios.post(
        `/series-master/list-series?${urlParams.toString()}`,
        body
      );
      dispatch({
        type: FETCH_ALL_SERIES_SUCCESS,
        payload: {
          data: data?.result?.allDetails || [],
          totalPage: data?.result.totalPage || 0,
        },
      });
    } catch (error) {
      dispatch({
        type: FETCH_ALL_SERIES_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };

//DEPARTMENT - MASTER
export const listDepartments =
  (query, page, limit, sortField, sortOrder) => async (dispatch) => {
    try {
      if (!query) {
        query = "";
      }
      const body = {
        searchFields: {
          string: ["dept_name", "remark"],
          numbers: ["sr_no"],
          arrayField: ["userDetails.first_name", "userDetails.last_name"],
        },
      };
      const urlParams = new URLSearchParams({
        query: query.trim(),
        page: page,
        limit: limit,
        sortOrder: sortOrder,
        sortField: sortField,
      });
      dispatch({ type: FETCH_ALL_DEPARTMENT_REQUEST });

      const { data } = await Axios.post(
        `/department-master/list-department?${urlParams.toString()}`,
        body
      );
      dispatch({
        type: FETCH_ALL_DEPARTMENT_SUCCESS,
        payload: {
          data: data?.result?.allDetails || [],
          totalPage: data?.result.totalPage || 0,
        },
      });
    } catch (error) {
      dispatch({
        type: FETCH_ALL_DEPARTMENT_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };

//MACHINE-MASTER
export const listMachines =
  (query, page, limit, sortField, sortOrder) => async (dispatch) => {
    try {
      if (!query) {
        query = "";
      }
      const body = {
        searchFields: {
          string: ["machine_name"],
          numbers: ["sr_no"],
          arrayField: [
            "userDetails.first_name",
            "userDetails.last_name",
            "departmentDetails.dept_name",
          ],
        },
      };
      const urlParams = new URLSearchParams({
        query: query.trim(),
        page: page,
        limit: limit,
        sortOrder: sortOrder,
        sortField: sortField,
      });
      dispatch({ type: FETCH_ALL_MACHINE_REQUEST });

      const { data } = await Axios.post(
        `/machine-master/list-machine?${urlParams.toString()}`,
        body
      );
      dispatch({
        type: FETCH_ALL_MACHINE_SUCCESS,
        payload: {
          data: data?.result?.allDetails || [],
          totalPage: data?.result.totalPage || 0,
        },
      });
    } catch (error) {
      dispatch({
        type: FETCH_ALL_MACHINE_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };
