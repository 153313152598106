import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Pagination,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";

function ContactPersonList() {
  const { state } = useLocation();
  const [personData, setPersonData] = useState(state?.personData?.personData);
  const rowsPerPage = 10;
  const [page, setPage] = useState(0);
  const [expandedBranch, setExpandedBranch] = useState(null);

  const handleExpansion = (branchId) => {
    setExpandedBranch(expandedBranch === branchId ? null : branchId);
  };

  return (
    <>
      <Typography variant="h3">
        Supplier Name - {personData?.supplier_name}
      </Typography>
      {personData?.supplierDetails?.map((branch, branchIndex) => (
        <div key={branchIndex} className="mt-6">
          <Accordion
            expanded={expandedBranch === branch._id}
            onChange={() => handleExpansion(branch._id)}
            component={Paper}
            sx={{ mb: 2 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${branch._id}-content`}
              id={`panel-${branch._id}-header`}
            >
              <Typography variant="h4" className="flex-1">
                Branch Name - {branch.branch_name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper} sx={{ mb: 1 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow
                      sx={{
                        bgcolor: "#7352C7",
                        color: "white",
                      }}
                    >
                      <TableCell sx={{ color: "white" }}>Sr.No</TableCell>
                      <TableCell sx={{ color: "white" }}>Name</TableCell>
                      <TableCell sx={{ color: "white" }}>Email</TableCell>
                      <TableCell sx={{ color: "white" }}>
                        Mobile Number
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>Designation</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {branch?.contact_person?.length > 0 ? (
                      branch.contact_person.map((contact, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{contact.name}</TableCell>
                          <TableCell>{contact.email}</TableCell>
                          <TableCell>{contact.mobile_number}</TableCell>
                          <TableCell>{contact.designation}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={5}>
                          No Data Found...
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </>
  );
}

export default ContactPersonList;
