import React from 'react'
import AddFlitchStock from './AddFlitch'
import ListingFlitch from './ListingFlitch'

const flitchInventory = () => {
  return (
    <div>
        {/* <AddFlitchStock/> */}
        <ListingFlitch/>
    </div>
  )
}

export default flitchInventory