import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { getCurrentDate } from "app/components/Function/getCurrentDate";
import { Form, Formik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { BorderRight, Padding } from "@mui/icons-material";
import Div from "@jumbo/shared/Div";

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  width: "100%",
};

const AddItemModal = ({ setAddedItemDetailsList, addedItemDetailsList, itemSubCategoryNameList, itemNameList, finalData }) => {
  const [submitting, setSubmitting] = useState(false);
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    customClass: {
      container: "popupImportant",
    },
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  console.log(finalData, "4111");

  const stock = {
    item_sr_no: addedItemDetailsList.length + 1 || 1,
    supplier_item_name: "",
    supplier_log_no: "",
    item_name: "",
    item_id: "",
    item_sub_category_name: "",
    item_sub_category_id: "",
    log_no: "",
    log_formula: "",
    invoice_length: 0,
    invoice_diameter: 0,
    invoice_cmt: 0,
    indian_cmt: 0,
    physical_length: 0,
    physical_diameter: 0,
    physical_cmt: 0,
    exchange_rate: 0,
    rate_in_currency: 0,
    rate_in_inr: 0,
    amount: 0,
    remark: "",
  };

  const validationSchema = yup.object({
    supplier_item_name: yup.string("Enter Supplier Name").required("Supplier Name is required"),
    supplier_log_no: yup.string("Enter Supplier Name").required("Supplier Name is required"),
    item_name: yup.string().required("Required"),
    item_sub_category_name: yup.string().required("Required"),
    log_no: yup.string().required("Required"),
    log_formula: yup.string().required("Required"),
    invoice_length: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    invoice_diameter: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    invoice_cmt: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    indian_cmt: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    physical_length: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    physical_diameter: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    physical_cmt: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    exchange_rate: yup
      .number()
      // .positive()
      // .moreThan(0, "Must be greater than 0")
      // .required("Required")
      .when((currency, schema) =>
        finalData?.currency?.currency_name !== "INR"
          ? schema.positive().moreThan(0, "Must be greater than 0").required("Required.")
          : schema.notRequired()
      ),
    rate_in_currency: yup
      .number()
      .when((currency, schema) =>
        finalData?.currency?.currency_name !== "INR"
          ? schema.positive().moreThan(0, "Must be greater than 0").required("Required.")
          : schema.notRequired()
      ),
    rate_in_inr: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    amount: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
  });

  const handleSubmit = async (values) => {
    console.log(values, "899999");

    setSubmitting(true);
    setAddedItemDetailsList([...addedItemDetailsList, { ...values }]);
    Toast.fire({
      timer: 3000,
      icon: "success",
      title: "Item Added Successfully.",
      position:"top-right"
      // background: theme.palette.background.paper,
    });
  };

  const handleCalculateInvoiceCMT = (invoiceLength, invoiceDia, formula, setFieldValue) => {
    let pai = 22 / 7;
    if (formula === "A") {
      let invoiceCMT = (pai * invoiceDia * invoiceDia * invoiceLength) / 4;
      let indianCMT = (invoiceCMT * 27.74) / 35.336;
      setFieldValue("invoice_cmt", parseFloat(invoiceCMT.toFixed(2)));
      setFieldValue("indian_cmt", parseFloat(indianCMT.toFixed(2)));
    }
    if (formula === "B") {
      let invoiceCMT = ((invoiceDia - 4) / 4) * ((invoiceDia - 4) / 4) * invoiceLength;
      let indianCMT = (invoiceLength * 0.305 * ((invoiceDia * 3.281) / 12) * 0.305 * (((invoiceDia * 3.281) / 12) * 0.305)) / 16;
      setFieldValue("invoice_cmt", parseFloat(invoiceCMT.toFixed(2)));
      setFieldValue("indian_cmt", parseFloat(indianCMT.toFixed(2)));
    }
  };

  const handleCalculatePhysicalCMT = (physicalLength, physicalDia, formula, setFieldValue) => {
    if (formula === "A") {
      let physicalCMT = (physicalLength * physicalDia * physicalDia) / 16;
      setFieldValue("physical_cmt", parseFloat(physicalCMT.toFixed(2)));
    }
    if (formula === "B") {
      let physicalCMT = (physicalLength * physicalDia * physicalDia) / 16;
      setFieldValue("physical_cmt", parseFloat(physicalCMT.toFixed(2)));
    }
  };
  return (
    <Formik
      validateOnChange={false}
      initialValues={stock}
      enableReinitialize={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, errors, handleBlur, initialValues, setFieldTouched, touched }) => (
        <Form noValidate autoComplete="off">
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "#7352C7",
                    color: "white",
                  }}
                >
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "60px" }}>Sr No.</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Item Name</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Log No.</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Name</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Sub Category</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Log No.</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Log Formula</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Invoice Length</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Invoice Diameter</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Invoice CMT</TableCell>

                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Indian CMT</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Physical Length</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Physical Diameter</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Physical CMT</TableCell>
                  {finalData?.currency?.currency_name !== "INR" ? (
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Exchange Rate</TableCell>
                  ) : (
                    ""
                  )}
                  {finalData?.currency?.currency_name !== "INR" ? (
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate in Currency</TableCell>
                  ) : (
                    ""
                  )}
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate in INR</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Amount</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ ...tableBodyCellStyle }}>{values?.item_sr_no}</TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // type="number"
                      size="small"
                      value={values?.supplier_item_name}
                      sx={{ width: "100%" }}
                      name="supplier_item_name"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("supplier_item_name", e.target.value)}
                      error={touched?.supplier_item_name && errors?.supplier_item_name}
                      helperText={touched?.supplier_item_name && errors?.supplier_item_name}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // type="number"
                      size="small"
                      value={values?.supplier_log_no}
                      sx={{ width: "100%" }}
                      name="supplier_log_no"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("supplier_log_no", e.target.value)}
                      error={touched?.supplier_log_no && errors?.supplier_log_no}
                      helperText={touched?.supplier_log_no && errors?.supplier_log_no}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <Autocomplete
                      size="small"
                      options={itemNameList}
                      name="item_name"
                      getOptionLabel={(option) => option?.item_name}
                      onBlur={() => setFieldTouched("item_name", true)}
                      onChange={(e, selectedData) => {
                        setFieldValue("item_name", selectedData?.item_name || "");
                        setFieldValue("item_id", selectedData?._id || "");
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.item_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.item_name && Boolean(errors.item_name)} // Show error if touched and there's an error
                          helperText={touched.item_name && errors.item_name}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <Autocomplete
                      size="small"
                      // disabled={values?.supplier_details?.country === "India"}
                      options={itemSubCategoryNameList}
                      name="item_sub_category_name"
                      // value={values?.item_sub_category_name}
                      getOptionLabel={(option) => option?.name}
                      onBlur={() => setFieldTouched("item_sub_category_name", true)}
                      onChange={(e, selectedData) => {
                        // setFinalData({ ...finalData, item_sub_category_name: selectedData });
                        setFieldValue("item_sub_category_name", selectedData?.name || "");
                        setFieldValue("item_sub_category_id", selectedData?._id || "");
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.item_sub_category_name && Boolean(errors.item_sub_category_name)} // Show error if touched and there's an error
                          helperText={touched.item_sub_category_name && errors.item_sub_category_name}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // type="number"
                      size="small"
                      value={values?.log_no}
                      sx={{ width: "100%" }}
                      name="log_no"
                      onBlur={async (e) => {
                        handleBlur(e);
                        // setTimeout(() => {
                        //   const isInvalid = e.target.getAttribute("aria-invalid");
                        //   if (isInvalid === "false") {
                        //     setInvoiceDetails({
                        //       ...invoiceDetails,
                        //       log_no: e.target.value,
                        //     });
                        //   }
                        // }, 0);
                      }}
                      onChange={(e) => setFieldValue("log_no", e.target.value)}
                      error={touched?.log_no && errors?.log_no}
                      helperText={touched?.log_no && errors?.log_no}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <Autocomplete
                      size="small"
                      options={["A", "B"]}
                      name="log_formula"
                      getOptionLabel={(option) => option}
                      onBlur={() => {
                        setFieldTouched("log_formula", true);
                      }}
                      onChange={(e, selectedData) => {
                        setFieldValue("log_formula", selectedData || "");
                        if (selectedData === "A" || selectedData === "B") {
                          handleCalculateInvoiceCMT(values?.invoice_length, values?.invoice_diameter, selectedData, setFieldValue);
                          handleCalculatePhysicalCMT(values?.physical_length, values?.physical_diameter, selectedData, setFieldValue);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.log_formula && Boolean(errors.log_formula)} // Show error if touched and there's an error
                          helperText={touched.log_formula && errors.log_formula}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.invoice_length}
                      sx={{ width: "100%" }}
                      name="invoice_length"
                      onBlur={async (e) => {
                        handleBlur(e);
                        handleCalculateInvoiceCMT(e.target.value, values?.invoice_diameter, values?.log_formula, setFieldValue);
                      }}
                      onChange={(e) => setFieldValue("invoice_length", e.target.value)}
                      error={touched?.invoice_length && errors?.invoice_length}
                      helperText={touched?.invoice_length && errors?.invoice_length}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.invoice_diameter}
                      sx={{ width: "100%" }}
                      name="invoice_diameter"
                      onBlur={async (e) => {
                        handleBlur(e);
                        handleCalculateInvoiceCMT(values?.invoice_length, e.target.value, values?.log_formula, setFieldValue);
                      }}
                      onChange={(e) => setFieldValue("invoice_diameter", e.target.value)}
                      error={touched?.invoice_diameter && errors?.invoice_diameter}
                      helperText={touched?.invoice_diameter && errors?.invoice_diameter}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      disabled
                      type="number"
                      size="small"
                      value={values?.invoice_cmt}
                      sx={{ width: "100%" }}
                      name="invoice_cmt"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("invoice_cmt", e.target.value)}
                      error={touched?.invoice_cmt && errors?.invoice_cmt}
                      helperText={touched?.invoice_cmt && errors?.invoice_cmt}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // type="number"
                      disabled
                      size="small"
                      value={values?.indian_cmt}
                      sx={{ width: "100%" }}
                      name="indian_cmt"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("indian_cmt", e.target.value)}
                      error={touched?.indian_cmt && errors?.indian_cmt}
                      helperText={touched?.indian_cmt && errors?.indian_cmt}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.physical_length}
                      sx={{ width: "100%" }}
                      name="physical_length"
                      onBlur={async (e) => {
                        handleBlur(e);
                        handleCalculatePhysicalCMT(e.target.value, values?.physical_diameter, values?.log_formula, setFieldValue);
                      }}
                      onChange={(e) => setFieldValue("physical_length", e.target.value)}
                      error={touched?.physical_length && errors?.physical_length}
                      helperText={touched?.physical_length && errors?.physical_length}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.physical_diameter}
                      sx={{ width: "100%" }}
                      name="physical_diameter"
                      onBlur={async (e) => {
                        handleBlur(e);
                        handleCalculatePhysicalCMT(values?.physical_length, e.target.value, values?.log_formula, setFieldValue);
                      }}
                      onChange={(e) => setFieldValue("physical_diameter", e.target.value)}
                      error={touched?.physical_diameter && errors?.physical_diameter}
                      helperText={touched?.physical_diameter && errors?.physical_diameter}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      disabled
                      type="number"
                      size="small"
                      value={values?.physical_cmt}
                      sx={{ width: "100%" }}
                      name="physical_cmt"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("physical_cmt", e.target.value)}
                      error={touched?.physical_cmt && errors?.physical_cmt}
                      helperText={touched?.physical_cmt && errors?.physical_cmt}
                    />
                  </TableCell>

                  {finalData?.currency?.currency_name !== "INR" ? (
                    <TableCell sx={{ ...tableBodyCellStyle }}>
                      <TextField
                        type="number"
                        size="small"
                        value={values?.exchange_rate}
                        sx={{ width: "100%" }}
                        name="exchange_rate"
                        onBlur={async (e) => {
                          handleBlur(e);
                          let rateInINR = Number(e.target.value) * Number(values?.rate_in_currency);
                          let amount = Number(values.physical_cmt) * Number(rateInINR);
                          setFieldValue("rate_in_inr", rateInINR);
                          setFieldValue("amount", amount);
                        }}
                        onChange={(e) => setFieldValue("exchange_rate", e.target.value)}
                        error={touched?.exchange_rate && errors?.exchange_rate}
                        helperText={touched?.exchange_rate && errors?.exchange_rate}
                      />
                    </TableCell>
                  ) : (
                    ""
                  )}

                  {finalData?.currency?.currency_name !== "INR" ? (
                    <TableCell sx={{ ...tableBodyCellStyle }}>
                      <TextField
                        type="number"
                        size="small"
                        value={values?.rate_in_currency}
                        sx={{ width: "100%" }}
                        name="rate_in_currency"
                        onBlur={async (e) => {
                          handleBlur(e);
                          let rateInINR = Number(e.target.value) * Number(values?.exchange_rate);
                          let amount = Number(values.physical_cmt) * Number(rateInINR);
                          setFieldValue("rate_in_inr", rateInINR);
                          setFieldValue("amount", amount);
                        }}
                        onChange={(e) => setFieldValue("rate_in_currency", e.target.value)}
                        error={touched?.rate_in_currency && errors?.rate_in_currency}
                        helperText={touched?.rate_in_currency && errors?.rate_in_currency}
                      />
                    </TableCell>
                  ) : (
                    ""
                  )}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.rate_in_inr}
                      sx={{ width: "100%" }}
                      name="rate_in_inr"
                      onBlur={async (e) => {
                        handleBlur(e);
                        let amount = Number(values.physical_cmt) * Number(e.target.value);
                        setFieldValue("amount", amount);
                      }}
                      onChange={(e) => setFieldValue("rate_in_inr", e.target.value)}
                      error={touched?.rate_in_inr && errors?.rate_in_inr}
                      helperText={touched?.rate_in_inr && errors?.rate_in_inr}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.amount}
                      sx={{ width: "100%" }}
                      name="amount"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("amount", e.target.value)}
                      error={touched?.amount && errors?.amount}
                      helperText={touched?.amount && errors?.amount}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // type="number"
                      size="small"
                      value={values?.remark}
                      sx={{ width: "100%" }}
                      name="remark"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("remark", e.target.value)}
                      error={touched?.remark && errors?.remark}
                      helperText={touched?.remark && errors?.remark}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Div sx={{ textAlign: "right" }}>
            <LoadingButton
              loading={submitting}
              type="submit"
              size="small"
              variant="contained"
              sx={{
                marginY: 1,
                width: "150px",
                textAlign: "center",
                verticalAlign: "middle",
                color: "white",
                px: 1,
                position: "sticky",
                right: "20px",
                zIndex: 1,
              }}
            >
              Add New Item
            </LoadingButton>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default AddItemModal;
