import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Grid,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  supplierBranchDropDownList,
  supplierDropDownList,
  supplierList,
} from "app/services/apis/supplierList";
import { City, Country, State } from "country-state-city";
import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AllApis from "app/Apis";
import { getCurrentDate } from "app/components/Function/getCurrentDate";
import { Axios } from "index";
import AddItemModal from "./addNewItemTable";
import FullScreenLoader from "app/components/ListingPageLoader";
import ItemDetailsTable from "./itemTable";
import InvoiceDetailsTable from "./invoiceDetailTable";
import { currencyDropDownList } from "app/services/apis/currencyApis";
import { itemNameDropDown } from "app/services/apis/addItemName";
import { itemSubCategoryListDropDown } from "app/services/apis/subCategoryApis";
import { gstDropdownList } from "app/services/apis/gstApis";

export default function AddLogStock() {
  const { state, pathname } = useLocation();
  const [currencyList, setCurrencyList] = useState([]);
  const [gstNameList, setGstNameList] = useState([]);
  const [itemNameList, setItemNameList] = useState([]);
  const [itemSubCategoryNameList, setItemSubCategoryNameList] = useState([]);
  const [palleteList, setPalleteList] = useState([]);
  const [branchDetails, setBranchDetails] = useState(null);
  const [loader, setLoader] = useState({
    addProduct: false,
    removeProduct: false,
  });

  const [tabValue, setTabValue] = useState(0);
  const [newTabValue, setNewTabValue] = useState(0);
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [supplierBranchList, setSupplierBranchList] = useState([]);
  const [addedItemDetailsList, setAddedItemDetailsList] = useState([]);

  const [finalData, setFinalData] = useState({
    company_details: {},
    branch_detail: {},
    no_of_workers: 0,
    shift: "",
    working_hours: "",
    inward_date: getCurrentDate(),
    currency: null,
    invoice_no: null,
  });

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const getSupplierBranchList = async (supplierId) => {
    const supplerBranchList = await supplierBranchDropDownList(supplierId);
    if (supplerBranchList.data.success) {
      setSupplierBranchList(supplerBranchList?.data?.result?.allDetails);
    }
  };

  const handleSubmit = (values) => {
    console.log(newTabValue);
    console.log(values, "values");
    console.log(finalData, "finalData");
    if (addedItemDetailsList && addedItemDetailsList.length <= 0) {
      Swal.fire({
        title: "Add at least one item details.",
        icon: "warning",
        showCancelButton: false,
      });
    } else {
      setTabValue(newTabValue);
    }
  };

  const stock = {
    inward_date: getCurrentDate(),
    invoice_no: "",
    currency: "",
    supplier_name: "",
    branch_name: "",
    no_of_workers: 0,
    shift: "",
    working_hours: "",
  };

  const validationSchema = yup.object({
    inward_date: yup.date().required("Date of Inward is required"),
    currency: yup.string().required("Currency is required").nullable(),
    invoice_no: yup.string().required("Invoice No. is required"),
    supplier_name: yup.string().required("Supplier Name is required"),
    branch_name: yup.string().required("Branch Name is required"),
    no_of_workers: yup
      .number()
      .nullable()
      .positive()
      .moreThan(0, "Must be greater than 0")
      .required("Required"),
    shift: yup.string().required("Required"),
    working_hours: yup
      .number()
      .nullable()
      .positive()
      .moreThan(0, "Must be greater than 0")
      .required("Required"),
  });

  useEffect(() => {
    (async () => {
      try {
        const supplierNameList = await supplierDropDownList("LOG");
        if (supplierNameList.data.success) {
          setSupplierNameList(supplierNameList.data.result);
        }
        const itemNameList = await itemNameDropDown("LOG");
        setItemNameList(itemNameList?.data?.result);

        const itemSubCategoryList = await itemSubCategoryListDropDown("");
        setItemSubCategoryNameList(itemSubCategoryList?.data?.result);

        const currencyNameList = await currencyDropDownList();
        setCurrencyList(currencyNameList?.data?.result);

        const gstList = await gstDropdownList();
        setGstNameList(gstList?.data?.result);

        // const gradeList = await Axios.get(`${AllApis.dropdownList.grade}`);
        // setGradeList(gradeList?.data?.result);

        // const palleteList = await Axios.get(`${AllApis.dropdownList.pallete}`);
        // setPalleteList(palleteList?.data?.result);
      } catch (error) {}
    })();
    return () => {};
  }, []);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname === "/inventory/log/edit"
          ? "Edit Log Stock"
          : "Add Log Stock"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={false}
          initialValues={stock}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            setFieldValue,
            values,
            errors,
            handleBlur,
            setFieldError,
            setFieldTouched,
            touched,
            handleSubmit,
            validateForm,
          }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4, width: "100%" }}>
                <Grid
                  sx={{
                    display: tabValue === 1 ? "none" : "",
                  }}
                  container
                  rowSpacing={2}
                  columnSpacing={3}
                >
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Inward Date*
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        format="DD-MM-YYYY"
                        maxDate={dayjs()}
                        defaultValue={
                          values?.inward_date !== ""
                            ? dayjs(values?.inward_date)
                            : null
                        }
                        onChange={(newValue) => {
                          setFieldValue(
                            "inward_date",
                            newValue
                              .startOf("day")
                              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                          );
                        }}
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage
                          name="inward_date"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Supplier Name*
                    </Typography>
                    <Select
                      size="small"
                      sx={{ width: "100%" }}
                      name="supplier_name"
                      onChange={(e, options) => {
                        getSupplierBranchList(options?.props?.action?._id);
                        setFieldValue(
                          "supplier_name",
                          options?.props?.action?._id
                        );
                        setFinalData({
                          ...finalData,
                          company_details: options?.props?.action || {},
                        });
                      }}
                      defaultValue={values?._id}
                    >
                      {supplierNameList?.map((item) => {
                        return (
                          <MenuItem value={item._id} action={item}>
                            {item?.supplier_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage
                      name="supplier_name"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Grid>

                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Branch Name*
                    </Typography>
                    <Select
                      size="small"
                      sx={{ width: "100%" }}
                      name="branch_name"
                      onChange={(e, options) => {
                        setFieldValue(
                          "branch_name",
                          options?.props?.action?._id
                        );
                        setBranchDetails({ ...options?.props?.action });
                        setFinalData({
                          ...finalData,
                          branch_detail: options?.props?.action || {},
                        });
                      }}
                      defaultValue={values?._id}
                    >
                      {supplierBranchList?.map((item) => {
                        return (
                          <MenuItem value={item._id} action={item}>
                            {item?.branch_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage
                      name="branch_name"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Invoice No.*
                    </Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="invoice_no"
                      onChange={(e) => {
                        setFieldValue("invoice_no", e.target.value);
                      }}
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({
                          ...finalData,
                          invoice_no: e.target.value,
                        });
                      }}
                      value={values?.invoice_no}
                      status={true}
                      error={touched?.invoice_no && errors?.invoice_no}
                      helperText={touched?.invoice_no && errors?.invoice_no}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Currency*
                    </Typography>
                    <Autocomplete
                      size="small"
                      // disabled={values?.supplier_details?.country === "India"}
                      options={currencyList}
                      name="currency"
                      // value={values?.currency}
                      getOptionLabel={(option) => option?.currency_name}
                      onBlur={() => setFieldTouched("currency", true)}
                      onChange={(e, selectedData) => {
                        setFinalData({ ...finalData, currency: selectedData });
                        setFieldValue("currency", selectedData?._id);
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.currency_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.currency && Boolean(errors.currency)} // Show error if touched and there's an error
                          helperText={touched.currency && errors.currency}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Workers*
                    </Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="no_of_workers"
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({
                          ...finalData,
                          no_of_workers: e.target.value,
                        });
                      }}
                      onChange={(e) => {
                        setFieldValue("no_of_workers", e.target.value);
                      }}
                      value={values?.no_of_workers}
                      status={true}
                      error={touched?.no_of_workers && errors?.no_of_workers}
                      helperText={
                        touched?.no_of_workers && errors?.no_of_workers
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Shift*
                    </Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="shift"
                      onChange={(e) => {
                        setFieldValue("shift", e.target.value);
                      }}
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({ ...finalData, shift: e.target.value });
                      }}
                      value={values?.shift}
                      status={true}
                      error={touched?.shift && errors?.shift}
                      helperText={touched?.shift && errors?.shift}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Working Hours*
                    </Typography>
                    <TextField
                      type="number"
                      sx={{ width: "100%" }}
                      size="small"
                      name="working_hours"
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({
                          ...finalData,
                          working_hours: e.target.value,
                        });
                      }}
                      onChange={(e) => {
                        setFieldValue("working_hours", e.target.value);
                      }}
                      value={values?.working_hours}
                      status={true}
                      error={touched?.working_hours && errors?.working_hours}
                      helperText={
                        touched?.working_hours && errors?.working_hours
                      }
                    />
                  </Grid>
                </Grid>
              </Div>
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => {
                  handleSubmit();
                  setNewTabValue(newValue);
                }}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Item Details" {...a11yProps(0)} />
                <Tab label="Invoice Details" {...a11yProps(4)} />
              </Tabs>
            </Form>
          )}
        </Formik>
        <CustomTabPanel value={tabValue} index={0}>
          {true ? (
            <ItemDetailsTable
              itemSubCategoryNameList={itemSubCategoryNameList}
              itemNameList={itemNameList}
              setAddedItemDetailsList={setAddedItemDetailsList}
              addedItemDetailsList={addedItemDetailsList}
              finalData={finalData}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          {true ? (
            <InvoiceDetailsTable
              setAddedItemDetailsList={setAddedItemDetailsList}
              addedItemDetailsList={addedItemDetailsList}
              // setInvoiceDetails={setInvoiceDetails}
              finalData={finalData}
              tabValue={tabValue}
              gstNameList={gstNameList}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
      </Div>
    </Div>
  );
}
