import { Autocomplete, Box, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { inwardSrnoDropdown, itemSrnoDropdown } from '../../../../../services/apis/Inventory/ApiCall';
import { itemNameDropDown } from 'app/services/apis/addItemName';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { supplierDropDownList } from 'app/services/apis/supplierList';

const datepickerStyling = {
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#aeb7bd ", // or any other color you prefer
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#475259", // Change to the color you want on hover
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#7352C7", // Change to the color you want when focused
    },
    "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
    {
        borderColor: "#7352C7", // Keep the same color as focused when hovered
    },
    "& .MuiInputLabel-outlined": {
        color: "#98a3aa", // Default label color
        "&.Mui-focused": {
            color: "#7352C7", // Label color on focus
        },
    },
};

export const ItemSrNo = React.memo(({ value, setFilters }) => {
    const [itemSrno, setItemSrno] = useState([]);
    console.log("first",1)

    useEffect(() => {
        (async () => {
            const { data } = await itemSrnoDropdown();
            setItemSrno(data);
        })()
    }, [])

    return (
        <Autocomplete
            freeSolo
            key={value}
            sx={{ width: "100%", textTransform: "capitalize" }}
            size="small"
            id="role-autocomplete"
            value={value}
            options={itemSrno || []}
            getOptionLabel={(option) => option?.toString()}
            onChange={(e, newValue) => {
                setFilters((prev) => ({ ...prev, item_sr_no: newValue }));
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option}
                </Box>
            )}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Item Sr.no" />}
        />
    )
})

export const InwardSrNo = React.memo(({ value, setFilters }) => {
    const [inwardSrno, setInwardSrno] = useState([]);
    console.log("first",2)

    useEffect(() => {
        (async () => {
            const { data } = await inwardSrnoDropdown();
            setInwardSrno(data);
        })()
    }, [])

    return (
        <Autocomplete
            freeSolo
            key={value}
            sx={{ width: "100%", textTransform: "capitalize" }}
            size="small"
            id="role-autocomplete"
            value={value}
            options={inwardSrno || []}
            getOptionLabel={(option) => option?.toString()}
            onChange={(e, newValue) => {
                setFilters((prev) => ({ ...prev, inward_sr_no: newValue }));
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option}
                </Box>
            )}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Inward Sr.no" />}
        />
    )
})

export const ItemName = React.memo(({ value, setFilters }) => {
    const [itemName, setItemName] = useState([]);
    console.log("first",3)

    useEffect(() => {
        (async () => {
            const { data: { result } } = await itemNameDropDown("test2");
            setItemName(result);
        })()
    }, [])

    return (
        <Autocomplete
            freeSolo
            // key={value}
            sx={{ width: "100%", textTransform: "capitalize" }}
            size="small"
            id="role-autocomplete"
            value={value}
            options={itemName || []}
            getOptionLabel={(option) => {
                if (option instanceof Object && !Array.isArray(option)) {
                    return option.item_name
                } else {
                    return option
                }
            }}
            isOptionEqualToValue={(option,value)=> option?.item_name === value}
            onChange={(e, newValue) => {
                setFilters((prev) => ({ ...prev, item_name: newValue?.item_name }));
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option?.item_name}
                </Box>
            )}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Item Name" />}
        />
    )
})

export const SupplierList = React.memo(({ name,value, setFilters }) => {
    const [suppliers, setSuppliers] = useState([]);
    console.log("first",3)

    useEffect(() => {
        (async () => {
            const { data: { result } } = await supplierDropDownList("log");
            setSuppliers(result);
        })()
    }, [])

    return (
        <Autocomplete
            freeSolo
            key={value}
            sx={{ width: "100%", textTransform: "capitalize" }}
            size="small"
            id="role-autocomplete"
            value={value}
            options={suppliers || []}
            getOptionLabel={(option) => {
                if (option instanceof Object && !Array.isArray(option)) {
                    return option.supplier_name
                } else {
                    return option
                }
            }}
            isOptionEqualToValue={(option,value)=> option?.supplier_name === value}
            onChange={(e, newValue) => {
                setFilters((prev) => ({ ...prev, [name]: newValue?.supplier_name }));
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option?.supplier_name}
                </Box>
            )}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Suppliers" />}
        />
    )
})

export const CustomDatePicker = React.memo(({ name,position,title, value, setFilters }) => {
    console.log("first",4,value)
    return (

        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                key={value}
                id={title}
                format="DD-MM-YYYY"
                name={title}
                label={title}
                sx={datepickerStyling}
                value={value !== "" ? dayjs(value, "DD-MM-YYYY") : null}
                maxDate={dayjs()}
                onChange={(newValue) => {
                    setFilters((prev) => {
                        const updatedData = prev;
                        updatedData.range.date[name][position] = newValue?.format("DD-MM-YYYY");
                        return {...updatedData}
                    });
                }}
                slotProps={{ textField: { size: "small" } }}
            />
        </LocalizationProvider>
    )
})