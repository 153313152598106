//old
// import React, { useEffect, useState } from "react";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import Typography from "@mui/material/Typography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Fade from "@mui/material/Fade";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import FullScreenLoader from "app/components/ListingPageLoader";
// import ContactPersonCrad from "./ContactPersonCrad";
// import { Button, Paper, TextField } from "@mui/material";
// import FormTextField1 from "app/components/InputField/FormTextField1";
// import { Axios } from "index";
// import Swal from "sweetalert2";
// import { getAllBranchesBySupplierId } from "app/redux/actions/masterAction";
// import CreatePersonModal from "./CreatePersonModal";

// function BranchesAccordion({
//   searchTerm,
//   setPage,
//   page,
//   sort,
//   sortBy,
//   setSort,
//   setSortBy,
//   rowsPerPage,
// }) {
//   const navigate = useNavigate();
//   const [expandedId, setExpandedId] = useState(null);
//   const [loader, setLoader] = useState(false);
//   const disaptch = useDispatch();
//   const { id } = useParams();
//   const [branchData, setBranchData] = useState({});
//   const { supplierMasterBranches, TotalPage, loading } = useSelector(
//     (state) => state.masterReducer
//   );
//   const [open, setOpen] = useState(false);
//   const [toggle, setToggle] = useState(false);
//   const handleOpenModal = () => setOpen(true);
//   const handleCloseModal = () => setOpen(false);
//   const [currentBranchId, setCurrentBranchId] = useState(null);

//   const handleExpansion = (id) => {
//     setExpandedId((prevExpandedId) => (prevExpandedId === id ? null : id));
//   };

//   // const handleSort = (property) => {
//   //     setSort(sort === "asc" ? "desc" : "asc");
//   //     setSortBy(property);
//   //     // setPage(1);
//   // };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   useEffect(() => {
//     setLoader(loading);
//   }, [loading]);
//   const handleInputChange = (branchId, field, value) => {
//     setBranchData((prev) => ({
//       ...prev,
//       [branchId]: {
//         ...prev[branchId],
//         [field]: value,
//       },
//     }));
//   };

//   const handleUpdation = async (branchId) => {
//     try {
//       const updatedData = branchData[branchId];
//       console.log("updted data= >", updatedData);
//       const { data } = await Axios.post(
//         `/supplier-master/update-supplier-branch?id=${branchId}`,
//         updatedData
//       );
//       if (data.statusCode === 200) {
//         Swal.fire({
//           icon: "success",
//           title: data.message,
//           text: "",
//           timer: 1000,
//           showConfirmButton: false,
//         });
//         disaptch(
//           getAllBranchesBySupplierId("", page, rowsPerPage, sortBy, sort, id)
//         );
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: data.message,
//           text: "",
//           timer: 1000,
//           showConfirmButton: false,
//         });
//       }
//     } catch (error) {
//       console.log("err updating branch data => ", error.message);
//     }
//   };

//   useEffect(() => {
//     disaptch(
//       getAllBranchesBySupplierId("", page, rowsPerPage, sortBy, sort, id)
//     );
//   }, [id, page, rowsPerPage, sort, sortBy, toggle]);

//   return (
//     <div>
//       {loader && <FullScreenLoader />}
//       {supplierMasterBranches?.map((branch, index) => (
//         <div className="mb-5  " key={index}>
//           <Accordion
//             component={Paper}
//             expanded={expandedId === branch._id}
//             onChange={() => handleExpansion(branch._id)}
//             slots={{ transition: Fade }}
//             slotProps={{ transition: { timeout: 400 } }}
//             sx={[
//               expandedId === branch._id
//                 ? {
//                     "& .MuiAccordion-region": {
//                       height: "auto",
//                     },
//                     "& .MuiAccordionDetails-root": {
//                       display: "block",
//                     },
//                   }
//                 : {
//                     "& .MuiAccordion-region": {
//                       height: 0,
//                     },
//                     "& .MuiAccordionDetails-root": {
//                       display: "none",
//                     },
//                   },
//             ]}
//           >
//             <AccordionSummary
//               expandIcon={<ExpandMoreIcon />}
//               aria-controls="panel1-content"
//               id="panel1-header"
//             >
//               <Typography className="flex-1" variant="h5">
//                 {branch.branch_name}
//               </Typography>
//               <Button
//                 size="small"
//                 variant="contained"
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   handleOpenModal();
//                   setCurrentBranchId(branch._id);
//                 }}
//                 style={{ paddingRight: 10 }}
//               >
//                 Add Contact Person
//               </Button>
//             </AccordionSummary>

//             <AccordionDetails>
//               <ContactPersonCrad
//                 totalPages={TotalPage}
//                 personData={branch.contact_person}
//                 page={page}
//                 rowsPerPage={rowsPerPage}
//                 handleChangePage={handleChangePage}
//                 setToogle={setToggle}
//               />
//               <div className="grid grid-flow-row lg:grid-cols-4 grid-cols-2 gap-5 mt-6">
//                 <div className="flex flex-col items-start">
//                   <Typography variant="h5">Branch Name</Typography>
//                   <TextField
//                     size="small"
//                     fullWidth
//                     defaultValue={branch.branch_name}
//                     onChange={(e) =>
//                       handleInputChange(
//                         branch._id,
//                         "branch_name",
//                         e.target.value
//                       )
//                     }
//                   />
//                 </div>
//                 <div className="flex flex-col items-start">
//                   <Typography variant="h5">Pincode</Typography>
//                   <TextField
//                     size="small"
//                     fullWidth
//                     defaultValue={branch.pincode}
//                     onChange={(e) =>
//                       handleInputChange(branch._id, "pincode", e.target.value)
//                     }
//                   />
//                 </div>
//                 <div className="flex flex-col items-start">
//                   <Typography variant="h5">GST Number</Typography>
//                   <TextField
//                     size="small"
//                     fullWidth
//                     defaultValue={branch.gst_number}
//                     onChange={(e) =>
//                       handleInputChange(
//                         branch._id,
//                         "gst_number",
//                         e.target.value
//                       )
//                     }
//                   />
//                 </div>
//                 <div className="flex flex-col items-start">
//                   <Typography variant="h5">Address</Typography>
//                   <TextField
//                     size="small"
//                     fullWidth
//                     defaultValue={branch.address}
//                     onChange={(e) =>
//                       handleInputChange(branch._id, "address", e.target.value)
//                     }
//                   />
//                 </div>
//                 <div className="flex flex-col items-start">
//                   <Typography variant="h5">State</Typography>
//                   <TextField
//                     size="small"
//                     fullWidth
//                     defaultValue={branch.state}
//                     onChange={(e) =>
//                       handleInputChange(branch._id, "state", e.target.value)
//                     }
//                   />
//                 </div>
//                 <div className="flex flex-col items-start">
//                   <Typography variant="h5">Country</Typography>
//                   <TextField
//                     size="small"
//                     fullWidth
//                     defaultValue={branch.country}
//                     onChange={(e) =>
//                       handleInputChange(branch._id, "country", e.target.value)
//                     }
//                   />
//                 </div>
//                 <div className="flex flex-col items-start">
//                   <Typography variant="h5">City</Typography>
//                   <TextField
//                     size="small"
//                     fullWidth
//                     defaultValue={branch.city}
//                     onChange={(e) =>
//                       handleInputChange(branch._id, "city", e.target.value)
//                     }
//                   />
//                 </div>
//                 <div className="flex flex-col items-start">
//                   <Typography variant="h5">Web Url</Typography>
//                   <TextField
//                     size="small"
//                     fullWidth
//                     defaultValue={branch.web_url}
//                     onChange={(e) =>
//                       handleInputChange(branch._id, "web_url", e.target.value)
//                     }
//                   />
//                 </div>
//               </div>
//               <div className="flex flex-row items-center justify-center gap-6 mt-6">
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   sx={{ py: 1 }}
//                   size="medium"
//                   onClick={() => {
//                     Swal.fire({
//                       title: "Are you sure you want to cancel?",
//                       icon: "warning",
//                       showCancelButton: true,
//                       confirmButtonText: "Yes",
//                       cancelButtonText: "No",
//                     }).then((result) => {
//                       if (result.isConfirmed) {
//                         navigate("/master/supplier");
//                       }
//                     });
//                   }}
//                 >
//                   Cancel
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   sx={{ py: 1 }}
//                   size="medium"
//                   onClick={() => {
//                     handleUpdation(branch._id);
//                   }}
//                 >
//                   Save
//                 </Button>
//               </div>
//             </AccordionDetails>
//             <CreatePersonModal
//               open={open}
//               handleClose={handleCloseModal}
//               branchId={currentBranchId}
//               setToggle={setToggle}
//             />
//           </Accordion>
//         </div>
//       ))}
//     </div>
//   );
// }

// export default BranchesAccordion;

//!new
// import React, { useEffect, useState } from "react";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import Typography from "@mui/material/Typography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Fade from "@mui/material/Fade";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import FullScreenLoader from "app/components/ListingPageLoader";
// import ContactPersonCrad from "./ContactPersonCrad";
// import {
//   Button,
//   Grid,
//   MenuItem,
//   Paper,
//   Select,
//   TextField,
// } from "@mui/material";
// import { Axios } from "index";
// import Swal from "sweetalert2";
// import { getAllBranchesBySupplierId } from "app/redux/actions/masterAction";
// import CreatePersonModal from "./CreatePersonModal";
// import Div from "@jumbo/shared/Div";
// import { outerDiv1 } from "app/utils/constants/dropdowns";
// import { City, Country, State } from "country-state-city";

// function BranchesAccordion({
//   searchTerm,
//   setPage,
//   page,
//   sort,
//   sortBy,
//   setSort,
//   setSortBy,
//   rowsPerPage,
// }) {
//   const navigate = useNavigate();
//   const [expandedId, setExpandedId] = useState(null);
//   const [loader, setLoader] = useState(false);
//   const [countryList, setCountryList] = useState(Country.getAllCountries());
//   const [stateList, setStateList] = useState([]);
//   const [cityList, setCityList] = useState([]);
//   const [contacts, setContacts] = useState([]);
//   const dispatch = useDispatch();
//   const { id } = useParams();
//   const [branchData, setBranchData] = useState({});
//   const { supplierMasterBranches, TotalPage, loading } = useSelector(
//     (state) => state.masterReducer
//   );
//   const [open, setOpen] = useState(false);
//   const [toggle, setToggle] = useState(false);
//   const handleOpenModal = () => setOpen(true);
//   const handleCloseModal = () => setOpen(false);
//   const [currentBranchId, setCurrentBranchId] = useState(null);

//   const handleExpansion = (id) => {
//     setExpandedId((prevExpandedId) => (prevExpandedId === id ? null : id));
//   };

//   const filterStates = (countryIsoCode) => {
//     const filteredStates = State.getAllStates().filter(
//       (state) => state.countryCode === countryIsoCode
//     );
//     setStateList(filteredStates);
//   };

//   const filterCities = (stateIsoCode, countryCode) => {
//     const filteredCities = City.getAllCities().filter(
//       (city) =>
//         city.stateCode === stateIsoCode && city.countryCode === countryCode
//     );
//     setCityList(filteredCities);
//   };

//   useEffect(() => {
//     if (branchData.country) {
//       filterStates(branchData.country);
//     }
//   }, [branchData.country]);

//   useEffect(() => {
//     if (branchData.state) {
//       filterCities(branchData.state, branchData.country);
//     }
//   }, [branchData.state, branchData.country]);

//   useEffect(() => {
//     setLoader(loading);
//   }, [loading]);

//   useEffect(() => {
//     dispatch(
//       getAllBranchesBySupplierId("", page, rowsPerPage, sortBy, sort, id)
//     );
//   }, [id, page, rowsPerPage, sort, sortBy, toggle]);

//   const handleInputChange = (branchId, field, value) => {
//     setBranchData((prev) => ({
//       ...prev,
//       [branchId]: {
//         ...prev[branchId],
//         [field]: value,
//       },
//     }));
//   };

//   const handleUpdation = async (branchId) => {
//     try {
//       const updatedData = branchData[branchId];
//       const { data } = await Axios.post(
//         `/supplier-master/update-supplier-branch?id=${branchId}`,
//         updatedData
//       );
//       if (data.statusCode === 200) {
//         Swal.fire({
//           icon: "success",
//           title: data.message,
//           text: "",
//           timer: 1000,
//           showConfirmButton: false,
//         });
//         dispatch(
//           getAllBranchesBySupplierId("", page, rowsPerPage, sortBy, sort, id)
//         );
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: data.message,
//           text: "",
//           timer: 1000,
//           showConfirmButton: false,
//         });
//       }
//     } catch (error) {
//       console.log("err updating branch data => ", error.message);
//     }
//   };

//   //default values for state and city

//   // useEffect(() => {
//   //   if (stateData) {
//   //     const country = Country.getAllCountries().filter(
//   //       (country) => country.name === stateData?.supplierDetails?.[0]?.country
//   //     );
//   //     const stateList = State.getAllStates().filter(
//   //       (country) => country.name === stateData?.supplierDetails?.[0]?.state
//   //     );
//   //     console.log("contries : ", country);
//   //     console.log("state : ", stateList);

//   //     filterStates(country[0]?.isoCode);
//   //     filterCities(stateList[0]?.isoCode, country[0]?.isoCode);
//   //   }
//   // }, [stateData]);

//   return (
//     <div>
//       {loader && <FullScreenLoader />}
//       {supplierMasterBranches?.map((branch, index) => (
//         <div className="mb-5" key={index}>
//           {console.log("default branch data => ", branch)}
//           <Accordion
//             component={Paper}
//             expanded={expandedId === branch._id}
//             onChange={() => handleExpansion(branch._id)}
//             slots={{ transition: Fade }}
//             slotProps={{ transition: { timeout: 400 } }}
//             sx={[
//               expandedId === branch._id
//                 ? {
//                     "& .MuiAccordion-region": {
//                       height: "auto",
//                     },
//                     "& .MuiAccordionDetails-root": {
//                       display: "block",
//                     },
//                   }
//                 : {
//                     "& .MuiAccordion-region": {
//                       height: 0,
//                     },
//                     "& .MuiAccordionDetails-root": {
//                       display: "none",
//                     },
//                   },
//             ]}
//           >
//             <AccordionSummary
//               expandIcon={<ExpandMoreIcon />}
//               aria-controls="panel1-content"
//               id="panel1-header"
//             >
//               <Typography className="flex-1" variant="h5">
//                 {branch.branch_name}
//               </Typography>
//               <Button
//                 size="small"
//                 variant="contained"
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   handleOpenModal();
//                   setCurrentBranchId(branch._id);
//                 }}
//                 style={{ paddingRight: 10 }}
//               >
//                 Add Contact Person
//               </Button>
//             </AccordionSummary>

//             <AccordionDetails>
//               <ContactPersonCrad
//                 totalPages={TotalPage}
//                 personData={branch.contact_person}
//                 page={page}
//                 rowsPerPage={rowsPerPage}
//                 // handleChangePage={handleChangePage}
//                 setToogle={setToggle}
//               />
//               <div className="grid grid-flow-row lg:grid-cols-4 grid-cols-2 gap-5 mt-6">
//                 <div className="flex flex-col items-start">
//                   <Typography variant="h5">Branch Name</Typography>
//                   <TextField
//                     size="small"
//                     fullWidth
//                     defaultValue={branch.branch_name}
//                     onChange={(e) =>
//                       handleInputChange(
//                         branch._id,
//                         "branch_name",
//                         e.target.value
//                       )
//                     }
//                   />
//                 </div>
//                 <div className="flex flex-col items-start">
//                   <Typography variant="h5">Pincode</Typography>
//                   <TextField
//                     size="small"
//                     fullWidth
//                     defaultValue={branch.pincode}
//                     onChange={(e) =>
//                       handleInputChange(branch._id, "pincode", e.target.value)
//                     }
//                   />
//                 </div>
//                 <div className="flex flex-col items-start">
//                   <Typography variant="h5">GST Number</Typography>
//                   <TextField
//                     size="small"
//                     fullWidth
//                     defaultValue={branch.gst_number}
//                     onChange={(e) =>
//                       handleInputChange(
//                         branch._id,
//                         "gst_number",
//                         e.target.value
//                       )
//                     }
//                   />
//                 </div>
//                 <div className="flex flex-col items-start">
//                   <Typography variant="h5">Address</Typography>
//                   <TextField
//                     size="small"
//                     fullWidth
//                     defaultValue={branch.address}
//                     onChange={(e) =>
//                       handleInputChange(branch._id, "address", e.target.value)
//                     }
//                   />
//                 </div>
//                 <Grid item xs={6} md={3}>
//                   <Div sx={outerDiv1}>
//                     <Typography variant="h5">Country</Typography>
//                     <Select
//                       fullWidth
//                       value={branch?.country || ""}
//                       size="small"
//                       onChange={(e) => {
//                         const selectedCountry = e.target.value;
//                         handleInputChange(
//                           branch._id,
//                           "country",
//                           selectedCountry
//                         );
//                         filterStates(
//                           Country.getAllCountries(selectedCountry)?.isoCode
//                         );
//                       }}
//                     >
//                       <MenuItem value="">Select</MenuItem>
//                       {countryList?.map((country) => (
//                         <MenuItem value={country?.name} key={country?.isoCode}>
//                           {country?.name}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                     <Div style={{ height: "30px" }}></Div>
//                   </Div>
//                 </Grid>
//                 <Grid item xs={6} md={3}>
//                   <Div sx={outerDiv1}>
//                     <Typography variant="h5">State</Typography>
//                     <Select
//                       fullWidth
//                       value={branch?.state || ""}
//                       size="small"
//                       onChange={(e) => {
//                         const selectedState = e.target.value;
//                         handleInputChange(branch._id, "state", selectedState);
//                         filterCities(
//                           State.getStateByName(selectedState)?.isoCode,
//                           branch.country
//                         );
//                       }}
//                     >
//                       <MenuItem value="">Select</MenuItem>
//                       {stateList?.map((state) => (
//                         <MenuItem value={state?.name} key={state?.isoCode}>
//                           {state?.name}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                     <Div style={{ height: "30px" }}></Div>
//                   </Div>
//                 </Grid>
//                 <Grid item xs={6} md={3}>
//                   <Div sx={outerDiv1}>
//                     <Typography variant="h5">City</Typography>
//                     <Select
//                       fullWidth
//                       value={branch?.city || ""}
//                       size="small"
//                       onChange={(e) =>
//                         handleInputChange(branch._id, "city", e.target.value)
//                       }
//                     >
//                       <MenuItem value="">Select</MenuItem>
//                       {cityList?.map((city) => (
//                         <MenuItem value={city?.name} key={city?.isoCode}>
//                           {city?.name}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                     <Div style={{ height: "30px" }}></Div>
//                   </Div>
//                 </Grid>
//                 <Grid item xs={12}>
//                   <Button
//                     onClick={() => handleUpdation(branch._id)}
//                     variant="contained"
//                     color="primary"
//                     fullWidth
//                   >
//                     Update Branch
//                   </Button>
//                 </Grid>
//               </div>
//             </AccordionDetails>
//           </Accordion>
//         </div>
//       ))}

//       <CreatePersonModal
//         open={open}
//         handleCloseModal={handleCloseModal}
//         currentBranchId={currentBranchId}
//       />
//     </div>
//   );
// }

// export default BranchesAccordion;

//breakdown accordion into diffrent compos

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FullScreenLoader from "app/components/ListingPageLoader";
import BranchAccordionItem from "./BranchAccordionItem";
import CreatePersonModal from "./CreatePersonModal";
import { getAllBranchesBySupplierId } from "app/redux/actions/masterAction";
import { Typography } from "@mui/material";

function BranchesAccordion({ page, rowsPerPage, sort, sortBy }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { supplierMasterBranches, TotalPage, loading } = useSelector(
    (state) => state.masterReducer
  );
  const [open, setOpen] = useState(false);
  const [currentBranchId, setCurrentBranchId] = useState(null);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    dispatch(
      getAllBranchesBySupplierId("", page, rowsPerPage, sortBy, sort, id)
    );
  }, [id, page, rowsPerPage, sort, sortBy, toggle]);

  const handleOpenModal = (branchId) => {
    setCurrentBranchId(branchId);
    console.log("bid in func => ", branchId);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <div>
      {loading && <FullScreenLoader />}
      {supplierMasterBranches?.length > 0 ? (
        supplierMasterBranches?.map((branch) => (
          <BranchAccordionItem
            key={branch._id}
            branch={branch}
            handleOpenModal={handleOpenModal}
            page={page}
            rowsPerPage={rowsPerPage}
            TotalPage={TotalPage}
            setToggle={setToggle}
          />
        ))
      ) : (
        <Typography variant="h2" textAlign="center">
          No Branches Found...
        </Typography>
      )}
      <CreatePersonModal
        open={open}
        handleCloseModal={handleCloseModal}
        branchId={currentBranchId}
        setToggle={setToggle}
      />
    </div>
  );
}

export default BranchesAccordion;
