import apiUrl from "app/utils/Axios.Config";

export const addSupplierBranch = async (details, id) => {
  try {
    const response = await apiUrl.post(
      `/supplier-master/add-branch/${id}`,
      details
    );
    return response;
  } catch (error) {
    console.log("err add branch => ", error.message);
  }
};
