import { Axios } from "index";
import { LOG_INVENTORY_FAILED, LOG_INVENTORY_REQUEST, LOG_INVENTORY_SUCCESS } from "./constant"

const filterObjectWithValues = (obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        // Check if value is an object, and recursively filter it
        if (typeof value === 'object' && value !== null) {
            const filteredNestedObject = filterObjectWithValues(value);
            if (Object.keys(filteredNestedObject).length > 0) {
                acc[key] = filteredNestedObject;
            }
        }
        // Add key-value pair if the value is non-empty (for strings or other primitives)
        else if (value !== "") {
            acc[key] = value;
        }
        return acc;
    }, {});
};

export const fetchLogInventory = function ({ search = "", sort = "desc", sortBy = "updatedAt", page = 1, limit = 10, filter = {} }) {
    return async function (dispatch) {
        try {
            dispatch({ type: LOG_INVENTORY_REQUEST });

            const body = {
                filter: {
                    ...filterObjectWithValues(filter),
                },
                searchFields: {
                    string: [
                        "supplier_item_name",
                        "supplier_log_no",
                        "item_name",
                        "log_no",
                        "supplier_details.company_details.supplier_name",
                        "log_invoice_details.invoice_Details.invoice_no",
                        "item_sub_category_name",
                        "log_formula",
                        "remark",
                    ],
                    numbers: [
                        "item_sr_no",
                        "invoice_length",
                        "invoice_diameter",
                        "invoice_cmt",
                        "indian_cmt",
                        "physical_length",
                        "physical_diameter",
                        "physical_cmt",
                        "exchange_rate",
                        "rate_in_currency",
                        "rate_in_inr",
                        "amount",
                    ],
                    arrayField: [],
                },
            };
            if (!search) {
                search = "";
            }

            const config = {
                withCredentials: true,
                headers: {
                    withCredentials: true,
                },
            };

            const urlParams = new URLSearchParams({
                search: search.trim(),
                page: page,
                sort: sort,
                sortBy: sortBy,
                limit: limit,
            });

            const response = await Axios.post(`/log-inventory/list-inventory?${urlParams.toString()}`,
                body,
                config
            );
            dispatch({
                type: LOG_INVENTORY_SUCCESS, payload: {
                    data: response?.data?.data,
                    totalPage: response?.data?.totalPage
                }
            })
        } catch (error) {
            dispatch({
                type: LOG_INVENTORY_FAILED, payload: {
                    isError: true,
                    message: error.message
                }
            })
        }
    }
}