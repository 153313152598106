// import React, { useEffect, useState } from "react";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Modal from "@mui/material/Modal";
// import { Close } from "@mui/icons-material";

// import { TextField, Typography } from "@mui/material";
// // import ToastAlerts from '../Toast';
// import { useNavigate } from "react-router-dom";
// import { Axios } from "index";
// import Swal from "sweetalert2";
// const style = {
//   position: "absolute",
//   top: "40%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   // width: 400,
//   bgcolor: "background.paper",
//   border: "1px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

// function SubcategoryModal({ open, handleClose, subcategoryData, setToggle }) {
//   console.log("values got => ", subcategoryData);
//   // const nvaigate = useNavigate();
//   const [subcategory, setsubcategory] = useState({
//     name: "",
//   });

//   useEffect(() => {
//     if (subcategoryData) {
//       setsubcategory({ name: subcategoryData?.data?.name });
//     } else {
//       setsubcategory({ name: "" });
//     }
//   }, [subcategoryData]);

//   const handleSubmit = async () => {
//     try {
//       let res;
//       for (let key in subcategoryData) {
//         if (key === "name") {
//           subcategoryData[key] = subcategoryData[key].toUpperCase();
//         }
//       }
//       if (subcategoryData) {
//         res = await Axios.post(
//           `/item-subcategory/update/${subcategoryData.id}`,
//           subcategory
//         );
//       } else {
//         res = await Axios.post("/item-subcategory/add", subcategory);
//       }

//       if (res.data.statusCode === 200) {
//         Swal.fire({
//           icon: "success",
//           title: `${
//             subcategoryData
//               ? "SubCategory Updated Successfully"
//               : "SubCategory Created Successfully"
//           } `,
//           text: "",
//           timer: 1000,
//           showConfirmButton: false,
//         });
//         setToggle(true);
//         handleClose();
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: res.data.message,
//           text: "",
//           timer: 1000,
//           showConfirmButton: false,
//         });
//       }
//     } catch (error) {
//       console.log("err adding subcategory => ", error.message);
//     }
//   };

//   return (
//     <div>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style} className="lg:w-1/3 w-[20rem]">
//           <div className="flex flex-row items-center justify-between">
//             <Typography variant="h2" component="h2">
//               Add Subcategory
//             </Typography>
//             <Close
//               onClick={handleClose}
//               className="mb-4"
//               style={{ cursor: "pointer", float: "right" }}
//             />
//           </div>
//           <div className="flex flex-col items-start mt-6 justify-center ">
//             <label>Subcategory Name*</label>
//             <TextField
//               size="small"
//               fullWidth
//               margin="normal"
//               name="name"
//               value={subcategory?.name}
//               onChange={(e) =>
//                 setsubcategory({ ...subcategory, name: e.target.value })
//               }
//             />
//           </div>
//           <div className="flex items-center justify-center mt-5">
//             <Button variant="contained" color="primary" onClick={handleSubmit}>
//               {subcategoryData ? "Update" : "Add"}
//             </Button>
//           </div>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

// export default SubcategoryModal;

import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Typography, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { Axios } from "index"; // Assuming Axios is properly configured
import { LoadingButton } from "@mui/lab";
import FormTextField1 from "app/components/InputField/FormTextField1";
import { useDispatch } from "react-redux";
import { listItemSubCatgory } from "app/redux/actions/masterAction";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object({
  name: yup
    .string("Enter Subcategory Name")
    .required("Subcategory Name is required"),
  remark: yup.string("Enter remarks"),
});

function SubcategoryModal({ open, handleClose, subcategoryData, setToggle }) {
  const initialValues = {
    name: subcategoryData?.data?.name ? subcategoryData?.data?.name : "",
    remark: subcategoryData?.data?.remark ? subcategoryData?.data?.remark : "",
  };
  const dispatch = useDispatch();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let res;
      values.name = values.name.toUpperCase();

      if (subcategoryData) {
        res = await Axios.post(
          `/item-subcategory/update/${subcategoryData.id}`,
          values
        );
      } else {
        res = await Axios.post("/item-subcategory/add", values);
      }

      if (res.data.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: subcategoryData
            ? "Subcategory Updated Successfully"
            : "Subcategory Created Successfully",
          timer: 1000,
          showConfirmButton: false,
        });
        setToggle((prevState) => !prevState);
        dispatch(listItemSubCatgory("", "desc", "updated_at", 1));
        handleClose();
      } else {
        Swal.fire({
          icon: "error",
          title: res.data.message,
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.log("Error adding/updating subcategory => ", error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
      <Box sx={style} className="lg:w-1/3 w-[20rem]">
        <div className="flex flex-row items-center justify-between">
          <Typography variant="h2">
            {subcategoryData ? "Edit Subcategory" : "Add Subcategory"}
          </Typography>
          <Close onClick={handleClose} style={{ cursor: "pointer" }} />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, handleChange, isSubmitting }) => (
            <Form noValidate>
              <div className="grid grid-flow-row lg:grid-cols-2 grid-cols-1 gap-5 mt-5">
                <FormTextField1 name="name" label="Subcategory Name*" />
                <FormTextField1 name="remark" label="Remark" />
              </div>

              <div className="flex items-center justify-center mt-5">
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={isSubmitting}
                >
                  {subcategoryData ? "Update" : "Add"}
                </LoadingButton>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

export default SubcategoryModal;
