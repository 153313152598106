import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  Pagination,
  Typography,
} from "@mui/material";
import { displayDateFun } from "app/utils/constants/functions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Div from "@jumbo/shared/Div";
import { getRoleLogList } from "app/services/apis/ListApi/RoleLogList";
import LogInfoModal from "./logInfoModal";

export default function RoleLogTable() {
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [logList, setLogList] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(1);

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = logList?.result?.sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  useEffect(async () => {
    setLogList(await getRoleLogList());
  }, []);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    setLogList(await getRoleLogList(newPage));
  };
  const handleInfoIconClick = (rowData) => {
    setSelectedRowData(rowData);
    setInfoModalOpen(true);
  };

  return (
    <>
      <Div sx={{ mt: -4, mb: 4 }}>
        <Typography variant="h1">Roles & Permissions Log</Typography>
      </Div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                py: 2,
              },
            }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "User Name"}
                  direction={order}
                  onClick={() => handleSort("user_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Employee ID
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "first_name"}
                  direction={order}
                  onClick={() => handleSort("first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Employee Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "100px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "role"}
                  direction={order}
                  onClick={() => handleSort("role")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Action
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "100px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "role"}
                  direction={order}
                  onClick={() => handleSort("role")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Role
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "role_logs_update_date"}
                  direction={order}
                  onClick={() => handleSort("role_logs_update_date")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "40px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "action"}
                  direction={order}
                  onClick={() => handleSort("action")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  View
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row, i) => (
              <TableRow key={i}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    pl: 2,
                  }}
                >
                  {row.user.user_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {`${row.user.first_name} ${row.user.last_name}`}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.role_action}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.role.role_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {displayDateFun(row.role_logs_create_date)}
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    maxWidth: "100px",
                    pl: 3,
                  }}
                >
                  <VisibilityIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleInfoIconClick(row)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <LogInfoModal
          open={isInfoModalOpen}
          onClose={() => setInfoModalOpen(false)}
          rowData={selectedRowData}
        />
        <Pagination size="medium"
          count={logList?.totalPages || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py:1
          }}
        />
      </TableContainer>
    </>
  );
}
