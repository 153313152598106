import Page from "@jumbo/shared/Page";
import CreateGroup from "app/pages/Grouping/Create";
import GroupingList from "app/pages/Grouping/ListGrouping";
import IssueForCutting from "app/pages/Grouping/ListGrouping/IssueForCutting";
import RejectGroup from "app/pages/Grouping/Modal/rejectGroup";
import groupingMiddleware from "./middleware/auth/groupingValidRoute";

export const groupingRoutes = [
  {
    middleware: [
      {
        element: groupingMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "grouping",
        element: <Page component={GroupingList} layout={"vertical-default"} />,
      },

      {
        path: "grouping/add",
        element: <Page component={CreateGroup} layout={"vertical-default"} />,
      },
      {
        path: "grouping/reject",
        element: <Page component={RejectGroup} layout={"vertical-default"} />,
      },
      {
        path: "grouping/issuecutting",
        element: <Page component={IssueForCutting} layout={"vertical-default"} />,
      },
    ],
  },
];
