import { combineReducers } from "redux";
import { dispatchReducer } from "./dispatchReducer";
import { factoryReducer } from "./factoryReducer";
import { groupingReducer } from "./groupingReducer";
import { inventoryReducer } from "./inventoryReducer";
import { masterReducer } from "./masterReducer";
import { orderReducer } from "./orderReducer";
import { purchaseReducer } from "./purchaseReducer";
import { roleReducer } from "./roleReducer";
import { userReducer } from "./userReducer";
import { logInventoryReducer } from "./Inventory/LogReducer";

const exportReducers = (history) => {
  return combineReducers({
    userReducer: userReducer,
    roleReducer: roleReducer,
    masterReducer: masterReducer,
    purchaseReducer: purchaseReducer,
    inventoryReducer: inventoryReducer,
    groupingReducer: groupingReducer,
    factoryReducer: factoryReducer,
    dispatchReducer: dispatchReducer,
    orderReducer: orderReducer,
    //Inventory
    logInventoryReducer:logInventoryReducer
  });
};

export default exportReducers;
