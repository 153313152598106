import axios from "axios";

export const itemSubCategoryListDropDown = async (type) => {
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.get(
        `${process.env.REACT_APP_URL}/item-subcategory/dropdown-subcategory-master?type=${type}`,
        config
      );
      // console.log(data);
      return data;
    } catch (error) {
      // console.log(error);
      return error.response;
    }
  };