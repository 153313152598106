import { Axios } from "index";

const updateMachine = async (details, id) => {
    try {
        const res = await Axios.post(`/machine-master/update-machine?id=${id}`, details);
        return res
    } catch (error) {
        console.log("err add machine => ", error.message)
    }
};

export default updateMachine;