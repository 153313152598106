import {
  ALL_INVENTORY_RAW_MATERIAL_FAIL,
  ALL_INVENTORY_RAW_MATERIAL_REQUEST,
  ALL_INVENTORY_RAW_MATERIAL_SUCCESS,
  ALL_ISSUED_DYING_FAIL,
  ALL_ISSUED_DYING_REQUEST,
  ALL_ISSUED_DYING_SUCCESS,
  ALL_ISSUED_GROUPING_FAIL,
  ALL_ISSUED_GROUPING_REQUEST,
  ALL_ISSUED_GROUPING_SUCCESS,
  ALL_ISSUED_SMOKING_FAIL,
  ALL_ISSUED_SMOKING_REQUEST,
  ALL_ISSUED_SMOKING_SUCCESS,
  ALL_OTHER_GOODS_CONSUMED_FAIL,
  ALL_OTHER_GOODS_CONSUMED_REQUEST,
  ALL_OTHER_GOODS_CONSUMED_SUCCESS,
  ALL_OTHER_GOODS_FAIL,
  ALL_OTHER_GOODS_REQUEST,
  ALL_OTHER_GOODS_SUCCESS,
  ALL_RAW_MATERIAL_HISTORY_FAIL,
  ALL_RAW_MATERIAL_HISTORY_REQUEST,
  ALL_RAW_MATERIAL_HISTORY_SUCCESS,
  ALL_READY_SHEET_FORM_FAIL,
  ALL_READY_SHEET_FORM_HISTORY_FAIL,
  ALL_READY_SHEET_FORM_HISTORY_REQUEST,
  ALL_READY_SHEET_FORM_HISTORY_SUCCESS,
  ALL_READY_SHEET_FORM_REQUEST,
  ALL_READY_SHEET_FORM_SUCCESS,
  FETCH_ALL_SUPPLIERS_FAIL,
  FETCH_ALL_SUPPLIERS_REQUEST,
  FETCH_ALL_SUPPLIERS_SUCCESS,
} from "app/utils/constants/inventoryConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  rawMaterial: [],
  otherGoods: [],
  otherGoodsConsumed: [],
  issuedGrouping: [],
  history: [],
  issuedSmoking: [],
  issuedDying: [],
  allSuppliers: [],
  TotalPage: null,
};

export const inventoryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    //raw material
    case ALL_INVENTORY_RAW_MATERIAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_INVENTORY_RAW_MATERIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        rawMaterial: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_INVENTORY_RAW_MATERIAL_FAIL:
      return {
        loading: false,
        rawMaterial: [],
        error: action.payload,
      };

    //ready sheet form
    case ALL_READY_SHEET_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_READY_SHEET_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        readySheetFormList: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_READY_SHEET_FORM_FAIL:
      return {
        loading: false,
        readySheetFormList: [],
        error: action.payload,
      };

    //ready sheet form history
    case ALL_READY_SHEET_FORM_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_READY_SHEET_FORM_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        readySheetFormHistory: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_READY_SHEET_FORM_HISTORY_FAIL:
      return {
        loading: false,
        readySheetFormHistory: [],
        error: action.payload,
      };

    //other goods
    case ALL_OTHER_GOODS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_OTHER_GOODS_SUCCESS:
      return {
        ...state,
        loading: false,
        otherGoods: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_OTHER_GOODS_FAIL:
      return {
        loading: false,
        otherGoodsConsumed: [],
        error: action.payload,
      };

    //other goods consumed
    case ALL_OTHER_GOODS_CONSUMED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_OTHER_GOODS_CONSUMED_SUCCESS:
      return {
        ...state,
        loading: false,
        otherGoodsConsumed: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_OTHER_GOODS_CONSUMED_FAIL:
      return {
        loading: false,
        otherGoodsConsumed: [],
        error: action.payload,
      };

    //Issued Grouping
    case ALL_ISSUED_GROUPING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_ISSUED_GROUPING_SUCCESS:
      return {
        ...state,
        loading: false,
        issuedGrouping: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_ISSUED_GROUPING_FAIL:
      return {
        loading: false,
        issuedGrouping: [],
        error: action.payload,
      };

    //Raw Material History
    case ALL_RAW_MATERIAL_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_RAW_MATERIAL_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        history: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_RAW_MATERIAL_HISTORY_FAIL:
      return {
        loading: false,
        history: [],
        error: action.payload,
      };

    //Issued Smoking
    case ALL_ISSUED_SMOKING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_ISSUED_SMOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        issuedSmoking: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_ISSUED_SMOKING_FAIL:
      return {
        loading: false,
        issuedDying: [],
        error: action.payload,
      };

    //Issued Dying
    case ALL_ISSUED_DYING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_ISSUED_DYING_SUCCESS:
      return {
        ...state,
        loading: false,
        issuedDying: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_ISSUED_DYING_FAIL:
      return {
        loading: false,
        issuedDying: [],
        error: action.payload,
      };

    case FETCH_ALL_SUPPLIERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_SUPPLIERS_SUCCESS:
      return {
        ...state,
        loading: false,
        allSuppliers: action.payload.data,
      };
    case FETCH_ALL_SUPPLIERS_FAIL:
      return {
        loading: false,
        allSuppliers: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
