import {
  ALL_COSTING_MASTER_SUCCESS,
  ALL_FABRIC_COLOR_MASTER_FAIL,
  ALL_FABRIC_COLOR_MASTER_REQUEST,
  ALL_FABRIC_COLOR_MASTER_SUCCESS,
  ALL_FABRIC_MASTER_FAIL,
  ALL_FABRIC_MASTER_REQUEST,
  ALL_FABRIC_MASTER_SUCCESS,
  ALL_GRADE_MASTER_FAIL,
  ALL_GRADE_MASTER_REQUEST,
  ALL_GRADE_MASTER_SUCCESS,
  ALL_CURRENCY_MASTER_FAIL,
  ALL_CURRENCY_MASTER_REQUEST,
  ALL_CURRENCY_MASTER_SUCCESS,
  ALL_CUT_MASTER_FAIL,
  ALL_CUT_MASTER_REQUEST,
  ALL_CUT_MASTER_SUCCESS,
  ALL_GST_MASTER_FAIL,
  ALL_GST_MASTER_REQUEST,
  ALL_GST_MASTER_SUCCESS,
  ALL_EXPENSE_TYPE_MASTER_FAIL,
  ALL_EXPENSE_TYPE_MASTER_REQUEST,
  ALL_EXPENSE_TYPE_MASTER_SUCCESS,
  ALL_ITEM_CODE_MASTER_FAIL,
  ALL_ITEM_CODE_MASTER_REQUEST,
  ALL_ITEM_CODE_MASTER_SUCCESS,
  ALL_ITEM_NAME_MASTER_FAIL,
  ALL_ITEM_NAME_MASTER_REQUEST,
  ALL_ITEM_NAME_MASTER_SUCCESS,
  ALL_PALLETE_MASTER_FAIL,
  ALL_PALLETE_MASTER_REQUEST,
  ALL_PALLETE_MASTER_SUCCESS,
  ALL_PARTY_MASTER_FAIL,
  ALL_PARTY_MASTER_REQUEST,
  ALL_PARTY_MASTER_SUCCESS,
  ALL_PRODUCT_MASTER_SUCCESS,
  ALL_SUPPLIER_MASTER_SUCCESS,
  ALL_UNIT_MASTER_FAIL,
  ALL_UNIT_MASTER_REQUEST,
  ALL_UNIT_MASTER_SUCCESS,
  FETCH_ALL_ITEMS_CATEGORY_FAIL,
  FETCH_ALL_ITEMS_CATEGORY_REQUEST,
  FETCH_ALL_ITEMS_CATEGORY_SUCCESS,
  FETCH_ALL_ITEMS_SUBCATEGORY_FAIL,
  FETCH_ALL_ITEMS_SUBCATEGORY_REQUEST,
  FETCH_ALL_ITEMS_SUBCATEGORY_SUCCESS,
  FETCH_ALL_SUPPLIER_MASTER_BRANCHES__FAIL,
  FETCH_ALL_SUPPLIER_MASTER_BRANCHES_LIST_REQUEST,
  FETCH_ALL_SUPPLIER_MASTER_BRANCHES_LIST_SUCCESS,
  FETCH_ALL_SUPPLIER_MASTER_FAIL,
  FETCH_ALL_SUPPLIER_MASTER_LIST_REQUEST,
  FETCH_ALL_SUPPLIER_MASTER_LIST_SUCCESS,
  LOAD_COSTING_MASTER_FAIL,
  LOAD_COSTING_MASTER_REQUEST,
  LOAD_PRODUCT_MASTER_FAIL,
  LOAD_PRODUCT_MASTER_REQUEST,
  LOAD_SUPPLIER_MASTER_FAIL,
  LOAD_SUPPLIER_MASTER_REQUEST,
  FETCH_ALL_SERIES_REQUEST,
  FETCH_ALL_SERIES_SUCCESS,
  FETCH_ALL_SERIES_FAIL,
  FETCH_ALL_DEPARTMENT_REQUEST,
  FETCH_ALL_DEPARTMENT_SUCCESS,
  FETCH_ALL_DEPARTMENT_FAIL,
  FETCH_ALL_MACHINE_REQUEST,
  FETCH_ALL_MACHINE_SUCCESS,
  FETCH_ALL_MACHINE_FAIL,
} from "app/utils/constants/masterConstants";

const INIT_STATE = {
  loading: false,
  error: null,
  supplierMaster: [],
  supplierMasterBranches: [],
  itemCategoryMaster: [],
  itemSubCategoryMaster: [],
  gradeMaster: [],
  currencyMaster: [],
  cutMaster: [],
  gstMaster: [],
  expenseTypeMaster: [],
  partyMaster: [],
  unitMaster: [],
  palleteMaster: [],
  itemNameMaster: [],
  itemCodeMaster: [],
  supplierList: [],
  seriesList: [],
  departmentList: [],
  machineList: [],
  TotalPage: null,
};

export const masterReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_SUPPLIER_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_SUPPLIER_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        supplierMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case LOAD_SUPPLIER_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        supplierMaster: [],
        error: action.payload,
      };

    case LOAD_COSTING_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_COSTING_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        costingMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case LOAD_COSTING_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        costingMaster: [],
        error: action.payload,
      };

    case LOAD_PRODUCT_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_PRODUCT_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        productMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case LOAD_PRODUCT_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        productMaster: [],
        error: action.payload,
      };

    //suplier_branches
    case FETCH_ALL_SUPPLIER_MASTER_BRANCHES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_SUPPLIER_MASTER_BRANCHES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        supplierMasterBranches: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case FETCH_ALL_SUPPLIER_MASTER_BRANCHES__FAIL:
      return {
        ...state,
        supplierMasterBranches: [],
        error: action.payload,
      };

    // item category master
    case FETCH_ALL_ITEMS_CATEGORY_REQUEST:
      return { ...state, loading: true }
    case FETCH_ALL_ITEMS_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        itemCategoryMaster: action.payload.data,
        TotalPage: action.payload.totalPage
      }

    case FETCH_ALL_ITEMS_CATEGORY_FAIL:
      return { ...state, itemCategoryMaster: [], error: action.payload }

    // item subcategory master
    case FETCH_ALL_ITEMS_SUBCATEGORY_REQUEST:
      return { ...state, loading: true }
    case FETCH_ALL_ITEMS_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        itemSubCategoryMaster: action.payload.data,
        TotalPage: action.payload.totalPage
      }

    case FETCH_ALL_ITEMS_SUBCATEGORY_FAIL:
      return { ...state, itemSubCategoryMaster: [], error: action.payload }

    //grade master
    case ALL_GRADE_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_GRADE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        gradeMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_GRADE_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        gradeMaster: [],
        error: action.payload,
      };

    //currency master
    case ALL_CURRENCY_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_CURRENCY_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        currencyMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_CURRENCY_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        currencyMaster: [],
        error: action.payload,
      };

    //cut master
    case ALL_CUT_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_CUT_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        cutMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_CUT_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        cutMaster: [],
        error: action.payload,
      };

    //gst master
    case ALL_GST_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_GST_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        gstMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_GST_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        gstMaster: [],
        error: action.payload,
      };

    //expense type master
    case ALL_EXPENSE_TYPE_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_EXPENSE_TYPE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        expenseTypeMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_EXPENSE_TYPE_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        expenseTypeMaster: [],
        error: action.payload,
      };

    //party master
    case ALL_PARTY_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_PARTY_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        partyMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_PARTY_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        partyMaster: [],
        error: action.payload,
      };

    //unit master
    case ALL_UNIT_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_UNIT_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        unitMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_UNIT_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        unitMaster: [],
        error: action.payload,
      };

    //pallete master
    case ALL_PALLETE_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_PALLETE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        palleteMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_PALLETE_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        palleteMaster: [],
        error: action.payload,
      };

    //fabricColor master
    case ALL_FABRIC_COLOR_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_FABRIC_COLOR_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        fabricColorMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_FABRIC_COLOR_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        fabricColorMaster: [],
        error: action.payload,
      };

    //item name master
    case ALL_ITEM_NAME_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_ITEM_NAME_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        itemNameMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_ITEM_NAME_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        itemNameMaster: [],
        error: action.payload,
      };

    //item Type  master
    case ALL_ITEM_CODE_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_ITEM_CODE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        itemCodeMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_ITEM_CODE_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        itemCodeMaster: [],
        error: action.payload,
      };

    //fabric master
    case ALL_FABRIC_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_FABRIC_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        fabricMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_FABRIC_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        fabricMaster: [],
        error: action.payload,
      };

    //new supplier-master
    case FETCH_ALL_SUPPLIER_MASTER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_SUPPLIER_MASTER_LIST_SUCCESS:
      return { ...state, loading: false, supplierList: action.payload.data, totalPage: action.payload.totalPage };

    case FETCH_ALL_SUPPLIER_MASTER_FAIL:
      return { ...state, loading: false, supplierList: [], error: action.payload };

    //series - master
    case FETCH_ALL_SERIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_SERIES_SUCCESS:
      return { ...state, loading: false, seriesList: action.payload.data, totalPage: action.payload.totalPage };

    case FETCH_ALL_SERIES_FAIL:
      return { ...state, loading: false, seriesList: [], error: action.payload };

    //department - master
    case FETCH_ALL_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_DEPARTMENT_SUCCESS:
      return { ...state, loading: false, departmentList: action.payload.data, totalPage: action.payload.totalPage };

    case FETCH_ALL_DEPARTMENT_FAIL:
      return { ...state, loading: false, departmentList: [], error: action.payload };

    case FETCH_ALL_MACHINE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_MACHINE_SUCCESS:
      return { ...state, loading: false, machineList: action.payload.data, totalPage: action.payload.totalPage };

    case FETCH_ALL_MACHINE_FAIL:
      return { ...state, loading: false, machineList: [], error: action.payload };











    default:
      return state;


  }
};
