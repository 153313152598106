import { Button, TablePagination, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  DeleteOutlineOutlined,
  EditOutlined,
  MoreHoriz,
} from "@mui/icons-material";
import ContactPersonModal from "./ContactPersonmodal";

function ContactPersonCrad({
  personData,
  totalPages,
  page,
  rowsPerPage,
  handleChangePage,
  branchId,
  setToogle,
}) {
  const [menuState, setMenuState] = useState({
    anchorEl: null,
    currentEventId: null,
  });
  const [open, setOpen] = useState(false);
  console.log("branch id got => ", branchId);

  const handleMenuClick = (event, id) => {
    setMenuState({
      anchorEl: event.currentTarget,
      currentEventId: id,
    });
    // setEventId(id);
    console.log("Event ID set to: ", id);
  };
  const handleMenuClose = () => {
    setMenuState({
      anchorEl: null,
      currentEventId: null,
    });
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <div className="flex flex-col gap-3">
      <TableContainer component={Paper} sx={{ mb: 1 }}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: "#7352C7",
                color: "white",
                "& .MuiTableCell-root": {
                  // py: 2,
                },
              }}
            >
              <TableCell sx={{ color: "white" }}>Sr.No</TableCell>
              <TableCell sx={{ color: "white" }}>Name</TableCell>
              <TableCell sx={{ color: "white" }}>Email</TableCell>
              <TableCell sx={{ color: "white" }}>Mobile Number</TableCell>
              <TableCell sx={{ color: "white" }}>Designation</TableCell>
              <TableCell sx={{ color: "white" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {personData?.length > 0 ? (
              personData?.map((contact, index) => (
                <TableRow sx={{ py: 2 }} key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell name="contactPerson.name">
                    {contact.name}
                  </TableCell>
                  <TableCell name="email">{contact.email}</TableCell>
                  <TableCell name="mobile_number">
                    {contact.mobile_number}
                  </TableCell>
                  <TableCell name="designation">
                    {contact.designation}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={(e) => handleMenuClick(e, contact._id)}
                    >
                      <MoreHoriz />
                    </IconButton>
                  </TableCell>
                  <Menu
                    open={Boolean(menuState.anchorEl)}
                    anchorEl={menuState.anchorEl}
                    onClose={handleMenuClose}
                  >
                    <MenuItem
                      onClick={() => {
                        handleOpenModal();
                      }}
                    >
                      <ListItemIcon>
                        <EditOutlined />
                      </ListItemIcon>{" "}
                      <ListItemText>Edit</ListItemText>
                    </MenuItem>
                    {/* <MenuItem
                    // onClick={() => { handleDeleteContact(index, setFieldValue, values); handleMenuClose() }}
                    >
                      <ListItemIcon>
                        <DeleteOutlineOutlined />
                      </ListItemIcon>{" "}
                      <ListItemText>Delete</ListItemText>
                    </MenuItem> */}
                  </Menu>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={8}>
                  No Data Found...
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <ContactPersonModal
          id={menuState?.currentEventId}
          open={open}
          setToggle={setToogle}
          handleClose={handleCloseModal}
        />
        {/* {totalPages > 0 && (
                    <TablePagination
                        size='small'
                        component="div"
                        count={totalPages}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        // onRowsPerPageChange={handleRowsPerPageChange}
                        rowsPerPageOptions={""}
                    />
                )} */}
      </TableContainer>
    </div>
  );
}

export default ContactPersonCrad;
