import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Close } from "@mui/icons-material";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField, Typography } from "@mui/material";
import FormTextField1 from "../InputField/FormTextField1";
// import ToastAlerts from '../Toast';
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  designation: Yup.string().required("Designation is required"),
  mobile_number: Yup.string()
    .matches(/^[0-9]{10}$/, "Must be exactly 10 digits")
    .required("Mobile number is required"),
});

function QuestionsModal({ open, handleClose, onAddContact, values }) {
  const [contact, setContact] = useState({
    name: "",
    email: "",
    designation: "",
    mobile_number: "",
  });

  useEffect(() => {
    if (values && values.length > 0) {
      // Access the first element in the array
      const contactPerson = values[0];
      setContact({
        name: contactPerson?.name || "",
        email: contactPerson?.email || "",
        designation: contactPerson?.designation || "",
        mobile_number: contactPerson?.mobile_number || "",
      });
    } else {
      setContact({ name: "", email: "", designation: "", mobile_number: "" });
    }
  }, [values]);
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setContact((prev) => ({ ...prev, [name]: value }));
  // };

  const handleSubmit = (formValues) => {
    onAddContact(formValues);
    setContact({ name: "", email: "", designation: "", mobile_number: "" });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="lg:w-1/3 w-[20rem]">
          <div className="flex flex-row items-center justify-between">
            <Typography variant="h2" component="h2">
              Add Contact Person
            </Typography>
            <Close
              onClick={handleClose}
              className="mb-4"
              style={{ cursor: "pointer", float: "right" }}
            />
          </div>
          <Formik
            initialValues={contact}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="grid grid-flow-row place-items-center grid-cols-2 gap-5">
                {/* <TextField
                  size="small"
                  fullWidth
                  margin="normal"
                  label="Name"
                  name="name"
                  value={contact.name}
                  onChange={handleChange}
                /> */}

                {/* <TextField
                  size="small"
                  fullWidth
                  margin="normal"
                  label="Email"
                  name="email"
                  value={contact.email}
                  onChange={handleChange}
                />
                <TextField
                  size="small"
                  fullWidth
                  margin="normal"
                  label="Designation"
                  name="designation"
                  value={contact.designation}
                  onChange={handleChange}
                />
                <TextField
                  size="small"
                  fullWidth
                  margin="normal"
                  label="Mobile Number"
                  name="mobile_number"
                  value={contact.mobile_number}
                  onChange={handleChange}
                /> */}

                <FormTextField1 name="name" label="Name*" />
                <FormTextField1 name="email" label="Email*" />
                <FormTextField1 name="designation" label="Designation*" />
                <FormTextField1 name="mobile_number" label="Mobile Number*" />
              </div>
              <div className="flex items-center justify-center mt-5">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  // onClick={handleSubmit}
                >
                  {values.length > 0 ? "Update" : "Add"}
                </Button>
              </div>
            </Form>
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default QuestionsModal;
