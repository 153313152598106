import Page from "@jumbo/shared/Page";
import EditStock from "app/pages/Inventory/EditStock";
import LogInventoryList from "app/pages/Inventory/Log Inventory/ListLog";
import LogInventoryRouteMiddleware from "./middleware/auth/inventoryValidRoute";
import AddLogStock from "app/pages/Inventory/Log Inventory/AddLog";

export const logInventoryRoute = [
  {
    middleware: [
      {
        element: LogInventoryRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/inventory/log",
        element: <Page component={LogInventoryList} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/log/add",
        element: <Page component={AddLogStock} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/log/edit",
        element: <Page component={EditStock} layout={"vertical-default"} />,
      },
    ],
  },
];
