import Page from "@jumbo/shared/Page";
import FactoryCuttingList from "app/pages/Factory/Cutting/List";
import CuttingMiddleWare from "./middleware/auth/cuttingValidRoute";
import CreateCutting from "app/pages/Factory/Cutting/Add";
import FactorySmokingList from "app/pages/Factory/Smoking/List";
import SmokingMiddleWare from "./middleware/auth/smokingValidRoute";
import CreateSmoking from "app/pages/Factory/Smoking/Create";
import CreateGroupSmoking from "app/pages/Factory/Smoking/Create/createGroupSmoking";
import DyingMiddleWare from "./middleware/auth/dyingValidRoute";
import CreateDying from "app/pages/Factory/Dying/Create";
import CreateGroupDying from "app/pages/Factory/Dying/Create/createGroupDying";
import FactoryDyingList from "app/pages/Factory/Dying/List";
import TappingMiddleWare from "./middleware/auth/tappingValidRoute";
import FactoryTappingList from "app/pages/Factory/Tapping/List";
import CreateTapping from "app/pages/Factory/Tapping/Create";
import PressingMiddleWare from "./middleware/auth/pressingValidRoute";
import FactoryPressingList from "app/pages/Factory/Pressing/List";
import FinishingMiddleWare from "./middleware/auth/finishingValidRoute";
import FactoryFinishingList from "app/pages/Factory/Finishing/List";
import CreateFinishing from "app/pages/Factory/Finishing/Create";

export const factoryRoutes = [
  {
    middleware: [
      {
        element: CuttingMiddleWare,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "factory/cutting",
        element: (
          <Page component={FactoryCuttingList} layout={"vertical-default"} />
        ),
      },
      {
        path: "factory/add-cutting",
        element: <Page component={CreateCutting} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: SmokingMiddleWare,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "factory/smoking",
        element: (
          <Page component={FactorySmokingList} layout={"vertical-default"} />
        ),
      },
      {
        path: "factory/smoking/create-smoking-individual",
        element: <Page component={CreateSmoking} layout={"vertical-default"} />,
      },
      {
        path: "factory/smoking/create-smoking-group",
        element: (
          <Page component={CreateGroupSmoking} layout={"vertical-default"} />
        ),
      },
    ],
  },
  {
    middleware: [
      {
        element: DyingMiddleWare,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "factory/dying",
        element: (
          <Page component={FactoryDyingList} layout={"vertical-default"} />
        ),
      },
      {
        path: "factory/dying/create-dying-individual",
        element: <Page component={CreateDying} layout={"vertical-default"} />,
      },
      {
        path: "factory/dying/create-dying-group",
        element: (
          <Page component={CreateGroupDying} layout={"vertical-default"} />
        ),
      },
    ],
  },
  {
    middleware: [
      {
        element: TappingMiddleWare,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "factory/tapping",
        element: (
          <Page component={FactoryTappingList} layout={"vertical-default"} />
        ),
      },
      {
        path: "factory/add-tapping",
        element: <Page component={CreateTapping} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: PressingMiddleWare,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "factory/pressing",
        element: (
          <Page component={FactoryPressingList} layout={"vertical-default"} />
        ),
      },
    ],
  },
  {
    middleware: [
      {
        element: FinishingMiddleWare,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "factory/finishing",
        element: (
          <Page component={FactoryFinishingList} layout={"vertical-default"} />
        ),
      },
      {
        path: "factory/add-finishing",
        element: <Page component={CreateFinishing} layout={"vertical-default"} />,
      },
    ],
  },
];
