import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import FormTextField1 from "app/components/InputField/FormTextField1";
import { addSupplier } from "app/services/apis/addSupplier";
import { codeList, outerDiv1 } from "app/utils/constants/dropdowns";
import { supplierTypes } from "app/utils/constants/supplierTypes";
import { City, Country, State } from "country-state-city";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import { addSupplierBranch } from "app/services/apis/addSupplierBranch";
import QuestionsModal from "app/components/Modal/Modal";
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import MoreHoriz from "@mui/icons-material/MoreHoriz";

export default function AddSupplier() {
  const [countryList, setCountryList] = useState(Country.getAllCountries());
  const [StateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [contacts, setContacts] = useState([]);
  const navigate = useNavigate();
  const [editValues, setEditValues] = useState({});
  // const [supplierSchema, setSupplierSchema] = useState({
  //   supplier_name: "",
  //   supplier_type: ""
  // })
  const { pathname } = useLocation();
  const { state } = useLocation();
  const [isSubmitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedContactIndex, setselectedContactIndex] = useState(null);
  const supplier = {
    supplier_name: "",
    supplier_type: [],
    branch_name: "",
    gst_number: "",
    address: "",
    state: "",
    country: "",
    city: "",
    pincode: "",
    web_url: "",
    contactPersons: [],
  };

  const onUserSave = async (values) => {
    const body = { ...values };

    if (body?.contactPersons?.length === 0) {
      return Swal.fire({
        icon: "warning",
        title: "At least one contact person is required",
        text: "",
        timer: 1000,
        showConfirmButton: false,
      });
    }

    const supplierData = {
      supplier_name: body.supplier_name,
      // supplier_type: body.supplier_type,
      supplier_type: Array.isArray(body.supplier_type)
        ? body.supplier_type
        : [body.supplier_type],
    };
    const branchData = {
      branch_name: body.branch_name,
      address: body.address,
      state: body.state,
      country: body.country,
      city: body.city,
      pincode: body.pincode,
      gst_number: body.gst_number,
      web_url: body.web_url,
      is_main_branch: true,
      contact_person: body.contactPersons,
    };

    setSubmitting(true);
    try {
      const data = await addSupplier(supplierData);

      if (data?.data?.statusCode === 200) {
        const id = data?.data?.result?._id;
        const branchResponse = await addSupplierBranch(branchData, id);

        if (branchResponse?.data?.statusCode === 200) {
          Swal.fire({
            icon: "success",
            title: "Supplier Added Successfully",
            text: "",
            timer: 1000,
            showConfirmButton: false,
          });
          navigate("/master/supplier");
        } else {
          Swal.fire({
            icon: "error",
            title:
              branchResponse?.data?.message || "Failed to add supplier branch",
            text: "",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    } catch (error) {
      setSubmitting(false);
      console.error("Error saving supplier data => ", error.message);
      Swal.fire({
        icon: "error",
        title: "",
        text:
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred",
      });
    }
  };
  const validationSchema = yup.object({
    supplier_name: yup.string().required("Supplier Name is required"),
    // supplier_type: yup.string().required("Supplier Type is required"),
    supplier_type: yup
      .array()
      .of(yup.string().required("Supplier Type is required"))
      .min(1, "At least one Supplier Type is required"),
    branch_name: yup.string().required("Branch Name is required"),
    address: yup.string().required("Address is required"),
    state: yup.string().required("State is required"),
    country: yup
      .string("Enter Country")
      .required("Country is required")
      .test(
        "supplier_country-not-select",
        "Please select a valid Country",
        (value) => value !== "Select"
      ),
    city: yup.string().required("City is required"),
    pincode: yup
      .string("Enter Pincode")
      .matches(
        /^\d{6}$/,
        "Invalid PIN code. Please enter a valid 6-digit PIN code."
      )
      .required("Pincode is required"),
    // gst_number: yup
    //   .string()
    //   // .matches(
    //   //   /^[0-9]{15}$/,
    //   //   "Invalid GST Number. Please enter a valid 15-digit GST number."
    //   // )
    //   .length(15)
    //   .required("GST Number is required"),
    gst_number: yup
      .string()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "Invalid GST Number"
      )
      .length(15)
      .required("GST Number is required"),
    web_url: yup
      .string()
      // .url("Enter a valid URL")
      .required("Web URL is required"),
    contactPersons: yup.array().of(
      yup.object().shape({
        name: yup.string().required("Contact Person Name is required"),
        email: yup
          .string()
          .email("Enter a valid Email")
          .required("Email is required"),
        mobile_number: yup
          .string()
          .matches(/^\d+$/, "Phone must be a number")
          .required("Phone is required"),
        designation: yup.string().required("Designation is required"),
      })
    ),
  });

  const filterStates = (countryIsoCode) => {
    // Use your logic to filter states based on the selected country.
    const filteredStates = State.getAllStates().filter(
      (state) => state.countryCode === countryIsoCode
    );
    setStateList(filteredStates);
  };

  const filterCities = (stateIsoCode, countryCode) => {
    // Use your logic to filter cities based on the selected state.
    const filteredCities = City.getAllCities().filter(
      (city) =>
        city.stateCode === stateIsoCode && city.countryCode === countryCode
    );
    setCityList(filteredCities);
  };

  useEffect(() => {
    if (state) {
      const country = Country.getAllCountries().filter(
        (country) => country.name === state.country
      );
      const stateList = State.getAllStates().filter(
        (country) => country.name === state.state
      );
      filterStates(country[0]?.isoCode);
      filterCities(stateList[0]?.isoCode, country[0]?.isoCode);
    }
  }, []);

  const handleOpenModal = (index) => {
    setselectedContactIndex(index);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleAddContact = (contact, setFieldValue, values) => {
    // setContacts(prev => [...prev, contact]);

    setFieldValue("contactPersons", [...values.contactPersons, contact]);
  };

  const [menuState, setMenuState] = useState({
    anchorEl: null,
    currentEventId: null,
  });

  const handleMenuClick = (event, id) => {
    setMenuState({
      anchorEl: event.currentTarget,
      currentEventId: id,
    });
    // setEventId(id);
    console.log("Event ID set to: ", id);
  };
  const handleMenuClose = () => {
    setMenuState({
      anchorEl: null,
      currentEventId: null,
    });
  };

  const handleDeleteContact = (index, setFieldValue, values) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedContacts = values.contactPersons.filter(
          (_, i) => i !== index
        );
        setFieldValue("contactPersons", updatedContacts);

        Swal.fire(
          "Deleted!",
          "The contact person has been deleted.",
          "success"
        );
      }
    });
  };

  const handleAddOrUpdateContact = (contact, setFieldValue, values) => {
    let updatedContacts = [...values.contactPersons];

    if (selectedContactIndex !== null) {
      // Update existing contact
      updatedContacts[selectedContactIndex] = contact;
    } else {
      // Add new contact
      updatedContacts = [...updatedContacts, contact];
    }

    // Update the field using setFieldValue
    setFieldValue("contactPersons", updatedContacts);
    handleCloseModal();
  };

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname === "/master/supplier/add"
          ? "Add New Supplier"
          : "Edit Supplier"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={supplier}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
          // onSubmit={(values) => console.log("values => ", values)}
        >
          {({ values, setFieldValue, errors }) => (
            <>
              <Form noValidate autoComplete="off">
                <Div sx={{ mt: 4 }}>
                  <Grid container rowSpacing={4} columnSpacing={4}>
                    <Grid item xs={6}>
                      <FormTextField1
                        name="supplier_name"
                        label="Supplier Name*"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h5">Supplier Type*</Typography>
                      <Autocomplete
                        multiple={
                          values?.supplier_type?.includes("STORE")
                            ? false
                            : true
                        }
                        options={supplierTypes?.map((type) => type.type)}
                        // value={values.supplier_type || []}
                        value={
                          Array.isArray(values.supplier_type)
                            ? values.supplier_type
                            : []
                        }
                        onChange={(e, newValue) => {
                          if (newValue === null) {
                            setFieldValue("supplier_type", []);
                          } else if (newValue.includes("STORE")) {
                            setFieldValue("supplier_type", ["STORE"]);
                          } else {
                            setFieldValue("supplier_type", newValue);
                          }
                        }}
                        getOptionDisabled={(option) => {
                          return (
                            values.supplier_type.includes("STORE") &&
                            option !== "STORE"
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // error={!!errors.supplier_type}
                            // helperText={errors.supplier_type}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />

                      <Div style={{ height: "30px" }}>
                        <ErrorMessage
                          name="supplier_type"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Grid>

                    <Grid item xs={12}>
                      <div className="flex flex-col gap-5 -mt-10 -mb-5">
                        <Button
                          className="self-end"
                          variant="contained"
                          size="small"
                          onClick={() => handleOpenModal(null)}
                        >
                          Add Contact Person
                        </Button>
                        <TableContainer component={Paper} sx={{ mb: -5 }}>
                          <Table size="small">
                            <TableHead>
                              <TableRow
                                sx={{
                                  bgcolor: "#7352C7",
                                  color: "white",
                                  "& .MuiTableCell-root": {
                                    // py: 2,
                                  },
                                }}
                              >
                                <TableCell sx={{ color: "white" }}>
                                  Sr.No
                                </TableCell>
                                <TableCell sx={{ color: "white" }}>
                                  Name
                                </TableCell>
                                <TableCell sx={{ color: "white" }}>
                                  Email
                                </TableCell>
                                <TableCell sx={{ color: "white" }}>
                                  Mobile Number
                                </TableCell>
                                <TableCell sx={{ color: "white" }}>
                                  Designation
                                </TableCell>
                                <TableCell sx={{ color: "white" }}>
                                  Action
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.contactPersons.length > 0 ? (
                                values.contactPersons.map((contact, index) => (
                                  <TableRow sx={{ py: 2 }} key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell name="contactPerson.name">
                                      {contact.name}
                                    </TableCell>
                                    <TableCell name="email">
                                      {contact.email}
                                    </TableCell>
                                    <TableCell name="mobile_number">
                                      {contact.mobile_number}
                                    </TableCell>
                                    <TableCell name="designation">
                                      {contact.designation}
                                    </TableCell>

                                    <TableCell>
                                      <IconButton
                                        onClick={(e) =>
                                          handleMenuClick(e, index)
                                        }
                                      >
                                        <MoreHoriz />
                                      </IconButton>
                                    </TableCell>
                                    <Menu
                                      open={Boolean(menuState.anchorEl)}
                                      anchorEl={menuState.anchorEl}
                                      onClose={handleMenuClose}
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          // setEditValues(values.contactPersons);
                                          handleOpenModal(index);
                                        }}
                                      >
                                        <ListItemIcon>
                                          <EditOutlined />
                                        </ListItemIcon>{" "}
                                        <ListItemText>Edit</ListItemText>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          handleDeleteContact(
                                            index,
                                            setFieldValue,
                                            values
                                          );
                                          handleMenuClose();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <DeleteOutlineOutlined />
                                        </ListItemIcon>{" "}
                                        <ListItemText>Delete</ListItemText>
                                      </MenuItem>
                                    </Menu>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell align="center" colSpan={6}>
                                    No Data Found...
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Div style={{ height: "30px" }}>
                          {values.contactPersons.length === 0 && (
                            <ErrorMessage
                              name="contactPersons"
                              component="div"
                              style={{ color: "red" }}
                            />
                          )}
                        </Div>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <FormTextField1 name="branch_name" label="Branch Name*" />
                    </Grid>
                    <Grid item xs={3}>
                      <FormTextField1 name="address" label="Address*" />
                    </Grid>

                    <Grid item xs={3}>
                      <Div sx={outerDiv1}>
                        <Typography variant="h5">Country*</Typography>

                        <Select
                          fullWidth
                          value={values?.country}
                          size="small"
                          onChange={(e, key) => {
                            setFieldValue("country", e?.target?.value);
                            setFieldValue("state", "");
                            setFieldValue("city", "");
                            setFieldValue(
                              "countryCode",
                              key.key.replace(/[.$]/g, "")
                            );
                            filterStates(key.key.replace(/[.$]/g, ""));
                          }}
                        >
                          {countryList?.map((country) => {
                            <MenuItem value={"Select"}>Select</MenuItem>;
                            return (
                              <MenuItem
                                value={country?.name}
                                key={country?.isoCode}
                              >
                                {country?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>

                        <Div style={{ height: "30px" }}>
                          <ErrorMessage
                            name="country"
                            component="div"
                            style={{ color: "red" }}
                          />
                        </Div>
                      </Div>
                    </Grid>
                    <Grid item xs={3}>
                      <Div sx={outerDiv1}>
                        <Typography variant="h5">State*</Typography>
                        <Select
                          fullWidth
                          value={values?.state}
                          size="small"
                          onChange={(e, key) => {
                            setFieldValue("state", e?.target?.value);
                            setFieldValue("city", "");
                            filterCities(
                              key.key.replace(/[.$]/g, ""),
                              values.countryCode
                            );
                          }}
                        >
                          {StateList?.map((state) => {
                            // console.log(state);
                            return (
                              <MenuItem
                                value={state?.name}
                                key={state?.isoCode}
                              >
                                {state?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <ErrorMessage
                          name="state"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Grid>

                    {/* <Grid item xs={3}>
                      <FormTextField1 name="city" label="City" />
                    </Grid> */}
                    <Grid item xs={6} md={3}>
                      <Div sx={outerDiv1}>
                        <Typography variant="h5">City</Typography>
                        <Select
                          fullWidth
                          value={values?.city}
                          size="small"
                          onChange={(e) => {
                            setFieldValue("city", e.target.value);
                          }}
                        >
                          {cityList?.map((city) => {
                            return (
                              <MenuItem key={city.name} value={city.name}>
                                {city.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <ErrorMessage
                          name="city"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Grid>
                    <Grid item xs={3}>
                      <FormTextField1 name="pincode" label="Pincode*" />
                    </Grid>
                    <Grid item xs={3}>
                      <FormTextField1 name="gst_number" label={`GST Number*`} />
                    </Grid>
                    <Grid item xs={3}>
                      <FormTextField1 name="web_url" label="Web Url*" />
                    </Grid>
                  </Grid>

                  <Div
                    sx={{
                      width: "93.5%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 3,
                      mt: 3,
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure you want to cancel?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: "Yes",
                          cancelButtonText: "No",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            navigate("/master/supplier");
                          }
                        });
                      }}
                    >
                      Cancel
                    </Button>

                    <LoadingButton
                      variant="contained"
                      type="submit"
                      sx={{ width: "100px" }}
                      loading={isSubmitting}
                    >
                      Save
                    </LoadingButton>
                  </Div>
                </Div>
              </Form>
              <QuestionsModal
                handleClose={handleCloseModal}
                open={open}
                onAddContact={(contact) =>
                  handleAddOrUpdateContact(contact, setFieldValue, values)
                }
                values={
                  selectedContactIndex !== null
                    ? [values.contactPersons[selectedContactIndex]]
                    : []
                }
                // values={editValues}
              />
            </>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
