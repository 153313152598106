import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AllApis from "app/Apis";
import { getAllRawMaterial } from "app/redux/actions/inventoryAction";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

export const RejectModal = ({ open, setOpen, rowData }) => {
  const [data, setData] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [quantity, setQuantity] = useState({
    // item_rejected_quantities: {
    //   natural: 0,
    //   dyed: 0,
    //   smoked: 0,
    //   total: 0,
    // },
    item_rejected_pattas: 0,
  });
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };

  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    let rejectedQty = {
      // item_rejected_quantities: {
      //   ...quantity.item_rejected_quantities,
      //   // [name]: Number(value),
      // },
      item_rejected_pattas: quantity?.item_rejected_pattas,
    };
    // const { natural, dyed, smoked } = rejectedQty.item_rejected_quantities;
    // const totalReceived =
    //   (parseFloat(natural) || 0) +
    //   (parseFloat(dyed) || 0) +
    //   (parseFloat(smoked) || 0);
    // rejectedQty.item_rejected_quantities.total = totalReceived;
    // rejectedQty.item_rejected_pattas = totalReceived;
    rejectedQty.item_rejected_pattas = parseFloat(value);
    setQuantity(rejectedQty);
  };

  const handleReject = async () => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    try {
      setSubmitting(true);
      const response = await Axios.patch(
        AllApis.raw_material.rejectQty(data?._id),
        quantity,
        config
      );
      setOpen(false);
      dispatch(
        getAllRawMaterial("", "desc", "updated_at", 1, {
          status: "available",
        })
      );
      if (response?.data?.status) {
        Swal.fire({
          title: "Qty Rejected Successful",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setData(rowData);
    setQuantity({
      item_rejected_quantities: {
        natural: 0,
        dyed: 0,
        smoked: 0,
        total: 0,
      },
      item_rejected_pattas: 0,
    });
  }, [open]);
console.log(data);
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
      <DialogContent>
        <Div>
          <Typography variant="h4" fontWeight={600}>
            Add Rejected Quantity
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                py: 2,
              },
            }}>
                {/* {rowData?.item_code?.toLowerCase() == "natural" && (
                  <TableCell sx={{ color: "white" }}>
                    Available Natural
                  </TableCell>
                )}
                {rowData?.item_code?.toLowerCase() == "dyed" && (
                  <TableCell sx={{ color: "white" }}>Available Dyed</TableCell>
                )}
                {rowData?.item_code?.toLowerCase() == "smoked" && (
                  <TableCell sx={{ color: "white" }}>
                    Available Smoked
                  </TableCell>
                )} */}
                
                  <TableCell sx={{ color: "white" }}>
                    Available Pattas
                  </TableCell>
              
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ bgcolor: "#EDEBEB" }}>
                {/* {rowData?.item_code?.toLowerCase() == "natural" && (
                  <TableCell>
                    {" "}
                    <TextField
                      sx={disabledStyling}
                      disabled
                      size="small"
                      value={data?.item_available_quantities?.natural}
                    />
                  </TableCell>
                )}
                {rowData?.item_code?.toLowerCase() == "dyed" && (
                  <TableCell>
                    {" "}
                    <TextField
                      sx={disabledStyling}
                      disabled
                      size="small"
                      value={data?.item_available_quantities?.dyed}
                    />
                  </TableCell>
                )}
                {rowData?.item_code?.toLowerCase() == "smoked" && (
                  <TableCell>
                    {" "}
                    <TextField
                      sx={disabledStyling}
                      disabled
                      size="small"
                      value={data?.item_available_quantities?.smoked}
                    />
                  </TableCell>
                )} */}
                
                  <TableCell>
                    {" "}
                    <TextField
                      sx={disabledStyling}
                      disabled
                      size="small"
                      value={data?.item_available_pattas}
                    />
                  </TableCell>
               
              </TableRow>
            </TableBody>
          </Table>
          <Table size="small">
            <TableHead>
              <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                py: 2,
              },
            }}>
                {/* {rowData?.item_code?.toLowerCase() == "natural" && (
                  <TableCell sx={{ color: "white" }}>
                    Rejected Natural
                  </TableCell>
                )}

                {rowData?.item_code?.toLowerCase() == "dyed" && (
                  <TableCell sx={{ color: "white" }}>Rejected Dyed</TableCell>
                )}

                {rowData?.item_code?.toLowerCase() == "smoked" && (
                  <TableCell sx={{ color: "white" }}>Rejected Smoked</TableCell>
                )} */}
                
                  <TableCell sx={{ color: "white" }}>Rejected Pattas</TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ bgcolor: "#EDEBEB" }}>
                {/* {rowData?.item_code?.toLowerCase() == "natural" && (
                  <TableCell>
                    {" "}
                    <TextField
                      size="small"
                      name="natural"
                      type="number"
                      sx={{ width: "33%" }}
                      inputProps={{
                        min: 0,
                        max: data?.item_available_quantities?.natural,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseInt(e.target.value + e.key, 10);

                        if (
                          isNaN(inputValue) ||
                          inputValue < 0 ||
                          inputValue > data?.item_available_quantities?.natural
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleOnChange(e)}
                      defaultValue={quantity?.item_rejected_quantities?.natural}
                    />
                  </TableCell>
                )}
                {rowData?.item_code?.toLowerCase() == "dyed" && (
                  <TableCell>
                    {" "}
                    <TextField
                      size="small"
                      name="dyed"
                      type="number"
                      sx={{ width: "33%" }}
                      inputProps={{
                        min: 0,
                        max: data?.item_available_quantities?.dyed,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseInt(e.target.value + e.key, 10);

                        if (
                          isNaN(inputValue) ||
                          inputValue < 0 ||
                          inputValue > data?.item_available_quantities?.dyed
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleOnChange(e)}
                      defaultValue={quantity?.item_rejected_quantities?.dyed}
                    />
                  </TableCell>
                )}

                {rowData?.item_code?.toLowerCase() == "smoked" && (
                  <TableCell>
                    {" "}
                    <TextField
                      size="small"
                      name="smoked"
                      type="number"
                      sx={{ width: "33%" }}
                      inputProps={{
                        min: 0,
                        max: data?.item_available_quantities?.smoked,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseInt(e.target.value + e.key, 10);
                        if (
                          isNaN(inputValue) ||
                          inputValue < 0 ||
                          inputValue > data?.item_available_quantities?.smoked
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleOnChange(e)}
                      defaultValue={quantity?.item_rejected_quantities?.smoked}
                    />
                  </TableCell>
                )} */}
                
                  <TableCell>
                    <TextField
                      size="small"
                      name="item_available_pattas"
                      type="number"
                      sx={{ width: "100%" }}
                      inputProps={{
                        min: 0,
                        max: data?.item_available_pattas,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseInt(e.target.value + e.key, 10);
                        if (
                          isNaN(inputValue) ||
                          inputValue < 0 ||
                          inputValue > data?.item_available_pattas
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleOnChange(e)}
                      defaultValue={quantity?.item_rejected_pattas}
                    />
                  </TableCell>
                
              </TableRow>
            </TableBody>
          </Table>
          <Div sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <LoadingButton
              loading={isSubmitting}
              disabled={quantity?.item_rejected_pattas <= 0}
              variant="contained"
              type="submit"
              sx={{ mx: "auto", textAlign: "center" }}
              onClick={() => handleReject()}
            >
              Reject
            </LoadingButton>
          </Div>
        </Div>
      </DialogContent>
    </Dialog>
  );
};
