/* eslint-disable default-case */
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Div from "@jumbo/shared/Div";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import AllApis from "app/Apis";
import { formatDate } from "app/components/Function/formatDate";
import { getIssueForFinishingList } from "app/redux/actions/factoryAction";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { CuttingDetailsModal } from "../Modal/cuttingDetailsModal";
import { ReadySheetDetailsModal } from "../Modal/readySheetFormDetailsModal";
import FullScreenLoader from "app/components/ListingPageLoader";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";

export default function IssuedForFinishingTable({
  page,
  setPage,
  searchTerm,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
  addGroup,
  setAddGroup,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { issueForFinishing, error, TotalPage, loading } = useSelector(
    (state) => state.factoryReducer
  );
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [readySheetOpen, setReadySheetOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      getIssueForFinishingList(searchTerm, sort, sortBy, newPage, filter)
    );
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "addToFinishing":
        navigate("factory/add-finishing", { state: menuItem.data });
        break;
      case "revert":
        revertIssueFinishing(menuItem?.data?._id);
        break;
    }
  };

  const revertIssueFinishing = async (id) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Revert",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          const response = await Axios.post(
            AllApis.finishing.revertIssueFinishing(id),
            {},
            config
          );
          if (response) {
            dispatch(getIssueForFinishingList(searchTerm, sort, sortBy, page, filter));
            Swal.fire({
              title: "Reverted",
              icon: "success",
              timer: 1000,
              showConfirmButton: false,
            });
          }
        } catch (error) {
          Swal.fire({
            title: error?.response?.data?.error,
            icon: "error",
            timer: 1000,
            showConfirmButton: false,
          });
        } finally {
          setIsLoading(false);
        }
      }
    });
  };
  const handleUpdateStatus = (value, id) => {
    const msg = {
      "sent for open grain": " Send For Open Grain",
      metallic: "Receive From Metallic",
      rejected: "Reject",
      "open grain": "Receive From Open Grain",
      "sent for metallic": "Send For Metallic",
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: msg?.[value],
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await Axios.patch(
            AllApis.finishing.updateStatus,
            {
              issued_for_finishing_id: id,
              status: value,
            },
            config
          );
          if (response) {
            dispatch(getIssueForFinishingList(searchTerm, sort, sortBy, page, filter));
            Swal.fire({
              title: "Status Updated",
              icon: "success",
              timer: 1000,
              showConfirmButton: false,
            });
          }
        } catch (error) {
          Swal.fire({
            title: error.message,
            icon: "error",
            timer: 1000,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                py: 2,
              },
            }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_no"}
                  direction={sort}
                  onClick={() => handleSort("group_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "300px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "cutting_details.cutting_id.item_details.item_data.item_name"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "cutting_details.cutting_id.item_details.item_data.item_name"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "cutting_details.cutting_id.item_details.item_data.item_code"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "cutting_details.cutting_id.item_details.item_data.item_code"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "pressing_details.pressing_length"}
                  direction={sort}
                  onClick={() => handleSort("pressing_details.pressing_length")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Pressing Length (cm)
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "pressing_details.pressing_width"}
                  direction={sort}
                  onClick={() => handleSort("pressing_details.pressing_width")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Pressing Width (cm)
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "140px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "pressing_details.pressing_no_of_peices"}
                  direction={sort}
                  onClick={() =>
                    handleSort("pressing_details.pressing_no_of_peices")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Original Pressing Pcs
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "pressing_details.pressing_sqm"}
                  direction={sort}
                  onClick={() => handleSort("pressing_details.pressing_sqm")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Pressing Sq.m.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "available_pressed_pcs"}
                  direction={sort}
                  onClick={() => handleSort("available_pressed_pcs")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Avl Pressing Pcs
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "status"}
                  direction={sort}
                  onClick={() => handleSort("status")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Ready Sheet Form Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Cutting Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Issued Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "created_employee_id.first_name"}
                  direction={sort}
                  onClick={() => handleSort("created_employee_id.first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>
              {permissions?.finishing_edit == true && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "200px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                    position: "sticky",
                    right: "95px",
                    height: "58px",
                    zIndex: 1,
                    bgcolor: "#7352C7",
                  }}
                >
                  Update Status
                </TableCell>
              )}
              {permissions?.finishing_create == true && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "95px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                    position: "sticky",
                    right: 0,
                    height: "58px",
                    zIndex: 1,
                    bgcolor: "#7352C7",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {issueForFinishing
              ?.filter((ele) => ele?.revert_status != "inactive")
              ?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.group_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {
                      row?.cutting_details?.cutting_id?.[0]?.item_details
                        ?.item_data?.[0]?.item_name
                    }
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {
                      row?.cutting_details?.cutting_id?.[0]?.item_details
                        ?.item_data?.[0]?.item_code
                    }
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.pressing_details?.pressing_length}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.pressing_details?.pressing_width}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.pressing_details?.pressing_no_of_peices}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.pressing_details?.pressing_sqm?.toFixed(2)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.available_pressed_pcs}
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      textTransform: "capitalize",
                    }}
                  >
                    {row?.status}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    <Div
                      onClick={() => {
                        setReadySheetOpen(true);
                        setRowData(row);
                      }}
                    >
                      <InfoIcon sx={{ cursor: "pointer" }} />
                    </Div>
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    <Div
                      onClick={() => {
                        setOpen(true);
                        setRowData(row?.cutting_details?.cutting_id);
                      }}
                    >
                      <InfoIcon sx={{ cursor: "pointer" }} />
                    </Div>
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {formatDate(row?.created_at)}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      textTransform: "capitalize",
                    }}
                  >
                    {row?.created_employee_id?.first_name}{" "}
                    {row?.created_employee_id?.last_name}
                  </TableCell>
                  {permissions?.finishing_edit == true && (
                    <TableCell
                      sx={{
                        textAlign: "left",
                        px: 1,
                        position: "sticky",
                        right: "95px",
                        zIndex: 1,
                        bgcolor: "white",
                      }}
                    >
                      {row?.status != "rejected" ? (
                        <Select
                          disabled={
                            row?.available_pressed_pcs !==
                            row?.pressing_details?.pressing_no_of_peices
                          }
                          sx={{ width: "100%" }}
                          size="small"
                          value={"Select"}
                          onChange={(e) => {
                            if (e.target.value !== "Select") {
                              handleUpdateStatus(e.target.value, row?._id);
                            }
                          }}
                        >
                          <MenuItem value={"Select"}>Select</MenuItem>
                          {(row?.status === "pending" ||
                            row?.status === "open grain" ||
                            row?.status === "metallic") && [
                            <MenuItem
                              key="sent-for-open-grain"
                              value="sent for open grain"
                            >
                              Send For Open Grain
                            </MenuItem>,
                            <MenuItem key="metallic" value="sent for metallic">
                              Send For Metallic
                            </MenuItem>,
                            <MenuItem key="rejected" value="rejected">
                              Reject
                            </MenuItem>,
                          ]}
                          {row?.status === "sent for open grain" && [
                            <MenuItem
                              key="receive-from-open-grain"
                              value="open grain"
                            >
                              Receive From Open Grain
                            </MenuItem>,
                            <MenuItem key="rejected" value="rejected">
                              Reject
                            </MenuItem>,
                          ]}
                          {row?.status === "sent for metallic" && [
                            <MenuItem
                              key="receive-from-metallic"
                              value="metallic"
                            >
                              Receive From Metallic
                            </MenuItem>,
                            <MenuItem key="rejected" value="rejected">
                              Reject
                            </MenuItem>,
                          ]}
                        </Select>
                      ) : (
                        <Typography>N/A</Typography>
                      )}
                    </TableCell>
                  )}

                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      position: "sticky",
                      right: 0,
                      zIndex: 1,
                      bgcolor: "white",
                    }}
                  >
                    {row?.status !== "rejected" ? (
                      <JumboDdMenu
                        icon={<MoreHorizIcon />}
                        menuItems={[
                          {
                            icon: <AddCircleIcon />,
                            title: "Add To Finishing",
                            action: "addToFinishing",
                            data: row,
                            show:
                              permissions?.finishing_create &&
                              ["metallic", "pending", "open grain"].includes(
                                row?.status
                              ),
                          },
                          {
                            icon: <SettingsBackupRestoreIcon />,
                            title: "Revert",
                            action: "revert",
                            data: row,
                            show: permissions?.finishing_edit,
                          },
                        ].filter((ele) => ele?.show)}
                        onClickCallback={handleItemAction}
                      />
                    ) : (
                      <Typography>N/A</Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          {open && (
            <CuttingDetailsModal
              open={open}
              setOpen={setOpen}
              rowData={rowData}
            />
          )}
          {readySheetOpen && (
            <ReadySheetDetailsModal
              open={readySheetOpen}
              setOpen={setReadySheetOpen}
              rowData={rowData}
            />
          )}
        </Table>
        <Pagination size="medium"
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py:1
          }}
        />
      </TableContainer>
    </>
  );
}
