import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { supplierDropDownList, supplierList } from "app/services/apis/supplierList";
import { City, Country, State } from "country-state-city";
import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AllApis from "app/Apis";
import { getCurrentDate } from "app/components/Function/getCurrentDate";
import { Axios } from "index";
import FullScreenLoader from "app/components/ListingPageLoader";

export default function EditStock() {
  const [itemNameList, setItemNameList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [supplierNameList, setSupplierNameList] = useState([]);

  const stock = {
    date_of_inward: state?.date_of_inward || getCurrentDate(),
    supplier_details: {
      ...state?.supplier_details,
    },
    item_details: [
      {
        item_name: state?.item_name || "",
        available_quantity: state?.available_quantity || "",
        received_quantity: state?.received_quantity || "",
        units: state?.units || "",
        rate: state?.rate || "",
        other_goods_remarks: state?.other_goods_remarks || "",
      },
    ],
  };

  const validationSchema = yup.object({
    date_of_inward: yup.date().required("Date of Inward is required"),
    supplier_details: yup.object({
      supplier_name: yup
        .string("Enter Supplier Name")
        .required("Supplier Name is required"),
      // bill_address: yup
      //   .string("Enter Bill Address")
      //   .required("Bill Address is required"),
      // delivery_address: yup
      //   .string("Enter Delivery Address")
      //   .required("Delivery Address is required"),
      // gst_no: yup
      //   .string()
      //   .required("GST No is required")
      //   .matches(
      //     /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
      //     "Invalid GST No. Please enter a valid GST No."
      //   ),
      // city: yup.string("Enter City").required("City is required"),
      // state: yup.string("Enter State").required("State is required"),
      // pincode: yup
      //   .string("Enter Pincode")
      //   .required("Pincode is required")
      //   .matches(
      //     /^\d{6}$/,
      //     "Invalid PIN code. Please enter a valid 6-digit PIN code."
      //   ),
      // pan_no: yup
      //   .string()
      //   .required("PAN No is required")
      //   .matches(
      //     /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
      //     "Invalid PAN No. Please enter a valid PAN No."
      //   ),
      // gst_no: yup.string().required("Gst No is required").matches(),
      // country_code: yup
      //   .string("Enter Country Code")
      //   .required("Country Code is required"),
      // contact_Person_name: yup
      //   .string("Enter Contact Person Name")
      //   .required("Contact Person Name is required"),
      // contact_Person_number: yup
      //   .string("Enter Contact Person Phone No")
      //   .required("Contact Person Phone No is required"),
      // email_id: yup
      //   .string()
      //   .email("Enter a valid Supplier Email address")
      //   .required("Supplier Email is required"),
      // country: yup
      //   .string()
      //   .required("Country is required")
      //   .test(
      //     "supplier_country-not-select",
      //     "Please select a valid Country",
      //     (value) => value !== "Select"
      //   ),
    }),
    item_details: yup.array().of(
      yup.object().shape({
        item_name: yup.string().required("Required"),
        available_quantity: yup.number().positive().required("Required"),
        received_quantity: yup.number().positive().required("Required"),
        units: yup.string().required("Required"),
        rate: yup.number().positive().required("Required"),
      })
    ),
  });

  const handleSubmit = async (values) => {
    const body = {
      ...values,
      ...values?.item_details[0],
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    try {
      setSubmitting(true);
      const response = await Axios.post(
        AllApis.other_goods.editInventory(state?._id),
        body,
        config
      );
      if (response?.data?.status) {
        Swal.fire({
          title: "Inventory Edited",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("inventory/othergoods");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }

    // setItemNameList(itemNameList?.data?.result);
  };

  const filterStates = (countryIsoCode) => {
    // Use your logic to filter states based on the selected country.
    const filteredStates = State.getAllStates().filter(
      (state) => state.countryCode === countryIsoCode
    );
    setStateList(filteredStates);
  };

  const filterCities = (stateIsoCode, countryCode) => {
    // Use your logic to filter cities based on the selected state.
    const filteredCities = City.getAllCities().filter(
      (city) =>
        city.stateCode === stateIsoCode && city.countryCode == countryCode
    );
    setCityList(filteredCities);
  };

  const handleOnChange = (e, i, setFieldValue, values, name, value) => {
    let latestArray = [...values.item_details];
    if (name == "received_quantity") {
      latestArray[i] = {
        ...latestArray[i],
        [name]: value,
        available_quantity: value,
      };
    } else {
      latestArray[i] = {
        ...latestArray[i],
        [name]: value,
      };
    }

    setFieldValue("item_details", latestArray);
  };

  useEffect(() => {
    if (state) {
      const country = Country.getAllCountries().filter(
        (country) => country.name === state?.supplier_master?.supplier_country
      );
      const stateList = State.getAllStates().filter(
        (country) => country.name === state?.supplier_master?.supplier_state
      );
      filterStates(country[0]?.isoCode);
      filterCities(stateList[0]?.isoCode);
    }
  }, []);

  useEffect(async () => {
    try {
      setLoader(true);
      const data = await supplierDropDownList();
      if (data.data.status == true) {
        setSupplierNameList(data.data.result);
      }
      const itemNameList = await Axios.get(`${AllApis.dropdownList.item_name()}`);
      setItemNameList(itemNameList?.data?.result);
      const unitList = await Axios.get(`${AllApis.dropdownList.unit}`);
      setUnitList(unitList?.data?.result);
      setLoader(false);
    } catch (error) {}
  }, []);

  return (
    <Div sx={{ mt: -4 }}>
      {loader && <FullScreenLoader />}
      <Typography variant="h1">
        {pathname == "inventory/othergoods/edit"
          ? "Edit Other Goods"
          : "Add Other Goods"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={stock}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, initialValues }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4, width: "100%" }}>
                <Div sx={{ display: "flex", width: "100%", columnGap: 3 }}>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Inward Date*</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        format="DD-MM-YYYY"
                        maxDate={dayjs()}
                        defaultValue={
                          values?.date_of_inward !== ""
                            ? dayjs(values?.date_of_inward)
                            : null
                        }
                        onChange={(newValue) => {
                          setFieldValue(
                            "date_of_inward",
                            newValue
                              .startOf("day")
                              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                          );
                        }}
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage
                          name="date_of_inward"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </LocalizationProvider>
                  </Div>
                  <Div sx={{ width: "20%", mb: 2 }}>
                    <Typography variant="h5">Supplier Name*</Typography>
                    {console.log(
                      values?.supplier_details?.supplier_name,
                      "values?.supplier_details?.supplier_namevalues?.supplier_details?.supplier_name"
                    )}
                    <Select
                      size="small"
                      sx={{ width: "100%" }}
                      name="supplier_name"
                      defaultValue={values?.supplier_details?.supplier_name}
                      onChange={(e, options) => {
                        // for dropdown list
                        const country = Country.getAllCountries().filter(
                          (country) =>
                            country.name === options?.props?.action?.country
                        );
                        const stateList = State.getAllStates().filter(
                          (country) =>
                            country.name === options?.props?.action?.state
                        );
                        filterStates(country[0]?.isoCode);
                        filterCities(
                          stateList[0]?.isoCode,
                          stateList[0]?.countryCode
                        );

                        setFieldValue(
                          "supplier_details.supplier_name",
                          options?.props?.action?._id
                        );
                        setFieldValue(
                          "supplier_details.bill_address",
                          options?.props?.action?.bill_address
                        );
                        setFieldValue(
                          "supplier_details.city",
                          options?.props?.action?.city
                        );
                        setFieldValue(
                          "supplier_details.state",
                          options?.props?.action?.state
                        );
                        setFieldValue(
                          "supplier_details.pincode",
                          options?.props?.action?.pincode
                        );
                        setFieldValue(
                          "supplier_details.gst_no",
                          options?.props?.action?.gst_no
                        );
                        setFieldValue(
                          "supplier_details.contact_Person_name",
                          options?.props?.action?.contact_Person_name
                        );
                        setFieldValue(
                          "supplier_details.country_code",
                          options?.props?.action?.country_code
                        );
                        setFieldValue(
                          "supplier_details.contact_Person_number",
                          options?.props?.action?.contact_Person_number
                        );
                        setFieldValue(
                          "supplier_details.email_id",
                          options?.props?.action?.email_id
                        );
                        setFieldValue(
                          "supplier_details.supplier_name",
                          options?.props?.action?.supplier_name
                        );
                        setFieldValue(
                          "supplier_details.country",
                          options?.props?.action?.country
                        );
                        setFieldValue(
                          "supplier_details.pan_no",
                          options?.props?.action?.pan_no
                        );
                        setFieldValue(
                          "supplier_details.delivery_address",
                          options?.props?.action?.delivery_address
                        );
                      }}
                    >
                      {supplierNameList?.map((item) => {
                        return (
                          <MenuItem value={item.supplier_name} action={item}>
                            {item?.supplier_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage
                      name="supplier_details.supplier_name"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  {/* <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Contact Person Name</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="supplier_details.contact_Person_name"
                      onChange={(e) => {
                        setFieldValue(
                          "supplier_details.contact_Person_name",
                          e.target.value
                        );
                      }}
                      value={values?.supplier_details?.contact_Person_name}
                      status={true}
                    />

                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"supplier_details.contact_Person_name"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">
                      Contact Supplier Email ID
                    </Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="email_id"
                      value={values.supplier_details.email_id}
                      onChange={(e) => {
                        setFieldValue(
                          "supplier_details.email_id",
                          e.target.value
                        );
                      }}
                      status={true}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"supplier_details.email_id"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div> */}
                </Div>
                {/* 
                <Div
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    columnGap: 3,
                  }}
                >
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Country</Typography>
                    <Select
                      fullWidth
                      value={values?.supplier_details?.country}
                      size="small"
                      onChange={(e, key) => {
                        setFieldValue(
                          "supplier_details.country",
                          e?.target?.value
                        );
                        setFieldValue("supplier_details.state", "");
                        setFieldValue("supplier_details.city", "");
                        filterStates(key.key.replace(/[.$]/g, ""));
                        setFieldValue(
                          "supplier_details.countryCode",
                          key.key.replace(/[.$]/g, "")
                        );
                      }}
                    >
                      {countryList?.map((country) => {
                        return (
                          <MenuItem
                            value={country?.name}
                            key={country?.isoCode}
                          >
                            {country?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Div style={{ height: "30px" }}>
                      <ErrorMessage
                        name="supplier_details.country"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>

                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">State</Typography>
                    <Select
                      fullWidth
                      value={values?.supplier_details?.state}
                      size="small"
                      onChange={(e, key) => {
                        setFieldValue(
                          "supplier_details.state",
                          e?.target?.value
                        );
                        setFieldValue("supplier_details.city", "");
                        filterCities(
                          key.key.replace(/[.$]/g, ""),
                          values.supplier_details.countryCode
                        );
                      }}
                    >
                      {StateList?.map((state) => {
                        return (
                          <MenuItem value={state?.name} key={state?.isoCode}>
                            {state?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage
                      name="supplier_details.state"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">City</Typography>
                    <Select
                      fullWidth
                      value={values?.supplier_details?.city}
                      size="small"
                      onChange={(e) => {
                        setFieldValue("supplier_details.city", e.target.value);
                      }}
                    >
                      {cityList?.map((city) => {
                        return (
                          <MenuItem key={city.name} value={city.name}>
                            {city.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage
                      name="supplier_details.city"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Pincode</Typography>

                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="supplier_details.pincode"
                      onChange={(e) => {
                        setFieldValue(
                          "supplier_details.pincode",
                          e.target.value
                        );
                      }}
                      value={values.supplier_details?.pincode}
                      status={true}
                    />
                    {console.log(errors)}
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"supplier_details.pincode"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>

                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">PAN No</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="supplier_details.pan_no"
                      value={values?.supplier_details?.pan_no}
                      onChange={(e) => {
                        setFieldValue(
                          "supplier_details.pan_no",
                          e.target.value
                        );
                      }}
                      status={true}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"supplier_details.pan_no"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">GST NO</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="supplier_details.gst_no"
                      onChange={(e) => {
                        setFieldValue(
                          "supplier_details.gst_no",
                          e.target.value
                        );
                      }}
                      value={values?.supplier_details?.gst_no}
                      status={true}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"supplier_details.gst_no"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>

                  <Div sx={{ width: "40%" }}>
                    <Typography variant="h5">
                      Contact Person Phone No*
                    </Typography>
                    <Div
                      sx={{
                        display: "flex",
                        mt: -1,
                        width: "100%",
                      }}
                    >
                      <Select
                        size="small"
                        value={values?.supplier_details?.country_code}
                        sx={{ mt: 1, width: "20%" }}
                        onChange={(e) =>
                          setFieldValue(
                            "supplier_details.country_code",
                            e.target.value
                          )
                        }
                      >
                        {codeList?.map((country_code) => {
                          return (
                            <MenuItem value={country_code} key={country_code}>
                              {country_code}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      <TextField
                        sx={{ width: "40%", mt: 1 }}
                        size="small"
                        name="supplier_details.contact_Person_number"
                        value={values?.supplier_details?.contact_Person_number}
                        onChange={(e) => {
                          setFieldValue(
                            "supplier_details.contact_Person_number",
                            e.target.value
                          );
                        }}
                        status={true}
                      />
                    </Div>
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"supplier_details.contact_Person_number"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "100%", display: "flex", columnGap: 3 }}>
                    <Div sx={{ width: "41.5%" }}>
                      <Typography variant="h5">Bill Address</Typography>
                      <TextField
                        sx={{ width: "100%" }}
                        size="small"
                        name="supplier_details.bill_address"
                        onChange={(e) => {
                          setFieldValue(
                            "supplier_details.bill_address",
                            e.target.value
                          );
                        }}
                        value={values?.supplier_details?.bill_address}
                        status={true}
                      />
                      <Div sx={{ height: "30px", mt: 0.5 }}>
                        <ErrorMessage
                          name={"supplier_details.bill_address"}
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Div>
                    <Div sx={{ width: "41%" }}>
                      <Typography variant="h5">Delivery Address</Typography>
                      <TextField
                        sx={{ width: "100%" }}
                        size="small"
                        name="supplier_details.delivery_address"
                        onChange={(e) => {
                          setFieldValue(
                            "supplier_details.delivery_address",
                            e.target.value
                          );
                        }}
                        value={values?.supplier_details?.delivery_address}
                        status={true}
                      />
                      <Div sx={{ height: "30px", mt: 0.5 }}>
                        <ErrorMessage
                          name={"supplier_details.delivery_address"}
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Div>
                  </Div>
                </Div> */}
              </Div>
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                py: 2,
              },
            }}>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          width: "20%",

                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Item Name
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          width: "20%",

                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Received Qty
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          width: "20%",

                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Units
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          width: "20%",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Rate
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "300px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Remark
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values?.item_details?.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "20%",
                          }}
                        >
                          <Autocomplete
                            size="small"
                            id="tags-standard"
                            options={itemNameList || []}
                            sx={{ width: "100%" }}
                            name="item_name"
                            value={
                              itemNameList.filter(
                                (item) => item.item_name == row?.item_name
                              )?.[0] || null
                            }
                            getOptionLabel={(option) => option?.item_name}
                            onChange={(e, newValue) => {
                              if (newValue == null) {
                                const updateData = [...values["item_details"]];
                                updateData[i] = initialValues.item_details[0];
                                setFieldValue("item_details", updateData);
                              } else {
                                handleOnChange(
                                  e,
                                  i,
                                  setFieldValue,
                                  values,
                                  "item_name",
                                  newValue?.item_name
                                );
                              }
                            }}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                {option?.item_name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                error={!!errors?.item_details?.[i]?.item_name}
                                helperText={
                                  errors?.item_details?.[i]?.item_name || ""
                                }
                                {...params}
                                variant="outlined"
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "20%",
                          }}
                        >
                          <TextField
                            type="number"
                            inputProps={{
                              min: 0,
                            }}
                            onKeyDown={(e) => {
                              const inputValue = parseInt(
                                e.target.value + e.key,
                                10
                              );

                              if (isNaN(inputValue) || inputValue < 0) {
                                e.preventDefault();
                              }
                            }}
                            size="small"
                            sx={{ width: "100%" }}
                            error={
                              !!errors?.item_details?.[i]?.received_quantity
                            }
                            helperText={
                              errors?.item_details?.[i]?.received_quantity || ""
                            }
                            value={row.received_quantity}
                            name="received_quantity"
                            onChange={(e) =>
                              handleOnChange(
                                e,
                                i,
                                setFieldValue,
                                values,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "20%",
                          }}
                        >
                          <Autocomplete
                            size="small"
                            id="tags-standard"
                            options={unitList || []}
                            name="units"
                            sx={{ width: "100%" }}
                            value={
                              unitList.filter(
                                (item) => item.unit_name == row?.units
                              )?.[0] || null
                            }
                            getOptionLabel={(option) => option?.unit_name}
                            onChange={(e, newValue) => {
                              handleOnChange(
                                e,
                                i,
                                setFieldValue,
                                values,
                                "units",
                                newValue?.unit_name
                              );
                            }}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                {option?.unit_name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                error={!!errors?.item_details?.[i]?.units}
                                helperText={
                                  errors?.item_details?.[i]?.units || ""
                                }
                                {...params}
                                variant="outlined"
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "20%",
                          }}
                        >
                          <TextField
                            type="number"
                            inputProps={{
                              min: 0,
                            }}
                            onKeyDown={(e) => {
                              const inputValue = parseInt(
                                e.target.value + e.key,
                                10
                              );

                              if (isNaN(inputValue) || inputValue < 0) {
                                e.preventDefault();
                              }
                            }}
                            size="small"
                            error={!!errors?.item_details?.[i]?.rate}
                            helperText={errors?.item_details?.[i]?.rate || ""}
                            value={row.rate}
                            sx={{ width: "100%" }}
                            name="rate"
                            onChange={(e) =>
                              handleOnChange(
                                e,
                                i,
                                setFieldValue,
                                values,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          <TextField
                            multiline
                            size="small"
                            value={row.other_goods_remarks}
                            error={
                              !!errors?.item_details?.[i]?.other_goods_remarks
                            }
                            sx={{ width: "100%" }}
                            helperText={
                              errors?.item_details?.[i]?.other_goods_remarks ||
                              ""
                            }
                            name="other_goods_remarks"
                            onChange={(e) =>
                              handleOnChange(
                                e,
                                i,
                                setFieldValue,
                                values,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Div
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                  mt: 5,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("inventory/othergoods");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>

                <LoadingButton
                  loading={isSubmitting}
                  variant="contained"
                  type="submit"
                >
                  Edit
                </LoadingButton>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
