// import React, { useEffect, useState } from "react";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Modal from "@mui/material/Modal";
// import { Close } from "@mui/icons-material";

// import { TextField, Typography } from "@mui/material";
// // import ToastAlerts from '../Toast';
// import { useNavigate } from "react-router-dom";
// import { Axios } from "index";
// import Swal from "sweetalert2";
// const style = {
//   position: "absolute",
//   top: "40%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   // width: 400,
//   bgcolor: "background.paper",
//   border: "1px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

// function AddDepartmentModal({ open, handleClose, deptData, setToggle }) {
//   console.log("values got => ", deptData);
//   const [dept, setDept] = useState({
//     dept_name: "",
//   });

//   useEffect(() => {
//     if (deptData) {
//       setDept({ dept_name: deptData?.data?.dept_name });
//     } else {
//       setDept({ dept_name: "" });
//     }
//   }, [deptData]);

//   const handleSubmit = async () => {
//     try {
//       let res;
//       if (deptData) {
//         res = await Axios.post(
//           `/department-master/update-department/${deptData.id}`,
//           dept
//         );
//       } else {
//         res = await Axios.post("/department-master/add-department", dept);
//       }

//       if (res.data.statusCode === 200) {
//         Swal.fire({
//           icon: "success",
//           title: `${
//             deptData
//               ? "Department Updated Successfully"
//               : "Department Created Successfully"
//           } `,
//           text: "",
//           timer: 1000,
//           showConfirmButton: false,
//         });
//         setToggle((prevState) => !prevState);
//         handleClose();
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: res.data.message,
//           text: "",
//           timer: 1000,
//           showConfirmButton: false,
//         });
//       }
//     } catch (error) {
//       console.log("err adding series => ", error.message);
//     }
//   };

//   return (
//     <div>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style} className="lg:w-1/3 w-[20rem]">
//           <div className="flex flex-row items-center justify-between">
//             <Typography variant="h2" component="h2">
//               Add Department
//             </Typography>
//             <Close
//               onClick={handleClose}
//               className="mb-4"
//               style={{ cursor: "pointer", float: "right" }}
//             />
//           </div>
//           <div className="flex flex-col items-start mt-6 justify-center ">
//             <label>Department Name*</label>
//             <TextField
//               size="small"
//               fullWidth
//               margin="normal"
//               name="name"
//               required
//               value={dept?.dept_name}
//               onChange={(e) => setDept({ ...dept, dept_name: e.target.value })}
//             />
//           </div>
//           <div className="flex items-center justify-center mt-5">
//             <Button variant="contained" color="primary" onClick={handleSubmit}>
//               {deptData ? "Update" : "Add"}
//             </Button>
//           </div>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

// export default AddDepartmentModal;

//new added formik with validation

import React from "react";
import { Box, Button, Modal, Typography, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { Axios } from "index"; // Assuming Axios is properly configured
import { LoadingButton } from "@mui/lab";
import FormTextField1 from "app/components/InputField/FormTextField1";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object({
  dept_name: yup
    .string("Enter Department Name")
    .required("Department Name is required"),
  remark: yup.string("Enter Remarks"),
});

function AddDepartmentModal({ open, handleClose, deptData, setToggle }) {
  const initialValues = {
    dept_name: deptData?.data?.dept_name ? deptData?.data?.dept_name : "",
    remark: deptData?.data?.remark ? deptData?.data?.remark : "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let res;
      const modifiedValues = {
        ...values,
        dept_name: values?.dept_name?.toUpperCase(),
      };
      if (deptData) {
        res = await Axios.post(
          `/department-master/update-department/${deptData.id}`,
          modifiedValues
        );
      } else {
        res = await Axios.post(
          "/department-master/add-department",
          modifiedValues
        );
      }

      if (res.data.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: deptData
            ? "Department Updated Successfully"
            : "Department Created Successfully",
          timer: 1000,
          showConfirmButton: false,
        });
        setToggle((prevState) => !prevState);
        handleClose();
      } else {
        Swal.fire({
          icon: "error",
          title: res.data.message,
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.log("Error adding/updating department => ", error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
      <Box sx={style} className="lg:w-1/3 w-[20rem]">
        <div className="flex flex-row items-center justify-between">
          <Typography variant="h2">
            {deptData ? "Edit Department" : "Add Department"}
          </Typography>
          <Close onClick={handleClose} style={{ cursor: "pointer" }} />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, handleChange, isSubmitting }) => (
            <Form noValidate>
              <div className="grid grid-flow-row lg:grid-cols-2 grid-cols-1 gap-5 mt-5">
                <FormTextField1 name="dept_name" label="Department Name*" />
                <FormTextField1 name="remark" label="Remark" />
              </div>

              <div className="flex items-center justify-center mt-5">
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={isSubmitting}
                >
                  {deptData ? "Update" : "Add"}
                </LoadingButton>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

export default AddDepartmentModal;
