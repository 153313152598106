import Page from "@jumbo/shared/Page";
import ReadyForDispatchList from "app/pages/Inventory/ReadyForDispatch/List";
import QCRouteMiddleware from "./middleware/auth/qcValidRoute";

export const readyForDispatchRoutes = [
  {
    middleware: [
      {
        element: QCRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "inventory/readyForDispatch",
        element: (
          <Page component={ReadyForDispatchList} layout={"vertical-default"} />
        ),
      },
    ],
  },
];
