/* eslint-disable default-case */
import Div from "@jumbo/shared/Div";
import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import AllApis from "app/Apis";
import { formatDate } from "app/components/Function/formatDate";
import FullScreenLoader from "app/components/ListingPageLoader";
import { RawInventoryModal } from "app/components/Modal/rawInventoryModal";
import { IssuedDyingPattasModal } from "app/pages/Inventory/TableComponent/Modals/issuedDyingPattas";
import { getAllIssuedDying } from "app/redux/actions/inventoryAction";
import { Axios } from "index";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function IssuedForDyingIndividual({
  page,
  setPage,
  searchTerm,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
  addGroup,
  setAddGroup,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { issuedDying, error, TotalPage, loading } = useSelector(
    (state) => state.inventoryReducer
  );
  const [open, setOpen] = useState(false);
  const [issuedSmokingDetails, setIssuedSmokingDetails] = useState(false);
  const [rowData, setRowData] = useState({});

  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllIssuedDying(searchTerm, sort, sortBy, newPage, filter));
  };

  const revertSmoking = async (id) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Revert Issued Dying",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await Axios.post(
            AllApis.raw_material.cancelSmoking,
            { id: id },
            config
          );
          if (response) {
            dispatch(getAllIssuedDying(searchTerm, sort, sortBy, page, filter));
            Swal.fire({
              title: "Dying Cancelled",
              icon: "success",
              timer: 1000,
              showConfirmButton: false,
            });
          }
        } catch (error) {
          Swal.fire({
            title: error.message,
            icon: "error",
            timer: 1000,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  const handleCheckbox = (e, row) => {
    if (e.target.checked == true) {
      const selectedArr = [...addGroup];
      selectedArr.push(row);
      setAddGroup(selectedArr);
    } else {
      const selectedArr = [...addGroup];
      const deletedIds = selectedArr.filter((ele) => ele != row);
      setAddGroup(deletedIds);
    }
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      {addGroup?.length > 0 && (
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
            bgcolor: "#dbdbdb",
            p: 2,
            borderRadius: "5px",
          }}
        >
          <Typography variant="h5" sx={{ color: "black", mt: 1 }}>
            {addGroup?.length} Bundles Selected
          </Typography>
          <Div sx={{ display: "flex", columnGap: 3 }}>
            <LoadingButton
              variant="contained"
              onClick={() =>
                navigate("factory/dying/create-dying-individual", {
                  state: addGroup,
                })
              }
            >
              Create Dying Individual
            </LoadingButton>

            <LoadingButton
              variant="contained"
              color="error"
              onClick={() => setAddGroup([])}
            >
              Cancel
            </LoadingButton>
          </Div>
        </Div>
      )}

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                py: 2,
              },
            }}>
              {permissions?.dying_create && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "95px",
                    color: "white",
                  }}
                >
                  Select
                </TableCell>
              )}

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "300px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_name"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_code"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_code")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "90px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_log_no"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_log_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Log No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_bundle_no"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_bundle_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Bundle No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_length"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_length")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Length (cm)
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_width"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_width")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Width (cm)
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_available_pattas"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_available_pattas")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Avl Pattas
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "135px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "issued_dying_quantity.total"}
                  direction={sort}
                  onClick={() => handleSort("issued_dying_quantity.total")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Issued Pattas
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_available_sqm"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_available_sqm")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Avl Total Sq.m.
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "75px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_grade"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_grade")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Grade
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_pallete_no"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_pallete_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Pallet No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_physical_location"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_physical_location")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Physical Location
                </TableSortLabel>
              </TableCell>
              {/* <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "85px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Dying Details
              </TableCell> */}
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "85px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.supplier_details.supplier_name"}
                  direction={sort}
                  onClick={() =>
                    handleSort("item_id.supplier_details.supplier_name")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Supplier
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Issued Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {issuedDying?.map((row, i) => (
              <TableRow key={i}>
                {permissions?.dying_create && (
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    <Checkbox
                      onClick={(event) => handleCheckbox(event, row)}
                      color="primary"
                      checked={addGroup?.some((ele) => ele == row)}
                    />
                  </TableCell>
                )}
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_code}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_log_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_bundle_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_length}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_width}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_available_pattas}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.issued_dying_quantity}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_available_sqm}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_grade}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_pallete_no}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.item_id?.item_physical_location}
                </TableCell>
                {/* <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setIssuedSmokingDetails(true);
                      setRowData(row);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell> */}
                <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setOpen(true);
                      setRowData(row?.item_id);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.supplier_details?.supplier_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {formatDate(row?.created_at)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {open && (
            <RawInventoryModal
              open={open}
              setOpen={setOpen}
              rowData={rowData}
            />
          )}
          {issuedSmokingDetails && (
            <IssuedDyingPattasModal
              open={issuedSmokingDetails}
              setOpen={setIssuedSmokingDetails}
              rowData={rowData}
            />
          )}
        </Table>
        <Pagination size="medium"
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py:1
          }}
        />
      </TableContainer>
    </>
  );
}
