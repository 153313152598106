import Page from "@jumbo/shared/Page";
import AddSupplier from "app/pages/Master/SupplierMaster/AddSupplier";
import ListSupplier from "app/pages/Master/SupplierMaster/ListSupplier";
import AddUnit from "app/pages/Master/UnitMaster/AddUnit";
import ListUnit from "app/pages/Master/UnitMaster/ListUnit";
import SupplierRouteMiddleware from "./middleware/auth/supplierMasterValidRoute";
import UnitRouteMiddleware from "./middleware/auth/unitValidRoute";
import palletMiddleware from "./middleware/auth/PalletMiddleware";
import PartyMiddleware from "./middleware/auth/PartyMiddleware";
import ItemNameMiddleware from "./middleware/auth/ItemNameMiddleware";
import ItemCodeMiddleware from "./middleware/auth/ItemCodeMiddleware";
import GradeMiddleware from "./middleware/auth/GradeMiddleware";
import AddPallet from "app/pages/Master/PalletMaster/AddPallet";
import ListPallet from "app/pages/Master/PalletMaster/ListPallet";
import AddGrade from "app/pages/Master/GradeMaster/AddGrade";
import ListGrade from "app/pages/Master/GradeMaster/ListGrade";
import AddParty from "app/pages/Master/PartyMaster/AddParty";
import ListParty from "app/pages/Master/PartyMaster/ListParty";
import AddItemName from "app/pages/Master/ItemNameMaster/AddItemName";
import ListItemName from "app/pages/Master/ItemNameMaster/ListItemName";
import AddItemCode from "app/pages/Master/ItemCodeMaster/AddItemCode";
import ListItemCode from "app/pages/Master/ItemCodeMaster/ListItemCode";
import AddBranches from "app/pages/Master/SupplierMaster/Branches/AddBranches";
import ListBranches from "app/pages/Master/SupplierMaster/Branches/ListBranches";
import EditSupplierPage from "app/pages/Master/SupplierMaster/EditSupplier/EditSupplierPage";
import ContactPersonList from "app/pages/Master/SupplierMaster/ListSupplier/ContactPersonList";
import ItemCategoryMiddleware from "./middleware/auth/itemCategory";
import ListItems from "app/pages/Master/ItemCategory/ListCategory/ListItems";
import AddItemPage from "app/pages/Master/ItemCategory/AddItem/AddItemPage";
import EditCatgeoryPage from "app/pages/Master/ItemCategory/EditCategory/EditCatgeoryPage";
import Main from "app/pages/Master/ItemCategory/Main";
import ItemSubCategoryMiddleware from "./middleware/auth/ItemSubCategory";
import Subcategory from "app/pages/Master/ItemSubCategory/SubCategory";
import AddSubcategoryPage from "app/pages/Master/ItemSubCategory/AddSubcategory/AddSubcategoryPage";
import EditSubCategorypage from "app/pages/Master/ItemSubCategory/EditSubCategory/EditSubCategorypage";
import CurrencyMiddleware from "./middleware/auth/CurrencyMiddleware";
import ListCurrency from "app/pages/Master/CurrencyMaster/ListCurrency";
import AddCurrency from "app/pages/Master/CurrencyMaster/AddCurrency";
import CutMiddleware from "./middleware/auth/CutMiddleware";
import ListCut from "app/pages/Master/CutMaster/ListCut";
import AddCut from "app/pages/Master/CutMaster/AddCut";
import GstMiddleware from "./middleware/auth/GstMiddleware";
import ListGst from "app/pages/Master/GstMaster/ListGst";
import AddGst from "app/pages/Master/GstMaster/AddGst";
import SeriesMasterMiddleware from "./middleware/auth/SeriesMasterMiddleware";
import ListSeries from "app/pages/Master/SeriesMaster/ListSeries/ListSeries";
import AddSeries from "app/pages/Master/SeriesMaster/AddSeries/AddSeries";
import Series from "app/pages/Master/SeriesMaster/Series";
import DepartmentMiddleware from "./middleware/auth/departmentMidlleware";
import MachineMiddleware from "./middleware/auth/machineMiddleware";
import Department from "app/pages/Master/DepartmentMaster/Department";
import AddDepartmentPage from "app/pages/Master/DepartmentMaster/AddDepartment/AddDepartmentPage";
import Machine from "app/pages/Master/MachineMaster/Machine";
import AddMachinePage from "app/pages/Master/MachineMaster/AddMachine/AddMachinePage";
import ExpenseTypeMiddleware from "./middleware/auth/ExpenseTypeMiddleware";
import ListExpenseType from "app/pages/Master/ExpenseTypeMaster/ListExpenseType";
import AddExpenseType from "app/pages/Master/ExpenseTypeMaster/AddExpenseType";




export const masterRoutes = [
  {
    middleware: [
      {
        element: SupplierRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/master/supplier",
        element: <Page component={ListSupplier} layout={"vertical-default"} />,
      },
      {
        path: "/master/supplier/add",
        element: <Page component={AddSupplier} layout={"vertical-default"} />,
      },
      {
        path: "/master/supplier/edit",
        element: <Page component={AddSupplier} layout={"vertical-default"} />,
      },
      {
        path: "/master/supplier/branch/add/:id",
        element: <Page component={AddBranches} layout={"vertical-default"} />,
      },
      {
        path: "/master/supplier/branch/list/:id",
        element: <Page component={ListBranches} layout={"vertical-default"} />,
      },
      {
        path: "/master/supplier/edit/:id",
        element: <Page component={EditSupplierPage} layout={"vertical-default"} />,
      },
      {
        path: "/master/supplier/contact-person-list",
        element: <Page component={ContactPersonList} layout={"vertical-default"} />,
      },
    ],
  },


  //item-category

  {
    middleware: [
      {
        element: ItemCategoryMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/master/item-category/list",
        element: <Page component={Main} layout={"vertical-default"} />,
      },
      {
        path: "/master/item-category/add",
        element: <Page component={AddItemPage} layout={"vertical-default"} />,
      },
      {
        path: "/master/item-category/edit/:id",
        element: <Page component={EditCatgeoryPage} layout={"vertical-default"} />,
      },
    ],
  },

  //item-sub-category

  {
    middleware: [
      {
        element: ItemSubCategoryMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/master/item-subcategory/list",
        element: <Page component={Subcategory} layout={"vertical-default"} />,
      },
      {
        path: "/master/item-subcategory/add",
        element: <Page component={AddSubcategoryPage} layout={"vertical-default"} />,
      },
      {
        path: "/master/item-subcategory/edit/:id",
        element: <Page component={EditSubCategorypage} layout={"vertical-default"} />,
      },
    ],
  },

  {
    middleware: [
      {
        element: palletMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/master/pallet",
        element: <Page component={ListPallet} layout={"vertical-default"} />,
      },
      {
        path: "/master/pallet/add",
        element: <Page component={AddPallet} layout={"vertical-default"} />,
      },
      {
        path: "/master/pallet/edit",
        element: <Page component={AddPallet} layout={"vertical-default"} />,
      },
    ],
  },

  {
    middleware: [
      {
        element: UnitRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/master/unit",
        element: <Page component={ListUnit} layout={"vertical-default"} />,
      },
      {
        path: "/master/unit/add",
        element: <Page component={AddUnit} layout={"vertical-default"} />,
      },
      {
        path: "/master/unit/edit",
        element: <Page component={AddUnit} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: GradeMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/master/grade",
        element: <Page component={ListGrade} layout={"vertical-default"} />,
      },
      {
        path: "/master/grade/add",
        element: <Page component={AddGrade} layout={"vertical-default"} />,
      },
      {
        path: "/master/grade/edit",
        element: <Page component={AddGrade} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: CurrencyMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/master/currency",
        element: <Page component={ListCurrency} layout={"vertical-default"} />,
      },
      {
        path: "/master/currency/add",
        element: <Page component={AddCurrency} layout={"vertical-default"} />,
      },
      {
        path: "/master/currency/edit",
        element: <Page component={AddCurrency} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: CutMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/master/cut",
        element: <Page component={ListCut} layout={"vertical-default"} />,
      },
      {
        path: "/master/cut/add",
        element: <Page component={AddCut} layout={"vertical-default"} />,
      },
      {
        path: "/master/cut/edit",
        element: <Page component={AddCut} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: GstMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/master/gst",
        element: <Page component={ListGst} layout={"vertical-default"} />,
      },
      {
        path: "/master/gst/add",
        element: <Page component={AddGst} layout={"vertical-default"} />,
      },
      {
        path: "/master/gst/edit",
        element: <Page component={AddGst} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: ExpenseTypeMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/master/expenseType",
        element: <Page component={ListExpenseType} layout={"vertical-default"} />,
      },
      {
        path: "/master/expenseType/add",
        element: <Page component={AddExpenseType} layout={"vertical-default"} />,
      },
      {
        path: "/master/expenseType/edit",
        element: <Page component={AddExpenseType} layout={"vertical-default"} />,
      },
    ],
  },

  {
    middleware: [
      {
        element: PartyMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/master/party",
        element: <Page component={ListParty} layout={"vertical-default"} />,
      },
      {
        path: "/master/party/add",
        element: <Page component={AddParty} layout={"vertical-default"} />,
      },
      {
        path: "/master/party/edit",
        element: <Page component={AddParty} layout={"vertical-default"} />,
      },
    ],
  },


  {
    middleware: [
      {
        element: ItemNameMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/master/item-name",
        element: <Page component={ListItemName} layout={"vertical-default"} />,
      },
      {
        path: "/master/item-name/add",
        element: <Page component={AddItemName} layout={"vertical-default"} />,
      },
      {
        path: "/master/item-name/edit",
        element: <Page component={AddItemName} layout={"vertical-default"} />,
      },
    ],
  },


  {
    middleware: [
      {

        element: ItemCodeMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/master/item-code",
        element: <Page component={ListItemCode} layout={"vertical-default"} />,
      },
      {
        path: "/master/item-code/add",
        element: <Page component={AddItemCode} layout={"vertical-default"} />,
      },
      {
        path: "/master/item-code/edit",
        element: <Page component={AddItemCode} layout={"vertical-default"} />,
      },
    ],
  },



  {
    middleware: [
      {

        element: SeriesMasterMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/master/series",
        element: <Page component={Series} layout={"vertical-default"} />,
      },
      {
        path: "/master/series/add",
        element: <Page component={AddSeries} layout={"vertical-default"} />,
      },
      {
        path: "/master/series/edit",
        element: <Page component={AddSeries} layout={"vertical-default"} />,
      },
    ],
  },

  {
    middleware: [
      {

        element: DepartmentMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/master/department",
        element: <Page component={Department} layout={"vertical-default"} />,
      },
      {
        path: "/master/department/add",
        element: <Page component={AddDepartmentPage} layout={"vertical-default"} />,
      },
      {
        path: "/master/department/edit",
        element: <Page component={AddDepartmentPage} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {

        element: MachineMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/master/machine",
        element: <Page component={Machine} layout={"vertical-default"} />,
      },
      {
        path: "/master/machine/add",
        element: <Page component={AddMachinePage} layout={"vertical-default"} />,
      },
      {
        path: "/master/machine/edit",
        element: <Page component={AddMachinePage} layout={"vertical-default"} />,
      },
    ],
  },
];
