import Div from "@jumbo/shared/Div/Div";
import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AllApis from "app/Apis";
import FullScreenLoader from "app/components/ListingPageLoader";
import {
  getAllOtherGoods,
  getAllOtherGoodsConsumed,
} from "app/redux/actions/inventoryAction";
import { supplierDropDownList, supplierList } from "app/services/apis/supplierList";
import dayjs from "dayjs";
import { Axios } from "index";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ConsumedGoodsTable from "./ConsumedGoods";
import OtherGoodsTable from "./OtherGoodsTable";
import { LoadingButton } from "@mui/lab";
import { handleLogs } from "app/components/Function/logsDownloadFunction";
import FilterAccordian from "app/components/FilterAccordian";

export default function ListPurchase() {
  const [loaded, setLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = React.useState(0);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("updated_at");
  const [itemNameList, setItemNameList] = useState([]);
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reportFilter, setReportFilter] = useState({
    month: "",
    year: "",
  });
  const [filters, setFilters] = useState({
    from: "",
    to: "",
  });
  const [emptyVal, setEmptyVal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );
  const state = useSelector((state) => state?.inventoryReducer);
  console.log(state, "state");
  const TabChange = () => {
    setFilters({
      from: "",
      to: "",
    });
    setEmptyVal(!emptyVal);
    setPage(1);
    setSort("desc");
    setSortBy("updated_at");
    setSearchTerm("");
    setReportFilter({
      month: "",
      year: "",
    });
  };

  const handleSearch = (search) => {
    setPage(1);
    if (value == 0) {
      dispatch(getAllOtherGoods(search, sort, sortBy, page, filters));
    } else if (value == 1) {
      dispatch(getAllOtherGoodsConsumed(search, sort, sortBy, page, filters));
    }
    setLoaded(true);
  };

  const debouncedHandleSearch = debounce(handleSearch, 1000);

  // useEffect(() => {
  //   if (searchTerm !== "") {
  //     debouncedHandleSearch(searchTerm);
  //   }
  //   return () => {
  //     debouncedHandleSearch.cancel();
  //   };
  // }, [searchTerm]);

  useEffect(() => {
    //to deal with search lagging
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    } else {
      setLoaded(true);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  const handleFilter = () => {
    setPage(1);
    if (value == 0) {
      dispatch(getAllOtherGoods(searchTerm, sort, sortBy, "", filters));
    } else if (value == 1) {
      dispatch(getAllOtherGoodsConsumed(searchTerm, sort, sortBy, "", filters));
    }
  };
  const handleClear = () => {
    TabChange();
    if (value == 0) {
      dispatch(getAllOtherGoods(searchTerm, sort, sortBy, page, {}));
    } else if (value == 1) {
      dispatch(getAllOtherGoodsConsumed(searchTerm, sort, sortBy, page, {}));
    }
  };

  const datepickerStyling = {
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#aeb7bd ", // or any other color you prefer
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#475259", // Change to the color you want on hover
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#7352C7", // Change to the color you want when focused
    },
    "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#7352C7", // Keep the same color as focused when hovered
      },
    "& .MuiInputLabel-outlined": {
      color: "#98a3aa", // Default label color
      "&.Mui-focused": {
        color: "#7352C7", // Label color on focus
      },
    },
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  //export csv
  const exportConsumedCsv = async (filter) => {
    const body = {
      filters: {
        ...filter,
      },
    };

    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        AllApis.reports.consumeGoods(reportFilter?.month, reportFilter?.year),
        body,
        config
      );
      if (response?.status == 200) {
        window.open(response?.data?.result);
        Swal.fire({
          title: "Downloaded",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const exportOtherGoodsCsv = async (filter) => {
    const body = {
      filters: {
        ...filter,
      },
    };

    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        AllApis.reports.otherGoods,
        body,
        config
      );
      if (response?.status == 200) {
        window.open(response?.data?.result);
        Swal.fire({
          title: "Downloaded",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    TabChange();
    if (newValue == 0) {
      dispatch(getAllOtherGoods("", sort, sortBy, page, {}));
    } else if (newValue == 1) {
      dispatch(getAllOtherGoodsConsumed("", sort, sortBy, page, {}));
    }
  };

  useEffect(async () => {
    try {
      const data = await supplierDropDownList();
      if (data.data.status == true) {
        setSupplierNameList(data.data.result);
      }
      const itemNameList = await Axios.get(`${AllApis.dropdownList.item_name()}`);
      setItemNameList(itemNameList?.data?.result);
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (value == 0) {
      dispatch(getAllOtherGoods(searchTerm, sort, sortBy, page, filters));
    } else if (value == 1) {
      dispatch(
        getAllOtherGoodsConsumed(searchTerm, sort, sortBy, page, filters)
      );
    }
  }, [sort, page]);

  useEffect(() => {
    //to deal with search input lagging
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);

    // Clean-up function to clear the timer in case the component unmounts before the timeout
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Div sx={{ mt: -4 }}>
        <Typography variant="h1">Other Goods</Typography>
        <FilterAccordian
          actions={
            <Div>
              <Button
                variant="contained"
                sx={{ marginRight: 1 }}
                onClick={handleFilter}
              >
                Apply
              </Button>
              <Button variant="outlined" onClick={handleClear}>
                Clear
              </Button>
            </Div>
          }
        >
          <Box
            sx={{ display: "flex", rowGap: 4, columnGap: 4, flexWrap: "wrap" }}
          >
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                freeSolo
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={supplierNameList || []}
                getOptionLabel={(option) => option?.supplier_name || ""}
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    "supplier_details.supplier_name":
                      newValue != null ? newValue?.supplier_name : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.supplier_name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Supplier Name"
                  />
                )}
              />
            </Div>
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                freeSolo
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={itemNameList || []}
                getOptionLabel={(option) => option?.item_name || ""}
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    item_name: newValue != null ? newValue?.item_name : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.item_name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Item Name" />
                )}
              />
            </Div>

            <Div sx={{ width: "23%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key={emptyVal ? "refresh1" : "refresh2"}
                  id="from"
                  format="DD-MM-YYYY"
                  name="from"
                  label={
                    value == 0 ? "From Inward Date" : "From Consumption Date"
                  }
                  sx={datepickerStyling}
                  defaultValue={
                    filters.from !== "" ? dayjs(filters.from) : null
                  }
                  maxDate={dayjs()}
                  onChange={(newValue) => {
                    setFilters({
                      ...filters,
                      from: newValue
                        .startOf("day")
                        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    });
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
              {filters?.to && !filters?.from && (
                <Typography
                  sx={{
                    color: "#E73145",
                    fontSize: "0.75rem",
                    ml: 1,
                    mt: 0.5,
                  }}
                >
                  {value == 0
                    ? "Select From Inward date"
                    : "Select From Consumption Date"}
                </Typography>
              )}
            </Div>
            <Div sx={{ width: "23%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key={emptyVal ? "refresh1" : "refresh2"}
                  id="to"
                  format="DD-MM-YYYY"
                  name="to"
                  label={value == 0 ? "To Inward Date" : "To Consumption Date"}
                  sx={datepickerStyling}
                  defaultValue={filters.to !== "" ? dayjs(filters.to) : null}
                  maxDate={dayjs()}
                  onChange={(newValue) => {
                    setFilters({
                      ...filters,
                      to: newValue
                        .startOf("day")
                        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    });
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
              {!filters?.to && filters?.from && (
                <Typography
                  sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}
                >
                  {value == 0
                    ? "Select To Inward Date"
                    : "Select To Consumption Date"}
                </Typography>
              )}
            </Div>
            {value == 1 && (
              <>
                <Div sx={{ width: "23%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      key={emptyVal ? "refresh1" : "refresh2"}
                      views={["month"]}
                      label="Report Month"
                      defaultValue={
                        reportFilter?.month ? dayjs(reportFilter?.month) : null
                      }
                      sx={{ ...datepickerStyling, width: "100%" }}
                      slotProps={{ textField: { size: "small" } }}
                      onChange={(newValue) => {
                        // Extract month from the newValue
                        if (newValue != null) {
                          const monthNumber =
                            newValue.startOf("month").month() + 1; // January is 0, so we add 1
                          setReportFilter({
                            ...reportFilter,
                            month: monthNumber,
                          });
                        } else {
                          setReportFilter({
                            ...reportFilter,
                            month: "",
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Div>
                <Div sx={{ width: "23%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      key={emptyVal ? "refresh1" : "refresh2"}
                      views={["year"]} // Only include "year" view
                      label="Select Year"
                      defaultValue={
                        reportFilter?.year ? dayjs(reportFilter?.year) : null
                      } // Assuming reportFilter contains the selected year
                      sx={{ ...datepickerStyling, width: "100%" }}
                      slotProps={{ textField: { size: "small" } }}
                      onChange={(newValue) => {
                        // Extract year from the newValue
                        if (newValue != null) {
                          const yearNumber = newValue?.startOf("year").year();
                          setReportFilter({
                            ...reportFilter,
                            year: yearNumber,
                          });
                        } else {
                          setReportFilter({
                            ...reportFilter,
                            year: "",
                          });
                        }
                        // You can then update the state or do whatever you need with the selected year
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </LocalizationProvider>
                </Div>
              </>
            )}
          </Box>
        </FilterAccordian>
        {/* <Div className="Filters" sx={{ width: "100%", mt: 4 }}>
          <Typography variant="h1" sx={{ mb: 3 }}>
            Filters
          </Typography>
          <Box
            sx={{ display: "flex", rowGap: 4, columnGap: 4, flexWrap: "wrap" }}
          >
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                freeSolo
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={supplierNameList || []}
                getOptionLabel={(option) => option?.supplier_name || ""}
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    "supplier_details.supplier_name":
                      newValue != null ? newValue?.supplier_name : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.supplier_name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Supplier Name"
                  />
                )}
              />
            </Div>
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                freeSolo
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={itemNameList || []}
                getOptionLabel={(option) => option?.item_name || ""}
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    item_name: newValue != null ? newValue?.item_name : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.item_name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Item Name" />
                )}
              />
            </Div>

            <Div sx={{ width: "23%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key={emptyVal ? "refresh1" : "refresh2"}
                  id="from"
                  format="DD-MM-YYYY"
                  name="from"
                  label={
                    value == 0 ? "From Inward Date" : "From Consumption Date"
                  }
                  sx={datepickerStyling}
                  defaultValue={
                    filters.from !== "" ? dayjs(filters.from) : null
                  } maxDate={dayjs()}
                  onChange={(newValue) => {
                    setFilters({
                      ...filters,
                      from: newValue
                        .startOf("day")
                        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    });
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
              {filters?.to && !filters?.from && (
                <Typography
                  sx={{
                    color: "#E73145",
                    fontSize: "0.75rem",
                    ml: 1,
                    mt: 0.5,
                  }}
                >
                  {value == 0
                    ? "Select From Inward date"
                    : "Select From Consumption Date"}
                </Typography>
              )}
            </Div>
            <Div sx={{ width: "23%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key={emptyVal ? "refresh1" : "refresh2"}
                  id="to"
                  format="DD-MM-YYYY"
                  name="to"
                  label={value == 0 ? "To Inward Date" : "To Consumption Date"}
                  sx={datepickerStyling}
                  defaultValue={filters.to !== "" ? dayjs(filters.to) : null}
maxDate={dayjs()}
                  onChange={(newValue) => {
                    setFilters({
                      ...filters,
                      to: newValue
                        .startOf("day")
                        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    });
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
              {!filters?.to && filters?.from && (
                <Typography
                  sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}
                >
                  {value == 0
                    ? "Select To Inward Date"
                    : "Select To Consumption Date"}
                </Typography>
              )}
            </Div>
            {value == 1 && (
              <>
                <Div sx={{ width: "23%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["month"]}
                      label="Report Month"
                      value={dayjs(reportFilter?.month)}
                      sx={{ width: "100%" }}
                      onChange={(newValue) => {
                        // Extract month from the newValue
                        if (newValue != null) {
                          const monthNumber =
                            newValue.startOf("month").month() + 1; // January is 0, so we add 1
                          setReportFilter({
                            ...reportFilter,
                            month: monthNumber,
                          });
                        } else {
                          setReportFilter({
                            ...reportFilter,
                            month: "",
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </LocalizationProvider>
                </Div>
                <Div sx={{ width: "23%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year"]} // Only include "year" view
                      label="Select Year"
                      value={dayjs(reportFilter)} // Assuming reportFilter contains the selected year
                      sx={{ width: "100%" }}
                      onChange={(newValue) => {
                        // Extract year from the newValue
                        if (newValue != null) {
                          const yearNumber = newValue?.startOf("year").year();
                          setReportFilter({
                            ...reportFilter,
                            year: yearNumber,
                          });
                        } else {
                          setReportFilter({
                            ...reportFilter,
                            year: "",
                          });
                        }
                        // You can then update the state or do whatever you need with the selected year
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </LocalizationProvider>
                </Div>
              </>
            )}
          </Box>
          <Box
            sx={{
              mt: 4,
              mb: 4,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Div>
              <Button
                variant="contained"
                sx={{ marginRight: 1 }}
                onClick={handleFilter}
              >
                Apply
              </Button>
              
              <Button variant="outlined" onClick={handleClear}>
                Clear
              </Button>
            </Div>
          </Box>
        </Div> */}

        <Div>
          <Div
            sx={{
              display: "flex",
              columnGap: 2,
              justifyContent: "flex-end",
              my: 2,
            }}
          >
            {permissions.other_goods_create == true && value == 0 && (
              <>
                <Button
                  size="small"
                  variant="contained"
                  // sx={{ p: 1, pl: 4, pr: 4 }}
                  onClick={() =>
                    navigate("inventory/othergoods/add")
                  }
                >
                  Add Other Goods
                </Button>
                <Button
                  size="small"
                  disabled={state?.otherGoods?.length === 0}
                  variant="contained"
                  onClick={async () => {
                    value == 0 && exportOtherGoodsCsv(filters);
                  }}
                >
                  Export CSV
                </Button>
              </>
            )}
            {/* {permissions.other_goods_view == true && (
              <LoadingButton
                size="small"
                variant="contained"
                sx={{
                  // p: 1,
                  // pl: 4,
                  // pr: 4,
                  ml: 1,
                }}
                onClick={() => {
                  const logs = [
                    {
                      url: "other-goods-inventory/othergood-logs",
                      file: "Other Goods",
                      value: 0,
                    },
                    {
                      url: "other-goods-inventory/othergoodconsumed-logs",
                      file: "Consumed",
                      value: 1,
                    },
                  ].filter((ele) => ele.value == value);
                  handleLogs(logs[0].url, logs[0].file);
                }}
              >
                Log
              </LoadingButton>
            )} */}
            {permissions.other_goods_view && value != 0 && (
              <Button
                size="small"
                disabled={
                  state?.otherGoodsConsumed?.length === 0 ||
                  reportFilter.month === "" ||
                  reportFilter.year === ""
                }
                variant="contained"
                onClick={async () => {
                  value == 1 && exportConsumedCsv(filters);
                }}
              >
                Export CSV
              </Button>
            )}
          </Div>
        </Div>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", display: "flex" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Other Goods" {...a11yProps(0)} />
              <Tab label="Consumed Goods" {...a11yProps(1)} />
            </Tabs>
            <TextField
              size="small"
              id="search"
              type="search"
              label="Search"
              value={searchTerm}
              onChange={(e) => {
                setLoaded(false);
                setSearchTerm(e.target.value);
                if (e.target.value == "") {
                  setSort("desc");
                  setSortBy("updated_at");
                  if (value == 0) {
                    dispatch(
                      getAllOtherGoods("", "desc", "updated_at", 1, filters)
                    );
                  } else if (value == 1) {
                    dispatch(
                      getAllOtherGoodsConsumed(
                        "",
                        "desc",
                        "updated_at",
                        1,
                        filters
                      )
                    );
                  }
                }
              }}
              sx={{ width: 300, ml: "auto" }}
              InputProps={{
                endAdornment: (
                  <Div sx={{ cursor: "pointer" }}>
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  </Div>
                ),
              }}
            />
          </Box>
          <CustomTabPanel value={value} index={0}>
            {loaded ? (
              <OtherGoodsTable
                filter={filters}
                page={page}
                setPage={setPage}
                searchTerm={searchTerm}
                sort={sort}
                setSort={setSort}
                sortBy={sortBy}
                setSortBy={setSortBy}
              />
            ) : (
              <FullScreenLoader />
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {loaded ? (
              <ConsumedGoodsTable
                filter={filters}
                page={page}
                setPage={setPage}
                searchTerm={searchTerm}
                sort={sort}
                setSort={setSort}
                sortBy={sortBy}
                setSortBy={setSortBy}
              />
            ) : (
              <FullScreenLoader />
            )}
          </CustomTabPanel>
        </Box>
      </Div>
    </>
  );
}
