import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { MenuItem, Select, Button, Typography } from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import FormTextField1 from "app/components/InputField/FormTextField1";
import Div from "@jumbo/shared/Div";
import Swal from "sweetalert2";
import { editItemCategory } from "app/services/apis/editItemCategory";
import { Axios } from "index";

function EditCatgeoryPage() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [unitList, setUnitList] = useState([]);
  const [rowValues, setRowValues] = useState(state.rowData);
  console.log("state => ", state.rowData);
  const initialValues = {
    category: rowValues?.category || "",
    product_hsn_code: rowValues?.product_hsn_code || "",
    calculate_unit: rowValues?.calculate_unit || "",
  };
  const validationSchema = Yup.object({
    category: Yup.string().required("Category is required"),
    product_hsn_code: Yup.string()
      .required("HSN Code is required")
      .matches(/^[0-9]+$/, "HSN Code must be numeric")
      .min(6, "HSN Code must be at least 6 digits"),
    calculate_unit: Yup.string().required("Please select a unit"),
  });
  // const calculateUnitOptions = [
  //   { label: "Kilograms", value: "unit one" },
  //   { label: "Liters", value: "Unit two" },
  //   { label: "Units", value: "unit three" },
  // ];
  const onSubmit = async (values) => {
    console.log("form data => ", values);
    values.category = values.category?.toUpperCase();
    const response = await editItemCategory(values, rowValues._id);
    if (response?.data?.statusCode === 200) {
      Swal.fire({
        icon: "success",
        title: "Category updated Successfully",
        text: "",
        timer: 1000,
        showConfirmButton: false,
      });
      navigate("/master/item-category/list");
    } else {
      Swal.fire({
        icon: "error",
        title: response?.data?.message,
        text: "",
        timer: 1000,
        showConfirmButton: false,
      });
    }
  };
  // const handleCancelClick = () => {
  //   Swal.fire({
  //     icon: "success",
  //     title: "Supplier Added Successfully",
  //     text: "",
  //     timer: 1000,
  //     showConfirmButton: false,
  //   });
  // };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios.get("/unit-master/dropdown-unit-master");
        setUnitList(res.data.result);
      } catch (error) {
        console.log("err fetching all units => ", error.message);
      }
    };
    fetchData();
  }, []);
  return (
    <div>
      <Typography variant="h2">Add Category</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <div className="grid grid-flow-row lg:grid-cols-2  gap-6 mt-6">
              <FormTextField1
                name="category"
                label="Category*"
                value={rowValues?.category}
              />

              <div className="flex flex-col ">
                <Typography variant="h5">Calculate Unit*</Typography>
                <Select
                  fullWidth
                  name="calculate_unit"
                  value={
                    rowValues?.calculate_unit || values.calculate_unit || ""
                  }
                  size="small"
                  onChange={(e) => {
                    setFieldValue(e.target.name, e.target.value);
                  }}
                >
                  {unitList?.map((type) => {
                    return (
                      <MenuItem value={type.unit_name} key={type.unit_name}>
                        {type.unit_name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Div style={{ height: "30px" }}>
                  <ErrorMessage
                    name="calculate_unit"
                    component="div"
                    style={{ color: "red" }}
                  />
                </Div>
              </div>
              <FormTextField1
                name="product_hsn_code"
                label="Product HSN Code*"
              />
            </div>
            <div className="flex flex-row items-center justify-center gap-5 mt-8">
              <Button
                // type="submit"
                variant="contained"
                size="small"
                color="secondary"
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure you want to cancel?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/master/item-category/list");
                    }
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="small"
                color="primary"
              >
                Edit Category
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditCatgeoryPage;
