import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AllApis from "app/Apis";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function RejectGroup() {
  const [indexNo, setIndexNo] = useState(null);
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };

  const [rejectedBundles, setRejectedBundles] = useState({
    item_details: state?.item_details?.map((ele) => {
      return {
        item_rejected_pattas: ele?.item_available_pattas,
        group_sqm: 0,
        _id: ele?._id,
      };
    }),
    total_item_sqm_original: state?.total_item_sqm_original,
    group_no_of_pattas_original: state?.group_no_of_pattas_original,
    group_no_of_pattas_available: 0,
    total_item_sqm_available: 0,
    total_rejected_pattas: state?.group_no_of_pattas_available,
  });

  const handleOnChange = (e, indexNo, rowData) => {
    const { name, value } = e.target;
    let quantity = rejectedBundles?.item_details?.[indexNo]?._id
      ? rejectedBundles?.item_details?.[indexNo]
      : {
          item_rejected_pattas: 0,
          group_sqm: 0,
        };

    console.log(quantity, "quantity");

    let rejectedQty = {
      ...quantity,
      [name]: Number(value),
    };

    console.log(rejectedQty, "rejectedQty");

    rejectedQty.group_sqm = parseFloat(
      (
        (rowData?.item_length *
          rowData?.item_width *
          (rowData?.item_available_pattas - rejectedQty.item_rejected_pattas)) /
        10000
      ).toFixed(2)
    );

    const rejectedArr = [...rejectedBundles.item_details];
    rejectedArr[indexNo] = {
      ...rejectedQty,
      _id: rowData?._id,
    };

    const obj = {
      group_no_of_pattas_available: 0,
    };

    if (rejectedArr?.length > 0) {
      const updatedOtherDetails = rejectedArr.reduce((acc, ele) => {
        acc.group_no_of_pattas_available += ele?.item_rejected_pattas;
        return acc;
      }, obj);

      const totalAvlItem = state?.item_details?.map((ele, i) => {
        if (ele?._id !== rejectedArr?.[i]?._id) {
          return {
            rejectedQuantity: 0,
            totalavailableSqm: ele?.item_available_sqm,
          };
        } else {
          return {
            rejectedQuantity: rejectedArr?.[i]?.item_rejected_pattas,
            totalavailableSqm: rejectedArr?.[i]?.group_sqm,
          };
        }
      });

      const total = totalAvlItem.reduce(
        (acc, ele) => {
          acc.total_item_sqm_available += ele?.totalavailableSqm;
          acc.total_rejected_pattas += ele?.rejectedQuantity;
          return acc;
        },
        {
          total_item_sqm_available: 0,
          total_rejected_pattas: 0,
        }
      );

      setRejectedBundles({
        ...rejectedBundles,
        group_no_of_pattas_available:
          state?.group_no_of_pattas_available -
          updatedOtherDetails.group_no_of_pattas_available,
        total_item_sqm_available: parseFloat(
          total?.total_item_sqm_available?.toFixed(2)
        ),
        total_rejected_pattas: total?.total_rejected_pattas,
        item_details: rejectedArr,
      });
    }
  };

  const handleSubmit = async () => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const body = {
      _id: state?._id,
      ...rejectedBundles,
      item_details: rejectedBundles?.item_details.filter(
        (ele) => ele.item_rejected_pattas != 0
        // (ele) => ele["item_rejected_quantities"]["total"] != 0
      ),
    };
    if (body?.item_details.length == 0) {
      return Swal.fire({
        title: "Please Reject Pattas",
        icon: "warning",
        timer: 1000,
        showConfirmButton: false,
      });
    }
    try {
      setSubmitting(true);
      const response = await Axios.patch(
        AllApis.grouping.rejectGroupBundle,
        body,
        config
      );
      if (response?.data?.status) {
        Swal.fire({
          title: "Group Bundles Rejected Successfully",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("grouping");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };
  // useEffect(() => {
  //   const obj = {
  //     group_no_of_pattas_available: 0,
  //   };
  //   if (rejectedBundles?.["item_details"]?.length > 0) {
  //     const updatedOtherDetails = rejectedBundles?.["item_details"].reduce(
  //       (acc, ele, i) => {
  //         acc.group_no_of_pattas_available +=
  //           ele?.["item_rejected_quantities"]?.["total"];

  //         return acc;
  //       },
  //       obj
  //     );

  //     const totalAvlItem = state?.item_details?.map((ele, i) => {
  //       if (ele?._id !== rejectedBundles?.["item_details"]?.[i]?._id) {
  //         return {
  //           rejectedQuantity: 0,
  //           totalavailableSqm: ele?.item_available_sqm,
  //         };
  //       } else {
  //         return {
  //           rejectedQuantity:
  //             rejectedBundles?.["item_details"]?.[i]?.[
  //               "item_rejected_quantities"
  //             ].total,
  //           totalavailableSqm:
  //             rejectedBundles?.["item_details"]?.[i]?.group_sqm,
  //         };
  //       }
  //     });
  //     console.log(totalAvlItem, "{}{}{");
  //     const total = totalAvlItem.reduce(
  //       (acc, ele, i) => {
  //         acc.total_item_sqm_available += ele?.totalavailableSqm;
  //         acc.total_rejected_pattas += ele?.rejectedQuantity;
  //         return acc;
  //       },
  //       {
  //         total_item_sqm_available: 0,
  //         total_rejected_pattas: 0,
  //       }
  //     );
  //     setRejectedBundles({
  //       ...rejectedBundles,
  //       group_no_of_pattas_available:
  //         state?.group_no_of_pattas_available -
  //         updatedOtherDetails.group_no_of_pattas_available,
  //       total_item_sqm_available: parseFloat(
  //         total?.total_item_sqm_available?.toFixed(2)
  //       ),
  //       total_rejected_pattas: total?.total_rejected_pattas,
  //     });
  //   }
  // }, [open]);
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1" sx={{ mb: 4 }}>
        Reject Bundles
      </Typography>
      <Div>
        <TableContainer component={Paper} sx={{ mb: 1 }}>
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: "#7352C7",
                  color: "white",
                  "& .MuiTableCell-root": {
                    py: 2,
                  },
                }}
              >
                <TableCell sx={{ color: "white" }}>Group No.</TableCell>
                <TableCell sx={{ color: "white" }}>Item Name</TableCell>
                <TableCell sx={{ color: "white" }}>Item Type</TableCell>
                <TableCell sx={{ color: "white" }}>Group Pcs</TableCell>
                <TableCell sx={{ color: "white" }}>Grade</TableCell>
                <TableCell sx={{ color: "white" }}>Pallet No.</TableCell>
                <TableCell sx={{ color: "white" }}>Physical Location</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{state?.group_no}</TableCell>
                <TableCell>{state?.item_details?.[0]?.item_name}</TableCell>
                <TableCell>{state?.item_details?.[0]?.item_code}</TableCell>
                <TableCell>{state?.group_pcs}</TableCell>
                <TableCell>{state?.group_grade}</TableCell>
                <TableCell>{state?.group_pallete_no}</TableCell>
                <TableCell>{state?.group_physical_location}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer component={Paper}>
          <Div sx={{ width: "100%", maxHeight: "44vh" }}>
            <Table size="small">
              <TableHead sx={{ position: "sticky", top: 0 }}>
                <TableRow
                  sx={{
                    bgcolor: "#7352C7",
                    color: "white",
                    "& .MuiTableCell-root": {
                      py: 2,
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "40px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Sr No.
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "100px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Log No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "100px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Bundle No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "90px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Length (cm)
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "100px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Width (cm)
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "105px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Avl Pattas
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "100px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Avl Total Sq.m.
                  </TableCell>

                  {/* {state?.item_details?.[0]?.item_code?.toLowerCase() ==
                    "natural" && (
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        px: 1,
                        color: "white",
                      }}
                    >
                      Avl Natural
                    </TableCell>
                  )}
                  {state?.item_details?.[0]?.item_code?.toLowerCase() ==
                    "dyed" && (
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        px: 1,
                        color: "white",
                      }}
                    >
                      Avl Dyed
                    </TableCell>
                  )}
                  {state?.item_details?.[0]?.item_code?.toLowerCase() ==
                    "smoked" && (
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        px: 1,
                        color: "white",
                      }}
                    >
                      Avl Smoked
                    </TableCell>
                  )} */}
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "100px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Reject Pattas
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "150px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Supplier
                  </TableCell>
                  {/* <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "115px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                      position: "sticky",
                      right: 0,
                      height: "58px",
                      zIndex: 1,
                      bgcolor: "#7352C7",
                    }}
                  >
                    Reject Bundles
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {state?.item_details?.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {i + 1}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.item_log_no}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.item_bundle_no}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.item_length}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.item_width}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.item_available_pattas}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.item_available_sqm}
                    </TableCell>

                    {/* <TableCell sx={{ textAlign: "left", px: 1 }}>
                        {row?.item_available_pattas}
                      </TableCell> */}
                    {/* {row?.item_available_quantities?.natural > 0 && (
                      <TableCell sx={{ textAlign: "left", px: 1 }}>
                        {row?.item_available_quantities?.natural}
                      </TableCell>
                    )}
                    {row?.item_available_quantities?.dyed > 0 && (
                      <TableCell sx={{ textAlign: "left", px: 1 }}>
                        {row?.item_available_quantities?.dyed}
                      </TableCell>
                    )}
                    {row?.item_available_quantities?.smoked > 0 && (
                      <TableCell sx={{ textAlign: "left", px: 1 }}>
                        {row?.item_available_quantities?.smoked}
                      </TableCell>
                    )} */}
                    {row?.item_available_pattas > 0 ? 
                      (<TableCell sx={{ width: "10%", px: 1 }}>
                        {" "}
                        <TextField
                          size="small"
                          name="item_rejected_pattas"
                          type="number"
                          sx={{ width: "100%" }}
                          inputProps={{
                            min: 0,
                            max: row?.item_available_pattas,
                          }}
                          onKeyDown={(e) => {
                            const inputValue = parseInt(
                              e.target.value + e.key,
                              10
                            );

                            if (
                              isNaN(inputValue) ||
                              inputValue < 0 ||
                              inputValue > row?.item_available_pattas
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => handleOnChange(e, i, row)}
                          defaultValue={
                            rejectedBundles?.item_details?.[i]
                              ?.item_rejected_pattas
                          }
                        />
                      </TableCell>) : (<TableCell sx={{ width: "10%", px: 1 }}>0</TableCell>)
                    }
                    {/* {row?.item_available_quantities?.dyed > 0 && (
                      <TableCell sx={{ width: "10%", px: 1 }}>
                        {" "}
                        <TextField
                          size="small"
                          name="dyed"
                          type="number"
                          sx={{ width: "100%" }}
                          inputProps={{
                            min: 0,
                            max: row?.item_available_quantities?.dyed,
                          }}
                          onKeyDown={(e) => {
                            const inputValue = parseInt(
                              e.target.value + e.key,
                              10
                            );

                            if (
                              isNaN(inputValue) ||
                              inputValue < 0 ||
                              inputValue > row?.item_available_quantities?.dyed
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => handleOnChange(e, i, row)}
                          defaultValue={
                            rejectedBundles?.item_details?.[i]
                              ?.item_rejected_quantities?.dyed
                          }
                        />
                      </TableCell>
                    )}
                    {row?.item_available_quantities?.smoked > 0 && (
                      <TableCell sx={{ width: "10%", px: 1 }}>
                        {" "}
                        <TextField
                          size="small"
                          name="smoked"
                          type="number"
                          sx={{ width: "100%" }}
                          inputProps={{
                            min: 0,
                            max: row?.item_available_quantities?.smoked,
                          }}
                          onKeyDown={(e) => {
                            const inputValue = parseInt(
                              e.target.value + e.key,
                              10
                            );

                            if (
                              isNaN(inputValue) ||
                              inputValue < 0 ||
                              inputValue >
                                row?.item_available_quantities?.smoked
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => handleOnChange(e, i, row)}
                          defaultValue={
                            rejectedBundles?.item_details?.[i]
                              ?.item_rejected_quantities?.smoked
                          }
                        />
                      </TableCell>
                    )} */}

                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.supplier_details?.supplier_name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Div>
        </TableContainer>
        <Div sx={{ mt: 4, width: "100%" }}>
          <Div
            sx={{
              display: "flex",
              width: "100%",
              columnGap: 3,
              flexWrap: "wrap",
            }}
          >
            {/* <Div sx={{ width: "20%" }}>
              <Typography variant="h5">Group  (cm)</Typography>
              <TextField
                disabled
                sx={{ width: "100%", ...disabledStyling }}
                size="small"
                value={state?.group_length}
                name="group_length"
              />
            </Div>
            <Div sx={{ width: "20%" }}>
              <Typography variant="h5">Group Width</Typography>
              <TextField
                disabled
                sx={{ width: "100%", ...disabledStyling }}
                size="small"
                value={state?.group_width}
                name="group_width"
              />
            </Div> */}
            <Div sx={{ width: "20%" }}>
              <Typography variant="h5">Balance No. of Pattas</Typography>
              <TextField
                disabled
                sx={{ width: "100%", ...disabledStyling }}
                size="small"
                value={rejectedBundles?.group_no_of_pattas_available}
                name="group_no_of_pattas_original"
              />
            </Div>
            <Div sx={{ width: "20%" }}>
              <Typography variant="h5">Rejected No. of Pattas</Typography>
              <TextField
                sx={{ width: "100%", ...disabledStyling }}
                disabled
                size="small"
                value={rejectedBundles?.total_rejected_pattas}
              />
            </Div>
            <Div sx={{ width: "20%" }}>
              <Typography variant="h5">Total Item Sq.m.</Typography>
              <TextField
                sx={{ width: "100%", ...disabledStyling }}
                disabled
                size="small"
                value={rejectedBundles?.total_item_sqm_available}
                name="total_item_sqm_available"
              />
            </Div>
          </Div>
        </Div>
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
            mt: 5,
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              Swal.fire({
                title: "Are you sure you want to cancel?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("grouping");
                }
              });
            }}
          >
            Cancel
          </Button>

          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            onClick={handleSubmit}
          >
            Reject Group
          </LoadingButton>
        </Div>
      </Div>
      {/* <RejectGroupBundlesModal
        open={open}
        setOpen={setOpen}
        rowData={rowData}
        indexNo={indexNo}
        rejectedBundles={rejectedBundles}
        setRejectedBundles={setRejectedBundles}
      /> */}
    </Div>
  );
}
