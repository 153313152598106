import axios from "axios";

export const addGst = async (details) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.post(
      `${process.env.REACT_APP_URL}/gst-master/add-gst-master`,
      details,
      config
    );
    // console.log(data);
    return data;
  } catch (error) {
    // console.log(error);
    return error.response;
  }
};

export const updateGst = async (details, id) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.post(
      `${process.env.REACT_APP_URL}/gst-master/update-gst-master?id=${id}`,
      details,
      config
    );
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const gstDropdownList = async () => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/gst-master/dropdown-gst-master`,
      config
    );
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};

