import { Axios } from "index";

const addMachine = async (details) => {
    try {
        const res = await Axios.post('/machine-master/add-machine', details);
        return res
    } catch (error) {
        console.log("err add machine => ", error.message)
    }
};

export default addMachine;