import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useState } from "react";

import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { formatDate } from "app/components/Function/formatDate";
import FullScreenLoader from "app/components/ListingPageLoader";
import { getAllOtherGoods } from "app/redux/actions/inventoryAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AvailableQuantityModal from "./AvailableQuantityModal";

export default function OtherGoodsTable({
  page,
  setPage,
  searchTerm,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedRowData, setSelectedRowData] = useState({});
  const [availQuantityModal, setAvailQuantityModal] = useState(false);
  const { otherGoods, TotalPage, loading } = useSelector(
    (state) => state.inventoryReducer
  );

  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllOtherGoods(searchTerm, sort, sortBy, newPage, filter));
  };

  const handleItemAction = async (menuItem) => {
    switch (menuItem.action) {
      case "addConsumed":
        setSelectedRowData(menuItem.data);
        setAvailQuantityModal(true);
        break;
      case "editOtherGoods":
        navigate("inventory/othergoods/edit", {
          state: menuItem.data,
        });
        break;
    }
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                py: 2,
              },
            }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "300px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_name"}
                  direction={sort}
                  onClick={() => handleSort("item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  px: 1,
                  textAlign: "left",
                  minWidth: "165px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "received_quantity"}
                  direction={sort}
                  onClick={() => handleSort("received_quantity")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Received Quantity
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  px: 1,
                  textAlign: "left",
                  minWidth: "165px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "available_quantity"}
                  direction={sort}
                  onClick={() => handleSort("available_quantity")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Available Quantity
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  px: 1,
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "units"}
                  direction={sort}
                  onClick={() => handleSort("units")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Units
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  px: 1,
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "rate"}
                  direction={sort}
                  onClick={() => handleSort("rate")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Rate
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  px: 1,
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "supplier_details.supplier_name"}
                  direction={sort}
                  onClick={() => handleSort("supplier_details.supplier_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Supplier Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  px: 1,
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Date of Inward
              </TableCell>
              <TableCell
                sx={{
                  px: 1,
                  textAlign: "left",
                  minWidth: "180px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Remarks
              </TableCell>
              <TableCell
                sx={{
                  px: 1,
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "created_employee_id?.first_name"}
                  direction={sort}
                  onClick={() => handleSort("created_employee_id?.first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>
              {permissions?.other_goods_create == true && (
                <TableCell
                  sx={{
                    px: 1,
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {otherGoods?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.item_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.received_quantity}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.available_quantity}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.units}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.rate}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.supplier_details.supplier_name}
                </TableCell>
                <TableCell
                  sx={{
                    px: 1,
                    textAlign: "left",
                    minWidth: "100px",
                  }}
                >
                  {formatDate(row?.date_of_inward)}
                </TableCell>
                <TableCell
                  sx={{
                    px: 1,
                    textAlign: "left",
                    minWidth: "100px",
                  }}
                >
                  {row?.other_goods_remarks ? row?.other_goods_remarks : "-"}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.created_employee_id?.first_name}{" "}
                  {row?.created_employee_id?.last_name}
                </TableCell>

                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  <JumboDdMenu
                    icon={<MoreHorizIcon />}
                    menuItems={[
                      {
                        icon: <AddCircleIcon />,
                        title: "Add To Consumption",
                        action: "addConsumed",
                        data: row,
                        show: permissions?.other_goods_create,
                      },
                      {
                        icon: <EditIcon />,
                        title: "Edit",
                        action: "editOtherGoods",
                        data: row,
                        show:
                          permissions?.other_goods_edit &&
                          row?.received_quantity == row?.available_quantity,
                      },
                    ].filter((ele) => ele?.show == true)}
                    onClickCallback={handleItemAction}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <AvailableQuantityModal
          open={availQuantityModal}
          onClose={() => setAvailQuantityModal(false)}
          rowData={selectedRowData}
        />
        <Pagination size="medium"
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py:1
          }}
        />
      </TableContainer>
    </>
  );
}
