import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import {
  AddCircleOutlineOutlined,
  AddRounded,
  AirlineStops,
  DeleteOutlineOutlined,
  EditOutlined,
  MoreHoriz,
  RecentActors,
} from "@mui/icons-material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import FullScreenLoader from "app/components/ListingPageLoader";
import { getAllSuppliers } from "app/redux/actions/masterAction";
import { updateSupplier } from "app/services/apis/updateSupplier";
// import { displayDateFun } from "app/utils/constants/functions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
export default function ListSupplierTable({
  searchTerm,
  setPage,
  page,
  sort,
  sortBy,
  setSort,
  setSortBy,
  rowsPerPage,
}) {
  const { supplierMaster, TotalPage, loading } = useSelector(
    (state) => state.masterReducer
  );
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  const handleSort = (property) => {
    setSort(sort === "asc" ? "desc" : "asc");
    setSortBy(property);
    // setPage(1);
  };

  const handleItemAction = (menuItem) => {
    // eslint-disable-next-line default-case
    switch (menuItem.action) {
      case "edit":
        navigate(`/master/supplier/edit/${menuItem.id}`, {
          state: { rowData: menuItem.data },
        });
        break;
      case "branch":
        navigate(`/master/supplier/branch/list/${menuItem.data}`);
        break;
      case "delete":
        Swal.fire({
          title: `Are you sure you want to ${
            menuItem.data.status === "active" ? "Deactivate ?" : "Activate ?"
          }`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            handleStatusChange(menuItem.data);
          }
        });
        break;
      case "person":
        navigate("/master/supplier/contact-person-list", {
          state: { personData: menuItem.data },
        });
        break;
    }
  };
  const handleStatusChange = async (row) => {
    try {
      setLoader(true);
      const data = await updateSupplier(
        {
          status: row.status === "active" ? "inactive" : "active",
        },
        row._id
      );
      if (data?.status === 200) {
        dispatch(getAllSuppliers("", "desc", "updated_at", 1));
        Swal.fire({
          icon: "success",
          title: "Status Updated",
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setLoader(loading);
  }, [loading]);
  // const [menuState, setMenuState] = useState({
  //   anchorEl: null,
  //   currentEventId: null,
  // });

  // const handleMenuClick = (event, id) => {
  //   setMenuState({
  //     anchorEl: event.currentTarget,
  //     currentEventId: id,
  //   });
  //   // setEventId(id);
  //   console.log("Event ID set to: ", id);
  // };
  // const handleMenuClose = () => {
  //   setMenuState({
  //     anchorEl: null,
  //     currentEventId: null,
  //   });
  // };

  return (
    <>
      {loader && <FullScreenLoader />}
      {/* <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: "#7352C7",
                color: "white",
                "& .MuiTableCell-root": {},
              }}
            >
              <TableCell
                sx={{
                  color: "white",
                  textAlign: "left",
                  minWidth: "40px",
                  verticalAlign: "middle",
                  position: "sticky",
                  left: 0,
                  // height: "58px",
                  zIndex: 1,
                  bgcolor: "#7352C7",
                  padding: "0 10px",
                }}
              >
                Sr.No
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <TableSortLabel
                  active={sortBy === "supplierDetails.supplier_name"}
                  onClick={() => handleSort("supplierDetails.supplier_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Supplier Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <TableSortLabel
                  active={sortBy === "supplierDetails.supplier_type"}
                  onClick={() => handleSort("supplierDetails.supplier_type")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Supplier Type
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <TableSortLabel
                  active={sortBy === "contact_person.name"}
                  onClick={() => handleSort("contact_person.name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  {" "}
                  Contact Person Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                {" "}
                <TableSortLabel
                  active={sortBy === "contact_person.designation"}
                  onClick={() => handleSort("contact_person.designation")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  {" "}
                  Designation
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                {" "}
                <TableSortLabel
                  active={sortBy === "contact_person.mobile_number"}
                  onClick={() => handleSort("contact_person.mobile_number")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Mobile Number
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                Mobile{" "}
                <TableSortLabel
                  active={sortBy === "contact_person.email"}
                  onClick={() => handleSort("contact_person.email")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <TableSortLabel
                  active={sortBy === "address"}
                  onClick={() => handleSort("address")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Address
                </TableSortLabel>{" "}
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                {" "}
                <TableSortLabel
                  active={sortBy === "city"}
                  onClick={() => handleSort("city")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  City
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                {" "}
                <TableSortLabel
                  active={sortBy === "state"}
                  onClick={() => handleSort("state")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  State
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                {" "}
                <TableSortLabel
                  active={sortBy === "country"}
                  onClick={() => handleSort("country")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Country
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "40px",
                  verticalAlign: "middle",
                  color: "white",
                  position: "sticky",
                  right: 0,
                  // height: "58px",
                  zIndex: 1,
                  bgcolor: "#7352C7",
                  padding: "0 10px",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {console.log("sp => ", supplierMaster)}
            {supplierMaster?.length > 0 ? (
              supplierMaster?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "40px",
                      verticalAlign: "middle",

                      position: "sticky",
                      left: 0,

                      zIndex: 1,

                      padding: "0 10px",
                    }}
                  >
                    {row?.supplierDetails?.sr_no}
                  </TableCell>
                  <TableCell>{row?.supplierDetails?.supplier_name}</TableCell>
                  <TableCell>{row?.supplierDetails?.supplier_type}</TableCell>

                  <TableCell>{row?.contact_person?.[0]?.name}</TableCell>
                  <TableCell>{row?.contact_person?.[0]?.designation}</TableCell>
                  <TableCell>
                    {row?.contact_person?.[0]?.mobile_number}
                  </TableCell>
                  <TableCell>{row?.contact_person?.[0]?.email}</TableCell>

                  <TableCell>{row?.address}</TableCell>
                  <TableCell>{row?.city}</TableCell>
                  <TableCell>{row?.state}</TableCell>
                  <TableCell>{row?.country}</TableCell>

                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      position: "sticky",
                      right: 0,
                      zIndex: 1,
                      bgcolor: "white",
                    }}
                  >
                    <JumboDdMenu
                      icon={<MoreHorizIcon />}
                      menuItems={
                        permissions.user_edit === true
                          ? [
                              {
                                icon: <RecentActors />,
                                title: "View Contact Person details",
                                action: "person",
                                data: {
                                  personData: row,
                                  totalPages: TotalPage,
                                },
                              },
                              {
                                icon: <AirlineStops />,
                                title: "Manage Branch ",
                                action: "branch",
                                data: row.supplierDetails._id,
                              },
                              {
                                icon: <EditIcon />,
                                title: "Edit ",
                                action: "edit",
                                data: row,
                                id: row.supplier_id,
                              },

                              // {
                              //   icon: <DeleteOutlineOutlined />,
                              //   title: "Delete",
                              //   action: "delete",
                              //   data: row,
                              // },
                            ]
                          : [{ title: "No Actions" }]
                      }
                      onClickCallback={handleItemAction}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{
                  color: "white",
                  "& .MuiTableCell-root": {
                    py: 2,
                  },
                }}
              >
                <TableCell colSpan={10} align="center">
                  No Data Found...!!
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {TotalPage > 0 && (
          // <TablePagination
          //   component="div"
          //   count={TotalPage}
          //   page={page}
          //   onPageChange={handleChangePage}
          //   rowsPerPage={rowsPerPage}
          //   // onRowsPerPageChange={handleRowsPerPageChange}
          //   rowsPerPageOptions={""}
          // />
          <Pagination
            size="small"
            count={TotalPage || 1}
            page={page}
            onChange={handleChangePage}
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              backgroundColor: "white",
              borderTop: "1px solid #ddd",
              py: 1,
            }}
          />
        )}
      </TableContainer> */}

      {/* new table */}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: "#7352C7",
                color: "white",
                "& .MuiTableCell-root": {},
              }}
            >
              <TableCell
                sx={{
                  color: "white",
                  textAlign: "left",
                  minWidth: "40px",
                  verticalAlign: "middle",
                  position: "sticky",
                  left: 0,
                  // height: "58px",
                  zIndex: 1,
                  bgcolor: "#7352C7",
                  padding: "0 10px",
                }}
              >
                <TableSortLabel
                  active={sortBy === "sr_no"}
                  onClick={() => handleSort("sr_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Sr.No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <TableSortLabel
                  active={sortBy === "supplier_name"}
                  onClick={() => handleSort("supplier_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Supplier Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <TableSortLabel
                  active={sortBy === "supplier_type"}
                  onClick={() => handleSort("supplier_type")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Supplier Type
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <TableSortLabel
                  active={sortBy === "supplierDetails.contact_person.name"}
                  onClick={() =>
                    handleSort("supplierDetails.contact_person.name")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  {" "}
                  Contact Person Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                {" "}
                <TableSortLabel
                  active={
                    sortBy === "supplierDetails.contact_person.designation"
                  }
                  onClick={() =>
                    handleSort("supplierDetails.contact_person.designation")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  {" "}
                  Designation
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                {" "}
                <TableSortLabel
                  active={
                    sortBy === "supplierDetails.contact_person.mobile_number"
                  }
                  onClick={() =>
                    handleSort("supplierDetails.contact_person.mobile_number")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Mobile Number
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <TableSortLabel
                  active={sortBy === "supplierDetails.contact_person.email"}
                  onClick={() =>
                    handleSort("supplierDetails.contact_person.email")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <TableSortLabel
                  active={sortBy === "supplierDetails.address"}
                  onClick={() => handleSort("supplierDetails.address")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Address
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <TableSortLabel
                  active={sortBy === "supplierDetails.city"}
                  onClick={() => handleSort("supplierDetails.city")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  City
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <TableSortLabel
                  active={sortBy === "supplierDetails.state"}
                  onClick={() => handleSort("supplierDetails.state")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  State
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                {" "}
                <TableSortLabel
                  active={sortBy === "supplierDetails.country"}
                  onClick={() => handleSort("supplierDetails.country")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Country
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "40px",
                  verticalAlign: "middle",
                  color: "white",
                  position: "sticky",
                  right: 0,
                  // height: "58px",
                  zIndex: 1,
                  bgcolor: "#7352C7",
                  padding: "0 10px",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {supplierMaster?.length > 0 ? (
              supplierMaster.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "20px",
                      verticalAlign: "middle",

                      position: "sticky",
                      left: 0,

                      zIndex: 1,
                      bgcolor: "white",
                      padding: "0 10px",
                    }}
                  >
                    {row?.sr_no}
                  </TableCell>
                  <TableCell>{row?.supplier_name}</TableCell>
                  <TableCell>
                    {Array.isArray(row?.supplier_type) &&
                    row.supplier_type.length > 0
                      ? row.supplier_type.join(", ")
                      : row?.supplier_type}
                  </TableCell>

                  <TableCell>
                    {row?.supplierDetails[0]?.contact_person[0]?.name}
                  </TableCell>
                  <TableCell>
                    {row?.supplierDetails[0]?.contact_person[0]?.designation}
                  </TableCell>
                  <TableCell>
                    {row?.supplierDetails[0]?.contact_person[0]?.mobile_number}
                  </TableCell>
                  <TableCell>
                    {row?.supplierDetails[0]?.contact_person[0]?.email}
                  </TableCell>

                  <TableCell>{row?.supplierDetails[0]?.address}</TableCell>
                  <TableCell>{row?.supplierDetails[0]?.city}</TableCell>
                  <TableCell>{row?.supplierDetails[0]?.state}</TableCell>
                  <TableCell>{row?.supplierDetails[0]?.country}</TableCell>

                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      position: "sticky",
                      right: 0,
                      zIndex: 1,
                      bgcolor: "white",
                    }}
                  >
                    <JumboDdMenu
                      icon={<MoreHorizIcon />}
                      menuItems={
                        permissions.user_edit === true
                          ? [
                              {
                                icon: <RecentActors />,
                                title: "View Contact Person details",
                                action: "person",
                                data: {
                                  personData: row,
                                  totalPages: TotalPage,
                                },
                              },
                              {
                                icon: <AirlineStops />,
                                title: "Manage Branch ",
                                action: "branch",
                                data: row._id,
                              },
                              {
                                icon: <EditIcon />,
                                title: "Edit ",
                                action: "edit",
                                data: row,
                                id: row._id,
                              },

                              // {
                              //   icon: <DeleteOutlineOutlined />,
                              //   title: "Delete",
                              //   action: "delete",
                              //   data: row,
                              // },
                            ]
                          : [{ title: "No Actions" }]
                      }
                      onClickCallback={handleItemAction}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{
                  color: "white",
                  "& .MuiTableCell-root": {
                    py: 2,
                  },
                }}
              >
                <TableCell colSpan={10} align="center">
                  No Data Found...!!
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {TotalPage >= 0 && (
          <Pagination
            size="small"
            count={TotalPage || 1}
            page={page}
            onChange={handleChangePage}
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              backgroundColor: "white",
              borderTop: "1px solid #ddd",
              py: 1,
            }}
          />
        )}
      </TableContainer>
    </>
  );
}
