export const supplierTypes = [
  { id: 1, type: "STORE" },
  { id: 2, type: "VENEER" },
  { id: 3, type: "PLYWOOD" },
  { id: 4, type: "SERVICE PROVIDER" },
  { id: 5, type: "LOG" },
  { id: 6, type: "FLITCH" },
  { id: 7, type: "MDF" },
  { id: 8, type: "FACE" },
  { id: 9, type: "CORE" },
  { id: 10, type: "FLEECE PAPER" },
  { id: 11, type: "OTHER GOODS" },
];
