import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { Button, Grid, Typography } from "@mui/material";
import FormTextField1 from "app/components/InputField/FormTextField1";
import { addGst, updateGst } from "app/services/apis/gstApis";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

export default function AddGst() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state } = useLocation();
  const [isSubmitting, setSubmitting] = useState(false);
  const gst = {
    gst_percentage: state?.gst_percentage ? state?.gst_percentage : "",
    gst_remarks: state?.gst_remarks ? state?.gst_remarks : "",
  };
  const validationSchema = yup.object({
    gst_percentage: yup
      .number("Enter Gst Percentage.")
      .required("Gst Percentage is required."),
  });
  const onUserSave = async (values) => {
    const body = { ...values };
    
    setSubmitting(true);
    if (pathname == "/master/gst/edit") {
      const data = await updateGst(body, state._id);
      if (data?.data?.status == true) {
        Swal.fire({
          icon: "success",
          title: "Gst Edited Successfully",
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/master/gst");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.message,
          text: "",
        });
      }
    } else {
      const data = await addGst(body);
      if (data?.data?.status == true) {
        Swal.fire({
          icon: "success",
          title: "Gst Added Successfully",
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/master/gst");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname == "/master/gst/add" ? "Add New Gst" : "Edit Gst"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={gst}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ values }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    columnGap: 5,
                  }}
                >
                  <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={6} md={3}>
                      <FormTextField1 type="number" name="gst_percentage" label="Gst in Percentage*" />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <FormTextField1 name="gst_remarks" label="Remarks" />
                    </Grid>
                  </Grid>
                </Div>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/master/gst");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
