import Div from "@jumbo/shared/Div/Div";
import { Suspense, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import { getAllUsers } from "app/redux/actions/userAction";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ListUserTable from "./usertable";
import axios from "axios";
import { handleLogs } from "app/components/Function/logsDownloadFunction";
import { LoadingButton } from "@mui/lab";
import FilterAccordian from "app/components/FilterAccordian";
import { getAllRoles } from "app/redux/actions/roleAction";
import AllApis from "app/Apis";
import { Axios } from "index";

export default function ListUser() {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("updated_at");
  const [roles, setRoles] = useState([]);
  const [logLoader, setLogLoader] = useState(false);
  const [resetInputField, setResetInputField] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = useSelector((state) => state?.userReducer?.user?.[0]?.role_id?.permissions);
  const { allUsers, TotalPage, loading } = useSelector((state) => state.userReducer);
  const [filters, setFilters] = useState(null);

  //debouncing for search
  const handleSearch = (value) => {
    setPage(1);
    dispatch(getAllUsers(value, sort, sortBy, 1));
  };

  const handleFilter = () => {
    console.log(filters);
    sessionStorage.setItem("userMasterFilter", JSON.stringify({ page, filters }));
    setPage(1);
    dispatch(getAllUsers(searchTerm, sort, sortBy, 1, { role_id: filters._id }));
  };

  const handleClear = () => {
    setFilters(null);
    setResetInputField((prev) => !prev);
    setSearchTerm("");
    sessionStorage.removeItem("userMasterFilter");
    dispatch(getAllUsers("", sort, sortBy, page, {}));
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  useEffect(() => {
    dispatch(getAllUsers(searchTerm, sort, sortBy, page, { role_id: filters?._id }));
    (async () => {
      const rolesList = await Axios.get(`${AllApis.dropdownList.roles}`);
      setRoles(rolesList?.data?.result);
    })();
  }, [sort, page]);

  useEffect(() => {
    const allKeys = Object.keys(sessionStorage);
    const filterKeys = allKeys.filter((key) => key.includes("Filter"));

    console.log(filterKeys, "76666");

    let retrieveFilter = sessionStorage.getItem("userMasterFilter");
    if (retrieveFilter) {
      let parseFilterData = JSON.parse(retrieveFilter);
      setFilters(parseFilterData?.filters);
      setPage(parseFilterData?.page);
      dispatch(getAllUsers(searchTerm, sort, sortBy, parseFilterData?.page, { role_id: parseFilterData?.filters?._id }));
    }
  }, []);

  return (
    <>
      <Div sx={{ mt: -4 }}>
        <Typography variant="h1">User Management </Typography>
        <FilterAccordian
          actions={
            <Div>
              <Button variant="contained" size="small" sx={{ marginRight: 1 }} onClick={handleFilter}>
                Apply
              </Button>

              <Button variant="outlined" size="small" onClick={handleClear}>
                Clear
              </Button>
            </Div>
          }
        >
          <Box sx={{ display: "flex", rowGap: 4, columnGap: 4, flexWrap: "wrap" }}>
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                freeSolo
                key={filters}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="role-autocomplete"
                value={filters}
                options={roles || []}
                getOptionLabel={(option) => option?.role_name}
                onChange={(e, newValue) => {
                  console.log(e, newValue);
                  setFilters({ ...newValue });
                }}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.role_name}
                  </Box>
                )}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Role" />}
              />
            </Div>
          </Box>
        </FilterAccordian>
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px 0",
          }}
        >
          <TextField
            size="small"
            id="search"
            type="search"
            label="Search"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value == "") {
                setSort("desc");
                setSortBy("updated_at");
                dispatch(getAllUsers("", "desc", "updated_at", 1));
              }
            }}
            sx={{ width: 300 }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />
          <Div>
            {/* {permissions?.user_view == true && (
              <LoadingButton
                variant="contained"
                sx={{
                  mr: 2,
                  p: 1,
                  pl: 4,
                  pr: 4,
                }}
                onClick={() => handleLogs("user/user-logs", "users")}
              >
                Log
              </LoadingButton>
            )} */}

            {permissions?.user_create === true && (
              <Button variant="contained" size="small" onClick={() => navigate("/user/add")}>
                Add User
              </Button>
            )}
          </Div>
        </Div>
        <Suspense fallback={<div style={{ backgroundColor: "grey", border: "10px solid red" }}>Loading...</div>}>
          <ListUserTable
            searchTerm={searchTerm}
            page={page}
            setPage={setPage}
            sort={sort}
            sortBy={sortBy}
            setSort={setSort}
            setSortBy={setSortBy}
          />
        </Suspense>
      </Div>
    </>
  );
}
