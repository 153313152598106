import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormTextField1 from "app/components/InputField/FormTextField1";
import Div from "@jumbo/shared/Div";
import { addItemCategory } from "app/services/apis/addItemCategory";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Axios } from "index";

function AddItemPage() {
  const navigate = useNavigate();
  const [unitList, setUnitList] = useState([]);
  const initialValues = {
    category: "",
    product_hsn_code: "",
    calculate_unit: "",
  };
  const validationSchema = Yup.object({
    category: Yup.string().required("Category is required"),
    product_hsn_code: Yup.string()
      .required("HSN Code is required")
      .matches(/^[0-9]+$/, "HSN Code must be numeric")
      .min(6, "HSN Code must be at least 6 digits"),
    calculate_unit: Yup.string().required("Please select a unit"),
  });

  const onSubmit = async (values) => {
    console.log("Form Data:", values);
    values.category = values.category?.toUpperCase();
    const response = await addItemCategory(values);
    if (response.data?.statusCode === 200) {
      Swal.fire({
        icon: "success",
        title: "Category Added Successfully",
        text: "",
        timer: 1000,
        showConfirmButton: false,
      });
      navigate("/master/item-category/list");
    } else {
      Swal.fire({
        icon: "error",
        title: response?.data?.message,
        text: "",
        timer: 1000,
        showConfirmButton: false,
      });
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios.get("/unit-master/dropdown-unit-master");
        setUnitList(res.data.result);
      } catch (error) {
        console.log("err fetching all units => ", error.message);
      }
    };
    fetchData();
  }, []);
  return (
    <div>
      <Typography variant="h2">Add Category</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            {console.log(errors)}
            <div className="grid grid-flow-row lg:grid-cols-3  gap-6 mt-6">
              <FormTextField1 name="category" label="Category*" />

              <div className="flex flex-col ">
                <Typography variant="h5">Calculate Unit*</Typography>
                <Select
                  fullWidth
                  name="calculate_unit"
                  value={values.calculate_unit}
                  size="small"
                  onChange={(e) => {
                    setFieldValue(e.target.name, e.target.value);
                  }}
                >
                  {unitList?.map((type) => {
                    return (
                      <MenuItem value={type.unit_name} key={type.id}>
                        {type.unit_name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Div style={{ height: "30px" }}>
                  <ErrorMessage
                    name="calculate_unit"
                    component="div"
                    style={{ color: "red" }}
                  />
                </Div>
              </div>
              <FormTextField1
                name="product_hsn_code"
                label="Product HSN Code*"
              />
            </div>
            <div className="flex flex-row items-center justify-center gap-5 mt-8">
              <Button
                // type="submit"
                variant="contained"
                size="small"
                color="secondary"
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure you want to cancel?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/master/item-category/list");
                    }
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="small"
                color="primary"
              >
                Add Category
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddItemPage;
