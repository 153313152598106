export const LOAD_SUPPLIER_MASTER_REQUEST = "LOAD_SUPPLIER_MASTER_REQUEST";
export const LOAD_SUPPLIER_MASTER_SUCCESS = "LOAD_SUPPLIER_MASTER_SUCCESS";
export const LOAD_SUPPLIER_MASTER_FAIL = "LOAD_SUPPLIER_MASTER_FAIL";

export const ALL_SUPPLIER_MASTER_REQUEST = "ALL_SUPPLIER_MASTER_REQUEST";
export const ALL_SUPPLIER_MASTER_SUCCESS = "ALL_SUPPLIER_MASTER_SUCCESS";
export const ALL_SUPPLIER_MASTER_FAIL = "ALL_SUPPLIER_MASTER_FAIL";

export const LOAD_COSTING_MASTER_REQUEST = "LOAD_COSTING_MASTER_REQUEST";
export const LOAD_COSTING_MASTER_SUCCESS = "LOAD_COSTING_MASTER_SUCCESS";
export const LOAD_COSTING_MASTER_FAIL = "LOAD_COSTING_MASTER_FAIL";

export const ALL_COSTING_MASTER_REQUEST = "ALL_COSTING_MASTER_REQUEST";
export const ALL_COSTING_MASTER_SUCCESS = "ALL_COSTING_MASTER_SUCCESS";
export const ALL_COSTING_MASTER_FAIL = "ALL_COSTING_MASTER_FAIL";

export const LOAD_PRODUCT_MASTER_REQUEST = "LOAD_PRODUCT_MASTER_REQUEST";
export const LOAD_PRODUCT_MASTER_SUCCESS = "LOAD_PRODUCT_MASTER_SUCCESS";
export const LOAD_PRODUCT_MASTER_FAIL = "LOAD_PRODUCT_MASTER_FAIL";

export const ALL_PRODUCT_MASTER_REQUEST = "ALL_PRODUCT_MASTER_REQUEST";
export const ALL_PRODUCT_MASTER_SUCCESS = "ALL_PRODUCT_MASTER_SUCCESS";
export const ALL_PRODUCT_MASTER_FAIL = "ALL_PRODUCT_MASTER_FAIL";

//grade master
export const ALL_GRADE_MASTER_REQUEST = "ALL_GRADE_MASTER_REQUEST";
export const ALL_GRADE_MASTER_SUCCESS = "ALL_GRADE_MASTER_SUCCESS";
export const ALL_GRADE_MASTER_FAIL = "ALL_GRADE_MASTER_FAIL";

//currency master
export const ALL_CURRENCY_MASTER_REQUEST = "ALL_CURRENCY_MASTER_REQUEST";
export const ALL_CURRENCY_MASTER_SUCCESS = "ALL_CURRENCY_MASTER_SUCCESS";
export const ALL_CURRENCY_MASTER_FAIL = "ALL_CURRENCY_MASTER_FAIL";

//currency master
export const ALL_CUT_MASTER_REQUEST = "ALL_CUT_MASTER_REQUEST";
export const ALL_CUT_MASTER_SUCCESS = "ALL_CUT_MASTER_SUCCESS";
export const ALL_CUT_MASTER_FAIL = "ALL_CUT_MASTER_FAIL";

//gst master
export const ALL_GST_MASTER_REQUEST = "ALL_GST_MASTER_REQUEST";
export const ALL_GST_MASTER_SUCCESS = "ALL_GST_MASTER_SUCCESS";
export const ALL_GST_MASTER_FAIL = "ALL_GST_MASTER_FAIL";

//expense type master
export const ALL_EXPENSE_TYPE_MASTER_REQUEST = "ALL_EXPENSE_TYPE_MASTER_REQUEST";
export const ALL_EXPENSE_TYPE_MASTER_SUCCESS = "ALL_EXPENSE_TYPE_MASTER_SUCCESS";
export const ALL_EXPENSE_TYPE_MASTER_FAIL = "ALL_EXPENSE_TYPE_MASTER_FAIL";

//pallete master
export const ALL_PALLETE_MASTER_REQUEST = "ALL_PALLETE_MASTER_REQUEST";
export const ALL_PALLETE_MASTER_SUCCESS = "ALL_PALLETE_MASTER_SUCCESS";
export const ALL_PALLETE_MASTER_FAIL = "ALL_PALLETE_MASTER_FAIL";

//fabricColor master
export const ALL_FABRIC_COLOR_MASTER_REQUEST = "ALL_FABRIC_COLOR_MASTER_REQUEST";
export const ALL_FABRIC_COLOR_MASTER_SUCCESS = "ALL_FABRIC_COLOR_MASTER_SUCCESS";
export const ALL_FABRIC_COLOR_MASTER_FAIL = "ALL_FABRIC_COLOR_MASTER_FAIL";

//party master
export const ALL_PARTY_MASTER_REQUEST = "ALL_PARTY_MASTER_REQUEST";
export const ALL_PARTY_MASTER_SUCCESS = "ALL_PARTY_MASTER_SUCCESS";
export const ALL_PARTY_MASTER_FAIL = "ALL_PARTY_MASTER_FAIL";

//unit master
export const ALL_UNIT_MASTER_REQUEST = "ALL_UNIT_MASTER_REQUEST";
export const ALL_UNIT_MASTER_SUCCESS = "ALL_UNIT_MASTER_SUCCESS";
export const ALL_UNIT_MASTER_FAIL = "ALL_UNIT_MASTER_FAIL";

// item name master
export const ALL_ITEM_NAME_MASTER_REQUEST = "ALL_ITEM_NAME_MASTER_REQUEST";
export const ALL_ITEM_NAME_MASTER_SUCCESS = "ALL_ITEM_NAME_MASTER_SUCCESS";
export const ALL_ITEM_NAME_MASTER_FAIL = "ALL_ITEM_NAME_MASTER_FAIL";

// item Type master
export const ALL_ITEM_CODE_MASTER_REQUEST = "ALL_ITEM_CODE_MASTER_REQUEST";
export const ALL_ITEM_CODE_MASTER_SUCCESS = "ALL_ITEM_CODE_MASTER_SUCCESS";
export const ALL_ITEM_CODE_MASTER_FAIL = "ALL_ITEM_CODE_MASTER_FAIL";

//fabric master
export const ALL_FABRIC_MASTER_REQUEST = "ALL_FABRIC_MASTER_REQUEST";
export const ALL_FABRIC_MASTER_SUCCESS = "ALL_FABRIC_MASTER_SUCCESS";
export const ALL_FABRIC_MASTER_FAIL = "ALL_FABRIC_MASTER_FAIL";

//new supplier master

export const FETCH_ALL_SUPPLIER_MASTER_LIST_REQUEST = "LIST_SUPPLIERS_MASTER_REQUEST";
export const FETCH_ALL_SUPPLIER_MASTER_LIST_SUCCESS = "FETCH_ALL_SUPPLIER_MASTER_LIST_SUCCESS";
export const FETCH_ALL_SUPPLIER_MASTER_FAIL = "FETCH_ALL_SUPPLIER_MASTER_FAIL";

//suplier branches
export const FETCH_ALL_SUPPLIER_MASTER_BRANCHES_LIST_REQUEST = "LIST_SUPPLIERS_MASTER_REQUEST";
export const FETCH_ALL_SUPPLIER_MASTER_BRANCHES_LIST_SUCCESS = "FETCH_ALL_SUPPLIER_MASTER_LIST_SUCCESS";
export const FETCH_ALL_SUPPLIER_MASTER_BRANCHES__FAIL = "FETCH_ALL_SUPPLIER_MASTER_FAIL"

//item category 
export const FETCH_ALL_ITEMS_CATEGORY_REQUEST = "FETCH_ALL_ITEMS_CATEGORY_REQUEST";
export const FETCH_ALL_ITEMS_CATEGORY_SUCCESS = "FETCH_ALL_ITEMS_CATEGORY_SUCCESS";
export const FETCH_ALL_ITEMS_CATEGORY_FAIL = "FETCH_ALL_ITEMS_CATEGORY_FAIL"

//item subcategory 
export const FETCH_ALL_ITEMS_SUBCATEGORY_REQUEST = "FETCH_ALL_ITEMS_SUBCATEGORY_REQUEST";
export const FETCH_ALL_ITEMS_SUBCATEGORY_SUCCESS = "FETCH_ALL_ITEMS_SUBCATEGORY_SUCCESS";
export const FETCH_ALL_ITEMS_SUBCATEGORY_FAIL = "FETCH_ALL_ITEMS_SUBCATEGORY_FAIL"

//series -master 
export const FETCH_ALL_SERIES_REQUEST = "FETCH_ALL_SERIES_REQUEST";
export const FETCH_ALL_SERIES_SUCCESS = "FETCH_ALL_SERIES_SUCCESS";
export const FETCH_ALL_SERIES_FAIL = "FETCH_ALL_SERIES_FAIL"

//DEPARTMENT -master 
export const FETCH_ALL_DEPARTMENT_REQUEST = "FETCH_ALL_DEPARTMENT_REQUEST";
export const FETCH_ALL_DEPARTMENT_SUCCESS = "FETCH_ALL_DEPARTMENT_SUCCESS";
export const FETCH_ALL_DEPARTMENT_FAIL = "FETCH_ALL_DEPARTMENT_FAIL"

//MACHINE -master 
export const FETCH_ALL_MACHINE_REQUEST = "FETCH_ALL_MACHINE_REQUEST";
export const FETCH_ALL_MACHINE_SUCCESS = "FETCH_ALL_MACHINE_SUCCESS";
export const FETCH_ALL_MACHINE_FAIL = "FETCH_ALL_MACHINE_FAIL"