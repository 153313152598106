import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import CategoryIcon from "@mui/icons-material/Category";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PeopleIcon from "@mui/icons-material/People";
import ScaleIcon from "@mui/icons-material/Scale";
import FactoryIcon from "@mui/icons-material/Factory";
import InventoryIcon from "@mui/icons-material/Inventory";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import DashboardIcon from "@mui/icons-material/Dashboard";

const menus = [
  {
    uri: "/dashboard",
    label: "sidebar.menuItem.dashboard",
    type: "nav-item",
    icon: <DashboardIcon sx={{ fontSize: 20 }} />,
  },
  {
    uri: "/user",
    isActiveUri: ["/user/add", "/user/edit"],
    label: "sidebar.menuItem.userManagement",
    type: "nav-item",
    permission: "user_view",
    icon: <PeopleIcon sx={{ fontSize: 20 }} />,
  },
  {
    uri: "/roles",
    isActiveUri: ["/role/edit", "/role/add"],
    label: "sidebar.menuItem.roleAndPermission",
    type: "nav-item",
    permission: "role_view",
    icon: <LockPersonOutlinedIcon sx={{ fontSize: 20 }} />,
  },
  {
    label: "sidebar.menu.master",
    type: "collapsible",
    icon: <StarBorderPurple500Icon sx={{ fontSize: 20 }} />,
    children: [
      {
        uri: "/master/supplier",
        label: "sidebar.menuItem.master.supplier",
        isActiveUri: ["/supplier/add", "/master/category/edit", "/master/supplier/branch/add/:id", "/master/supplier/branch/list/:id", "/master/supplier/edit/:id", "/master/supplier/contact-person-list"],
        permission: "supplier_master_view",
        type: "nav-item",
        icon: <CategoryIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/master/item-category/list",
        label: "Item Category",
        isActiveUri: ["/master/item-category/add", "/master/item-category/edit"],
        permission: "item_category_master_view",
        type: "nav-item",
        icon: <ScaleIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/master/item-subcategory/list",
        label: "Item Sub-Category",
        isActiveUri: ["/master/item-subcategory/add", "/master/item-subcategory/edit"],
        permission: "item_sub_category_master_view",
        type: "nav-item",
        icon: <ScaleIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/master/item-name",
        label: "sidebar.menuItem.master.itemName",
        isActiveUri: ["/master/item-name/add", "/master/item-name/edit"],
        permission: "item_name_master_view",
        type: "nav-item",
        icon: <DescriptionOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/master/unit",
        label: "Unit",
        isActiveUri: ["/master/unit/add", "/master/unit/edit"],
        permission: "unit_master_view",
        type: "nav-item",
        icon: <MonetizationOnIcon sx={{ fontSize: 20 }} />,
      },
      // {
      //   uri: "/master/item-code",
      //   label: "sidebar.menuItem.master.itemType",
      //   isActiveUri: [
      //     "/master/item-code/add",
      //     "/master/item-code/edit",
      //   ],
      //   permission:"item_code_master_view",
      //   type: "nav-item",
      //   icon: <LocalOfferOutlinedIcon sx={{ fontSize: 20 }} />,
      // },
      {
        uri: "/master/grade",
        label: "sidebar.menuItem.master.grade",
        isActiveUri: ["/master/grade/add", "/master/grade/edit"],
        permission: "grade_master_view",
        type: "nav-item",
        icon: <LocalOfferOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/master/currency",
        label: "Currency",
        isActiveUri: ["/master/currency/add", "/master/currency/edit"],
        permission: "currency_master_view",
        type: "nav-item",
        icon: <LocalOfferOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/master/cut",
        label: "Cut",
        isActiveUri: ["/master/cut/add", "/master/cut/edit"],
        permission: "cut_master_view",
        type: "nav-item",
        icon: <LocalOfferOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/master/series",
        label: "Series",
        isActiveUri: ["/master/series/add", "/master/series/edit"],
        permission: "series_master_view",
        type: "nav-item",
        icon: <LocalOfferOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/master/expenseType",
        label: "Expense Type",
        isActiveUri: ["/master/expenseType/add", "/master/expenseType/edit"],
        permission: "expense_type_master_view",
        type: "nav-item",
        icon: <LocalOfferOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/master/expense",
        label: "Expense",
        isActiveUri: ["/master/expense/add", "/master/expense/edit"],
        permission: "expense_master_view",
        type: "nav-item",
        icon: <LocalOfferOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/master/gst",
        label: "GST",
        isActiveUri: ["/master/gst/add", "/master/gst/edit"],
        permission: "gst_master_view",
        type: "nav-item",
        icon: <LocalOfferOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/master/department",
        label: "Department",
        isActiveUri: ["/master/department/add", "/master/department/edit"],
        permission: "department_master_view",
        type: "nav-item",
        icon: <LocalOfferOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/master/machine",
        label: "Machine",
        isActiveUri: ["/master/machine/add", "/master/machine/edit"],
        permission: "machine_master_view",
        type: "nav-item",
        icon: <LocalOfferOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: "sidebar.menu.inventory",
    type: "collapsible",
    icon: <InventoryIcon sx={{ fontSize: 20 }} />,
    children: [
      {
        uri: "/inventory/log",
        label: "Log",
        isActiveUri: ["/inventory/log/add", "/inventory/log/edit"],
        type: "nav-item",
        permission: "log_inventory_view",
        icon: <CategoryIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/inventory/flitch",
        label: "Flitch",
        isActiveUri: ["/inventory/flitch/add", "/inventory/flitch/edit"],
        type: "nav-item",
        permission: "flitch_inventory_view",
        icon: <CategoryIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/inventory/plywood",
        label: "Plywood",
        isActiveUri: ["/inventory/plywood/add", "/inventory/plywood/edit"],
        type: "nav-item",
        permission: "plywood_inventory_view",
        icon: <CategoryIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/inventory/veneer",
        label: "Veneer",
        isActiveUri: ["/inventory/veneer/add", "/inventory/veneer/edit"],
        type: "nav-item",
        permission: "veneer_inventory_view",
        icon: <CategoryIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/inventory/mdf",
        label: "MDF",
        isActiveUri: ["/inventory/mdf/add", "/inventory/mdf/edit"],
        type: "nav-item",
        permission: "mdf_inventory_view",
        icon: <CategoryIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/inventory/face",
        label: "Face",
        isActiveUri: ["/inventory/face/add", "/inventory/face/edit"],
        type: "nav-item",
        permission: "face_inventory_view",
        icon: <CategoryIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/inventory/core",
        label: "Core",
        isActiveUri: ["/inventory/core/add", "/inventory/core/edit"],
        type: "nav-item",
        permission: "core_inventory_view",
        icon: <CategoryIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/inventory/fleece-paper",
        label: "Fleece Paper",
        isActiveUri: ["/inventory/fleece-paper/add", "/inventory/fleece-paper/edit"],
        type: "nav-item",
        permission: "fleece_paper_inventory_view",
        icon: <CategoryIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/inventory/other-goods",
        label: "Other Goods",
        isActiveUri: ["/inventory/other-goods/add", "/inventory/other-goods/edit"],
        type: "nav-item",
        permission: "other_goods_inventory_view",
        icon: <ScaleIcon sx={{ fontSize: 20 }} />,
      },
      // {
      //   uri: "inventory/groupedveneer",
      //   label: "sidebar.menuItem.inventory.groupedVeneer",
      //   isActiveUri: [],
      //   type: "nav-item",
      //   permission:"grouped_veneer_view",
      //   icon: <MonetizationOnIcon sx={{ fontSize: 20 }} />,
      // },
      // {
      //   uri: "inventory/readysheetform",
      //   label: "sidebar.menuItem.inventory.readySheetForm",
      //   isActiveUri: [],
      //   type: "nav-item",
      //   permission:"ready_sheet_form_view",
      //   icon: <DescriptionOutlinedIcon sx={{ fontSize: 20 }} />,
      // },
      // {
      //   uri: "inventory/readyForDispatch",
      //   label: "sidebar.menuItem.inventory.readyForDispatch",
      //   isActiveUri: [],
      //   type: "nav-item",
      //   permission:"qc_view",
      //   icon: <LocalOfferOutlinedIcon sx={{ fontSize: 20 }} />,
      // },
    ],
  },
  {
    label: "sidebar.menu.factory",
    type: "collapsible",
    icon: <FactoryIcon sx={{ fontSize: 20 }} />,
    children: [
      {
        uri: "/factory/crosscut",
        label: "Crosscut",
        isActiveUri: [
          "/factory/crosscut/add",
          "/factory/crosscut/edit",
        ],
        type: "nav-item",
        permission: "crosscut_factory_view",
        icon: <CategoryIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/factory/flitching",
        label: "Flitching",
        isActiveUri: [
          "/factory/flitching/add",
          "/factory/flitching/edit",
        ],
        type: "nav-item",
        permission: "flitching_factory_view",
        icon: <CategoryIcon sx={{ fontSize: 20 }} />,
      },
      // {
      //   uri: "grouping",
      //   label: "sidebar.menuItem.factory.grouping",
      //   isActiveUri: [],
      //   type: "nav-item",
      //   permission:"grouping_view",
      //   icon: <CategoryIcon sx={{ fontSize: 20 }} />,
      // },
      // {
      //   uri: "factory/smoking",
      //   label: "sidebar.menuItem.factory.smoking",
      //   isActiveUri: [],
      //   type: "nav-item",
      //   permission:"smoking_view",
      //   icon: <ScaleIcon sx={{ fontSize: 20 }} />,
      // },
      // {
      //   uri: "factory/dying",
      //   label: "sidebar.menuItem.factory.dying",
      //   isActiveUri: [],
      //   type: "nav-item",
      //   permission:"dying_view",
      //   icon: <MonetizationOnIcon sx={{ fontSize: 20 }} />,
      // },
      // {
      //   uri: "factory/cutting",
      //   label: "sidebar.menuItem.factory.cutting",
      //   isActiveUri: [],
      //   type: "nav-item",
      //   permission:"cutting_view",
      //   icon: <DescriptionOutlinedIcon sx={{ fontSize: 20 }} />,
      // },
      // {
      //   uri: "factory/tapping",
      //   label: "sidebar.menuItem.factory.tapping",
      //   isActiveUri: [],
      //   type: "nav-item",
      //   permission:"tapping_view",
      //   icon: <LocalOfferOutlinedIcon sx={{ fontSize: 20 }} />,
      // },
      // {
      //   uri: "factory/pressing",
      //   label: "sidebar.menuItem.factory.pressing",
      //   isActiveUri: [],
      //   type: "nav-item",
      //   permission:"pressing_view",
      //   icon: <LocalOfferOutlinedIcon sx={{ fontSize: 20 }} />,
      // },
      // {
      //   uri: "factory/finishing",
      //   label: "sidebar.menuItem.factory.finishing",
      //   isActiveUri: [],
      //   type: "nav-item",
      //   permission:"finishing_view",
      //   icon: <LocalOfferOutlinedIcon sx={{ fontSize: 20 }} />,
      // },
    ],
  },
  // {
  //   label: "sidebar.menu.factory",
  //   type: "section",
  //   children: [
  //   ],
  // },
  // {
  //   uri: "orders",
  //   label: "sidebar.menuItem.orders",
  //   type: "nav-item",
  //   permission: "orders_view",
  //   icon: <AssignmentOutlinedIcon sx={{ fontSize: 20 }} />,
  // },
  // {
  //   uri: "dispatch",
  //   label: "sidebar.menuItem.dispatch",
  //   type: "nav-item",
  //   permission: "dispatch_view",
  //   icon: <LocalShippingOutlinedIcon sx={{ fontSize: 20 }} />,
  // },
  // {
  //   label: "sidebar.menu.orders&dispatch",
  //   type: "section",
  //   children: [
  //   ],
  // },
];

export default menus;
