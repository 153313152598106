import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Button, Grid, MenuItem, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { supplierDropDownList } from "app/services/apis/supplierList";
import { City, Country, State } from "country-state-city";
import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import AllApis from "app/Apis";
import { getCurrentDate } from "app/components/Function/getCurrentDate";
import { Axios } from "index";
import FullScreenLoader from "app/components/ListingPageLoader";
import ItemDetailsTable from "./itemTable";

export default function AddFlitchStock() {
  const { state, pathname } = useLocation();
  const [countryList, setCountryList] = useState(Country.getAllCountries());
  const [currencyList, setCurrencyList] = useState(["INR", "USD", "EUR"]);
  const [itemNameList, setItemNameList] = useState([]);
  const [itemCodeList, setItemCodeList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [palleteList, setPalleteList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [loader, setLoader] = useState({
    addProduct: false,
    removeProduct: false,
  });
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [rowData, setRowData] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [addedItemDetailsList, setAddedItemDetailsList] = useState([]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && (
          <Box sx={{ pt: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const stock = {
    date_of_inward: getCurrentDate(),
    invoice_no: "",
    currency: "INR",
    supplier_details: {
      supplier_name: "",
      // country: "",
      // state: "",
      // city: "",
      // pincode: "",
      // bill_address: "",
      // delivery_address: "",
      // contact_Person_name: "",
      // contact_Person_number: "",
      // country_code: "",
      // email_id: "",
      // pan_no: "",
      // gst_no: "",
    },
    // item_details: [
    //   {
    //     item_name: "",
    //     item_code: "",
    //     item_log_no: "",
    //     item_bundle_no: "",
    //     item_length: "",
    //     item_width: "",
    //     // item_received_quantities: {
    //     //   natural: "",
    //     //   dyed: "",
    //     //   smoked: "",
    //     //   total: "",
    //     // },
    //     item_received_pattas: "",
    //     item_received_sqm: "",
    //     // item_available_quantities: {
    //     //   natural: "",
    //     //   dyed: "",
    //     //   smoked: "",
    //     //   total: "",
    //     // },
    //     item_available_pattas: "",
    //     item_available_sqm: "",
    //     // item_rejected_quantities: {
    //     //   natural: "",
    //     //   dyed: "",
    //     //   smoked: "",
    //     //   total: "",
    //     // },
    //     item_rejected_pattas: 0,
    //     item_rejected_sqm: 0,
    //     item_pallete_no: "",
    //     item_physical_location: "",
    //     item_grade: "",
    //     item_rate_per_sqm: "",
    //     item_remark: "",
    //     conversion_rate: 0,
    //     item_rate_per_sqm_for_currency: 0,
    //     gst: 0,
    //     igst: 0,
    //     cgst: 0,
    //     sgst: 0,
    //   },
    // ],
  };

  const validationSchema = yup.object({
    date_of_inward: yup.date().required("Date of Inward is required"),
    currency: yup.string().required("Currency is required").nullable(),
    invoice_no: yup.string("Enter Invoice No.").required("Invoice No. is required"),
    supplier_details: yup.object({
      supplier_name: yup.string("Enter Supplier Name").required("Supplier Name is required"),
    }),
    // item_details: yup.array().of(
    //   yup.object().shape({
    //     item_name: yup.string().required("Required"),
    //     item_code: yup.string().required("Required"),
    //     item_log_no: yup
    //       .string()
    //       .matches(/^[a-zA-Z0-9/]*$/, "Item log number cannot contain symbols except /")
    //       .required("Required"),
    //     item_bundle_no: yup.string().required("Required"),
    //     item_length: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    //     item_width: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    //     item_received_pattas: yup.number().positive().required("Required"),
    //     item_received_sqm: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    //     item_available_pattas: yup.number().positive().required("Required"),
    //     item_available_sqm: yup.number().positive().required("Required"),
    //     item_rejected_pattas: yup.number(),
    //     item_rejected_sqm: yup.number(),
    //     item_pallete_no: yup.string().required("Required"),
    //     item_physical_location: yup.string().required("Required"),
    //     item_grade: yup.string().required("Required"),
    //     item_rate_per_sqm: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    //     conversion_rate: yup
    //       .number()
    //       .when("$currency", (currency, schema) =>
    //         currency !== "INR" ? schema.positive().moreThan(0, "Must be greater than 0").required("Required.") : schema.notRequired()
    //       ),
    //     item_rate_per_sqm_for_currency: yup
    //       .number()
    //       .when("$currency", (currency, schema) =>
    //         currency !== "INR" ? schema.positive().moreThan(0, "Must be greater than 0").required("Required.") : schema.notRequired()
    //       ),
    //     gst: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    //     // item_received_quantities: yup
    //     //   .object()
    //     //   .shape({
    //     //     natural: yup.number().min(0),
    //     //     dyed: yup.number().min(0),
    //     //     smoked: yup.number().min(0),
    //     //     total: yup.number().min(0),
    //     //   })
    //     //   .test(
    //     //     "only-one-nonzero",
    //     //     "Only one field should have a value greater than 0",
    //     //     function (values) {
    //     //       const { natural, dyed, smoked } = values;
    //     //       const countNonZero = [natural, dyed, smoked].filter(
    //     //         (value) => value > 0
    //     //       ).length;
    //     //       return countNonZero === 1;
    //     //     }
    //     //   ),
    //     // item_rejected_quantities: yup.object().shape({
    //     //   natural: yup.number().min(0),
    //     //   dyed: yup.number().min(0),
    //     //   smoked: yup.number().min(0),
    //     //   total: yup.number().min(0),
    //     // }),
    //   })
    // ),
  });

  const handleSubmit = async (values) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    try {
      setSubmitting(true);
      const response = await Axios.post(AllApis.raw_material.addInventory, values, config);
      if (response?.data?.status) {
        Swal.fire({
          title: "Inventory Added",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("inventory/rawmaterial");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        showConfirmButton: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const filterStates = (countryIsoCode) => {
    // Use your logic to filter states based on the selected country.
    const filteredStates = State.getAllStates().filter((state) => state.countryCode === countryIsoCode);
    setStateList(filteredStates);
  };

  const filterCities = (stateIsoCode, countryCode) => {
    // Use your logic to filter cities based on the selected state.
    const filteredCities = City.getAllCities().filter((city) => city.stateCode === stateIsoCode && city.countryCode == countryCode);
    setCityList(filteredCities);
  };

  const handleOnChange = (e, i, setFieldValue, values, name, value) => {
    let latestArray = [...values.item_details];
    if (name == "item_pallete_no") {
      latestArray[i] = {
        ...latestArray[i],
        [name]: value,
      };
      let location = palleteList.filter((pal) => pal.pallete_no == value);
      latestArray[i] = {
        ...latestArray[i],
        item_physical_location: location[0]?.item_physical_location,
      };
    }
    if (name == "item_length" || name == "item_width") {
      const sqm =
        ((name === "item_length" ? Number(value) : Number(values?.item_details?.[i]?.item_length || 0)) *
          (name === "item_width" ? Number(value) : Number(values?.item_details?.[i]?.item_width || 0)) *
          (Number(values?.item_details?.[i]?.item_received_pattas) || 0)) /
        10000;

      latestArray[i] = {
        ...latestArray[i],
        item_received_sqm: sqm?.toFixed(2),
        item_available_sqm: sqm?.toFixed(2),
        [name]: value,
      };
    } else if (name == "item_received_pattas") {
      if (latestArray[i].item_length && latestArray[i].item_width) {
        let sqm = (Number(latestArray[i].item_length) * Number(latestArray[i].item_width) * Number(value)) / 10000;
        latestArray[i] = {
          ...latestArray[i],
          item_received_sqm: sqm?.toFixed(2),
          item_available_sqm: sqm?.toFixed(2),
        };
      }
      latestArray[i] = {
        ...latestArray[i],
        item_received_pattas: value,
        item_available_pattas: value,
      };
    } else {
      latestArray[i] = {
        ...latestArray[i],
        [name]: value,
      };
    }

    setFieldValue("item_details", latestArray);
  };

  const handleOnChangeQty = (setFieldValue, values, name, value, index) => {
    const i = index;
    let quantity = {
      item_received_quantities: {
        ...values?.item_details?.[i]?.item_received_quantities,
        [name]: Number(value),
      },
      item_received_pattas: values?.item_received_pattas,
    };

    const { natural, dyed, smoked } = quantity.item_received_quantities;
    const totalReceived = (parseFloat(natural) || 0) + (parseFloat(dyed) || 0) + (parseFloat(smoked) || 0);

    quantity.item_received_quantities.total = totalReceived;
    quantity.item_received_pattas = totalReceived;

    let sqm = 0;
    if (values?.item_details?.[i]?.item_length != 0 && values?.item_details?.[i]?.item_width != 0) {
      sqm =
        (Number(values?.item_details?.[i]?.item_length || 0) *
          Number(values?.item_details?.[i]?.item_width || 0) *
          (Number(totalReceived) || 0)) /
        10000;
    }
    const latestArray = [...values.item_details];
    latestArray[i] = {
      ...latestArray[i],
      item_received_quantities: quantity.item_received_quantities,
      item_received_pattas: quantity.item_received_pattas,
      item_available_quantities: quantity.item_received_quantities,
      item_available_pattas: quantity.item_received_pattas,
      ...(sqm !== 0 && { item_received_sqm: sqm }),
      ...(sqm !== 0 && { item_available_sqm: sqm }),
    };
    setFieldValue("item_details", latestArray);
  };

  useEffect(() => {
    if (state) {
      const country = Country.getAllCountries().filter((country) => country.name === state?.supplier_master?.supplier_country);
      const stateList = State.getAllStates().filter((country) => country.name === state?.supplier_master?.supplier_state);
      filterStates(country[0]?.isoCode);
      filterCities(stateList[0]?.isoCode);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await supplierDropDownList();
        if (data.data.status == true) {
          setSupplierNameList(data.data.result);
        }
        const itemNameList = await Axios.get(`${AllApis.dropdownList.item_name()}`);
        setItemNameList(itemNameList?.data?.result);

        const itemCodeList = await Axios.get(`${AllApis.dropdownList.item_code}`);
        setItemCodeList(itemCodeList?.data?.result);

        const gradeList = await Axios.get(`${AllApis.dropdownList.grade}`);
        setGradeList(gradeList?.data?.result);

        const palleteList = await Axios.get(`${AllApis.dropdownList.pallete}`);
        setPalleteList(palleteList?.data?.result);
      } catch (error) { }
    }
    fetchData();
  }, []);
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">{pathname === "/inventory/log/edit" ? "Edit Log Stock" : "Add Log Stock"}</Typography>
      <Div>
        <Formik
          validateOnChange={false}
          initialValues={stock}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, initialValues }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4, width: "100%" }}>
                <Grid
                  // sx={{
                  //   display: "flex",
                  //   flexWrap: "wrap",
                  //   width: "100%",
                  //   columnGap: 3,
                  // }}
                  container
                  rowSpacing={0}
                  columnSpacing={3}
                >
                  <Grid item xs={6} md={2}>
                    <Typography variant="h5">Inward Date*</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        format="DD-MM-YYYY"
                        maxDate={dayjs()}
                        defaultValue={values?.date_of_inward !== "" ? dayjs(values?.date_of_inward) : null}
                        onChange={(newValue) => {
                          setFieldValue("date_of_inward", newValue.startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
                        }}
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage name="date_of_inward" component="div" style={{ color: "red" }} />
                      </Div>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography variant="h5">Supplier Name*</Typography>
                    <Select
                      size="small"
                      sx={{ width: "100%" }}
                      name="supplier_name"
                      onChange={(e, options) => {
                        // for dropdown list
                        const country = Country.getAllCountries().filter((country) => country.name === options?.props?.action?.country);
                        const stateList = State.getAllStates().filter((country) => country.name === options?.props?.action?.state);

                        filterStates(country[0]?.isoCode);
                        filterCities(stateList[0]?.isoCode, stateList[0]?.countryCode);

                        setFieldValue("supplier_details.supplier_name", options?.props?.action?._id);
                        setFieldValue("supplier_details.bill_address", options?.props?.action?.bill_address);
                        setFieldValue("supplier_details.city", options?.props?.action?.city);
                        setFieldValue("supplier_details.state", options?.props?.action?.state);
                        setFieldValue("supplier_details.pincode", options?.props?.action?.pincode);
                        setFieldValue("supplier_details.gst_no", options?.props?.action?.gst_no);
                        setFieldValue("supplier_details.contact_Person_name", options?.props?.action?.contact_Person_name);
                        setFieldValue("supplier_details.country_code", options?.props?.action?.country_code);
                        setFieldValue("supplier_details.contact_Person_number", options?.props?.action?.contact_Person_number);
                        setFieldValue("supplier_details.email_id", options?.props?.action?.email_id);
                        setFieldValue("supplier_details.supplier_name", options?.props?.action?.supplier_name);
                        setFieldValue("supplier_details.country", options?.props?.action?.country);
                        setFieldValue("supplier_details.pan_no", options?.props?.action?.pan_no);
                        setFieldValue("supplier_details.delivery_address", options?.props?.action?.delivery_address);
                        if (options?.props?.action?.country === "India") {
                          setFieldValue("currency", "INR");
                        }

                        let updatedItemDetails = values?.item_details?.map((ele) => {
                          if (options?.props?.action?.state !== "Maharashtra") {
                            ele.igst = ele.gst;
                            ele.cgst = 0;
                            ele.sgst = 0;
                          } else {
                            ele.igst = 0;
                            ele.cgst = ele.gst / 2;
                            ele.sgst = ele.gst / 2;
                          }
                          return ele;
                        });
                        setFieldValue("item_details", updatedItemDetails);
                      }}
                      defaultValue={values?._id}
                    >
                      {supplierNameList?.map((item) => {
                        return (
                          <MenuItem value={item._id} action={item}>
                            {item?.supplier_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage name="supplier_details.supplier_name" component={"div"} style={{ color: "red" }}></ErrorMessage>
                  </Grid>

                  <Grid item xs={6} md={3}>
                    <Typography variant="h5">Branch Name*</Typography>
                    <Select
                      size="small"
                      sx={{ width: "100%" }}
                      name="supplier_name"
                      onChange={(e, options) => {
                        // setFieldValue("item_details", updatedItemDetails);
                      }}
                      defaultValue={values?._id}
                    >
                      {supplierNameList?.map((item) => {
                        return (
                          <MenuItem value={item._id} action={item}>
                            {item?.supplier_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage name="supplier_details.supplier_name" component={"div"} style={{ color: "red" }}></ErrorMessage>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Typography variant="h5">Invoice No.*</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="invoice_no"
                      onChange={(e) => {
                        setFieldValue("invoice_no", e.target.value);
                      }}
                      value={values?.invoice_no}
                      status={true}
                    />

                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage name={"invoice_no"} component="div" style={{ color: "red" }} />
                    </Div>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Typography variant="h5">Currency*</Typography>
                    <Autocomplete
                      size="small"
                      disabled={values?.supplier_details?.country === "India"}
                      options={currencyList || []}
                      name="currency"
                      value={values.currency}
                      getOptionLabel={(option) => option}
                      onChange={(e, newValue) => {
                        setFieldValue("currency", newValue);
                        let updatedItemDetails = values?.item_details?.map((ele) => {
                          ele.conversion_rate = 0;
                          ele.item_rate_per_sqm_for_currency = 0;
                          ele.item_rate_per_sqm = 0;
                          return ele;
                        });
                        setFieldValue("item_details", updatedItemDetails);
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          {option}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          // error={!!errors?.currency}

                          // helperText={errors?.currency || ""}
                          {...params}
                          variant="outlined"
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />

                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage name={"currency"} component="div" style={{ color: "red" }} />
                    </Div>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Typography variant="h5">Workers*</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="invoice_no"
                      onChange={(e) => {
                        setFieldValue("invoice_no", e.target.value);
                      }}
                      value={values?.invoice_no}
                      status={true}
                    />

                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage name={"invoice_no"} component="div" style={{ color: "red" }} />
                    </Div>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Typography variant="h5">Shift*</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="invoice_no"
                      onChange={(e) => {
                        setFieldValue("invoice_no", e.target.value);
                      }}
                      value={values?.invoice_no}
                      status={true}
                    />

                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage name={"invoice_no"} component="div" style={{ color: "red" }} />
                    </Div>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Typography variant="h5">Working Hours*</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="invoice_no"
                      onChange={(e) => {
                        setFieldValue("invoice_no", e.target.value);
                      }}
                      value={values?.invoice_no}
                      status={true}
                    />

                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage name={"invoice_no"} component="div" style={{ color: "red" }} />
                    </Div>
                  </Grid>
                </Grid>
              </Div>
              <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto">
                <Tab label="Item Details" {...a11yProps(0)} />
                <Tab label="Invoice Details" {...a11yProps(4)} />
              </Tabs>

              {/* <Div
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                  mt: 5,
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/inventory/log");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton loading={isSubmitting} variant="contained" size="small" type="submit">
                  Add Stock
                </LoadingButton>
              </Div> */}
              {/* <AddItemModal
                setFieldValue={setFieldValue}
                open={open}
                setOpen={setOpen}
                rowData={rowData}
                errors={errors}
                values={values}
              /> */}
            </Form>
          )}
        </Formik>
        <CustomTabPanel value={tabValue} index={0}>
          {true ? (
            <ItemDetailsTable setAddedItemDetailsList={setAddedItemDetailsList} addedItemDetailsList={addedItemDetailsList} />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
      </Div>
    </Div>
  );
}
