/* eslint-disable default-case */
import {
  Autocomplete,
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField
} from "@mui/material";
import { useEffect, useState } from "react";

import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Div from "@jumbo/shared/Div";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import AllApis from "app/Apis";
import FullScreenLoader from "app/components/ListingPageLoader";
import { CuttingDtailsModal } from "app/pages/Factory/Tapping/Modal/cuttingDetails";
import { getAllReadySheetFormList } from "app/redux/actions/inventoryAction";
import { displayDateFun } from "app/utils/constants/functions";
import { Axios } from "index";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { ReadySheetFormApprovalModal } from "../Modal/approvedModal";
import { ReadySheetFormRejectModal } from "../Modal/rejectModal";
import { ReadySheetFormSplitModal } from "../Modal/splitModal";
import { TappingDtailsModal } from "../Modal/tappingDetails";

export default function ReadySheetFormList({
  page,
  setPage,
  searchTerm,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
  palleteList,
}) {
  const dispatch = useDispatch();
  const { readySheetFormList, error, TotalPage, loading } = useSelector(
    (state) => state.inventoryReducer
  );
  const [open, setOpen] = useState(false);
  const [cutting, setCutting] = useState(false);
  const [reject, setReject] = useState(false);
  const [approved, setApproved] = useState(false);
  const [split, setSplit] = useState(false);
  const [rowData, setRowData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      getAllReadySheetFormList(searchTerm, sort, sortBy, newPage, filter)
    );
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "approved":
        setApproved(true); // Open the modal
        setRowData(menuItem.data);
        break;
      case "reject":
        setReject(true); // Open the modal
        setRowData(menuItem.data);
        break;
      case "spilit_group":
        setSplit(true); // Open the modal
        setRowData(menuItem.data);
        break;
      case "revert":
        revertReadySheetForm(menuItem?.data?.issued_for_tapping_id);
        break;
    }
  };
  const revertReadySheetForm = async (id) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Revert",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          const response = await Axios.post(
            AllApis.ready_sheet_form.revertReadySheetForm(id),
            {},
            config
          );
          if (response) {
            dispatch(getAllReadySheetFormList(searchTerm, sort, sortBy, page, filter));
            Swal.fire({
              title: "Reverted",
              icon: "success",
              timer: 1000,
              showConfirmButton: false,
            });
          }
        } catch (error) {
          Swal.fire({
            title: error?.response?.data?.error,
            icon: "error",
            timer: 1000,
            showConfirmButton: false,
          });
        } finally {
          setIsLoading(false);
        }
      }
    });
  };
  const editRawReadySheetForm = async (id, name, value) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    if (value != null) {
      try {
        const response = await Axios.patch(
          AllApis.ready_sheet_form.editRawReadySheetForm(id),
          {
            [name]:
              name == "ready_sheet_form_pallete_no" ? value.pallete_no : value,
            ready_sheet_form_physical_location: value.item_physical_location,
          },
          config
        );
        if (response?.data?.status) {
          Swal.fire({
            title: "Updated",
            icon: "success",
            timer: 1000,
            showConfirmButton: false,
          });
          dispatch(getAllReadySheetFormList(searchTerm, sort, sortBy, page, filter));
        }
      } catch (error) {
        Swal.fire({
          title: error?.response?.data?.message,
          icon: "error",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                py: 2,
              },
            }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  // px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_no"}
                  direction={sort}
                  onClick={() => handleSort("group_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "300px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "cutting_id.cutting_id.item_details.item_data.item_name"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "cutting_id.cutting_id.item_details.item_data.item_name"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "cutting_id.cutting_id.item_details.item_data.item_code"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "cutting_id.cutting_id.item_details.item_data.item_code"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={sortBy === "ready_sheet_form_length"}
                  direction={sort}
                  onClick={() => handleSort("ready_sheet_form_length")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Ready Sheet Form Length (cm)
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={sortBy === "ready_sheet_form_width"}
                  direction={sort}
                  onClick={() => handleSort("ready_sheet_form_width")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Ready Sheet Form Width (cm)
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={sortBy === "ready_sheet_form_no_of_pcs_original"}
                  direction={sort}
                  onClick={() =>
                    handleSort("ready_sheet_form_no_of_pcs_original")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Ready Sheet Form Original Pieces
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={sortBy === "ready_sheet_form_no_of_pcs_available"}
                  direction={sort}
                  onClick={() =>
                    handleSort("ready_sheet_form_no_of_pcs_available")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Ready Sheet Form Available Pieces
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={sortBy === "ready_sheet_form_rejected_pcs"}
                  direction={sort}
                  onClick={() => handleSort("ready_sheet_form_rejected_pcs")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Ready Sheet Form Rejected Pieces
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={sortBy === "ready_sheet_form_sqm"}
                  direction={sort}
                  onClick={() => handleSort("ready_sheet_form_sqm")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Ready Sheet Form Sq.m.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "200px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "ready_sheet_form_pallete_no"}
                  direction={sort}
                  onClick={() => handleSort("ready_sheet_form_pallete_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Pallet No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "ready_sheet_form_physical_location"}
                  direction={sort}
                  onClick={() =>
                    handleSort("ready_sheet_form_physical_location")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Physical Location
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "85px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Tapping Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "85px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Cutting Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "85px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "200px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Remarks
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Ready Sheet Form Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "created_employee_id.first_name"}
                  direction={sort}
                  onClick={() => handleSort("created_employee_id.first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>

              {permissions?.ready_sheet_form_edit == true && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "95px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                    position: "sticky",
                    right: 0,
                    height: "58px",
                    zIndex: 1,
                    bgcolor: "#7352C7",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {readySheetFormList?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.group_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {
                    row?.cutting_id?.[0]?.cutting_id?.[0]?.item_details
                      ?.item_data?.[0]?.item_name
                  }
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {
                    row?.cutting_id?.[0]?.cutting_id?.[0]?.item_details
                      ?.item_data?.[0]?.item_code
                  }
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.ready_sheet_form_length}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.ready_sheet_form_width}
                </TableCell>

                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.ready_sheet_form_no_of_pcs_original}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.ready_sheet_form_no_of_pcs_available}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.ready_sheet_form_rejected_pcs}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.ready_sheet_form_sqm}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  <Autocomplete
                    size="small"
                    id="tags-standard"
                    options={palleteList || []}
                    name="ready_sheet_form_pallete_no"
                    value={row?.ready_sheet_form_pallete_no}
                    isOptionEqualToValue={(option, value) =>
                      option.pallete_no === value
                    }
                    getOptionLabel={(option) => {
                      // console.log(option);
                      if (option instanceof Object) {
                        return option.pallete_no;
                      } else {
                        return option;
                      }
                    }}
                    onChange={(e, newValue) => {
                      editRawReadySheetForm(
                        row._id,
                        "ready_sheet_form_pallete_no",
                        newValue
                      );
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option?.pallete_no}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {/* <Select
                    sx={{ width: "100%" }}
                    size="small"
                    name="ready_sheet_form_physical_location"
                    value={row?.ready_sheet_form_physical_location}
                    onChange={(e) => {
                      editRawReadySheetForm(
                        row._id,
                        "ready_sheet_form_physical_location",
                        e.target.value
                      );
                    }}
                  >
                    <MenuItem value={"TERRACE"}>TERRACE</MenuItem>
                    <MenuItem value={"ROLL GODOWN"}>ROLL GODOWN</MenuItem>
                    <MenuItem value={"TEXTURE GODOWN"}>TEXTURE GODOWN</MenuItem>
                    <MenuItem value={"VEENEER SECTION"}>
                      VEENEER SECTION
                    </MenuItem>
                    <MenuItem value={"GODWON-1"}>GODWON-1</MenuItem>
                  </Select> */}
                  <TextField
                    multiline
                    size="small"
                    value={row?.ready_sheet_form_physical_location}
                    sx={{ width: "100%" }}
                    name="ready_sheet_form_physical_location"
                    disabled
                  />
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setOpen(true);
                      setRowData(row?.tapping_id);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setCutting(true);
                      setRowData(row?.cutting_id[0]?.cutting_id);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.status}
                </TableCell>
                <TableCell
                  style={{ resize: "none", width: "300px" }}
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.remarks ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: row?.remarks.replace(/\n/g, "<br />"),
                      }}
                    />
                  ) : (
                    "-"
                  )}
                </TableCell>

                {/* <TableCell sx={{ textAlign: "left", minWidth: "100px", px: 1 }}>
                  <TextField
                    style={{ resize: "none", width: "200px" }}
                    type="text"
                    size="small"
                    defaultValue={row?.remarks}
                    onChange={(e) => {
                      setRemarks(e.target.value);
                    }}
                    onBlur={(e) => {
                      const updatedRemarks = e.target.value;
                      editRawReadySheetForm(row._id, "remarks", updatedRemarks);
                    }}
                  />
                </TableCell> */}
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {displayDateFun(row?.created_at)}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.created_employee_id?.first_name}{" "}
                  {row?.created_employee_id?.last_name}
                </TableCell>

                {permissions?.ready_sheet_form_edit == true && (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      position: "sticky",
                      right: 0,
                      zIndex: 1,
                      bgcolor: "white",
                      textTransform: "capitalize",
                    }}
                  >
                    {row.status != "not available" ? (
                      <JumboDdMenu
                        icon={<MoreHorizIcon />}
                        menuItems={[
                          {
                            icon: <CheckCircleIcon />,
                            title: "Approved",
                            action: "approved",
                            data: row,
                          },
                          {
                            icon: <CancelIcon />,
                            title: "Reject",
                            action: "reject",
                            data: row,
                          },
                          {
                            icon: <CallSplitIcon />,
                            title: "Split Group",
                            action: "spilit_group",
                            data: row,
                          },
                          {
                            icon: <SettingsBackupRestoreIcon />,
                            title: "Revert",
                            action: "revert",
                            data: row,
                          },
                        ]}
                        onClickCallback={handleItemAction}
                      />
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
          {reject && (
            <ReadySheetFormRejectModal
              open={reject}
              setOpen={setReject}
              rowData={rowData}
            />
          )}
          {approved && (
            <ReadySheetFormApprovalModal
              open={approved}
              setOpen={setApproved}
              rowData={rowData}
            />
          )}
          {split && (
            <ReadySheetFormSplitModal
              open={split}
              setOpen={setSplit}
              rowData={rowData}
            />
          )}
        </Table>

        {open && (
          <TappingDtailsModal open={open} setOpen={setOpen} rowData={rowData} />
        )}
        {cutting && (
          <CuttingDtailsModal
            open={cutting}
            setOpen={setCutting}
            rowData={rowData}
          />
        )}
        <Pagination size="medium"
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py:1
          }}
        />
      </TableContainer>
    </>
  );
}
