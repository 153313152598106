import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AllApis from "app/Apis";
import { getAllRawMaterial } from "app/redux/actions/inventoryAction";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

export const DyingModal = ({ open, setOpen, rowData, setAddGroup }) => {
  const [data, setData] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [quantity, setQuantity] = useState({
    item_id: rowData?._id,
    issued_dying_quantity: 0,
    // issued_dying_quantity: {
    //   natural: 0,
    //   dyed: 0,
    //   smoked: 0,
    //   total: 0,
    // },
  });
  const dispatch = useDispatch();
  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    let smokingQty = {
      ...quantity,
      item_id: rowData?._id,
      [name]: Number(value),
    };

    setQuantity(smokingQty);
  };

  const handleIssueForSmoking = async () => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    try {
      setSubmitting(true);
      const response = await Axios.post(
        AllApis.raw_material.addSingleDying,
        quantity,
        config
      );
      setOpen(false);
      dispatch(getAllRawMaterial("", "desc", "updated_at", 1, {}));
      if (response?.data?.status) {
        Swal.fire({
          title: "Dyed Issued Successful",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        setAddGroup([]);
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setData(rowData);
    setQuantity({
      issued_dying_quantity: 0,
      // issued_dying_quantity: {
      //   natural: 0,
      //   dyed: 0,
      //   smoked: 0,
      //   total: 0,
      // },
      item_id: rowData?._id,
    });
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <Div
        sx={{
          display: "flex",
          minWidth: 600,
        }}
      ></Div>
      <DialogContent>
        <Div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Div>
            <Typography variant="h4" fontWeight={600}>
              Issue Dying Quantity
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "#7352C7",
                    color: "white",
                    "& .MuiTableCell-root": {
                      py: 2,
                    },
                  }}
                >
                  {rowData?.item_code?.toLowerCase() == "natural" && (
                    <TableCell sx={{ color: "white" }}>
                      Available Natural
                    </TableCell>
                  )}
                  {rowData?.item_code?.toLowerCase() == "dyed" && (
                    <TableCell sx={{ color: "white" }}>
                      Available Dyed
                    </TableCell>
                  )}
                  {rowData?.item_code?.toLowerCase() == "smoked" && (
                    <TableCell sx={{ color: "white" }}>
                      Available Smoked
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ bgcolor: "#EDEBEB" }}>
                  <TableCell>
                    {" "}
                    <TextField
                      sx={disabledStyling}
                      disabled
                      size="small"
                      value={data?.item_available_pattas}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "#7352C7",
                    color: "white",
                    "& .MuiTableCell-root": {
                      py: 2,
                    },
                  }}
                >
                  {rowData?.item_code?.toLowerCase() == "natural" && (
                    <TableCell sx={{ color: "white" }}>Issue Natural</TableCell>
                  )}
                  {rowData?.item_code?.toLowerCase() == "dyed" && (
                    <TableCell sx={{ color: "white" }}>Issue Dyed</TableCell>
                  )}
                  {rowData?.item_code?.toLowerCase() == "smoked" && (
                    <TableCell sx={{ color: "white" }}>Issue Smoked</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ bgcolor: "#EDEBEB" }}>
                  {/* {rowData?.item_code?.toLowerCase() == "natural" && ( */}
                  <TableCell sx={{ width: "100%" }}>
                    {" "}
                    <TextField
                      size="small"
                      name="issued_dying_quantity"
                      type="number"
                      sx={{ width: "100%" }}
                      inputProps={{
                        min: 0,
                        max: data?.item_available_pattas,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseInt(e.target.value + e.key, 10);

                        if (
                          isNaN(inputValue) ||
                          inputValue < 0 ||
                          inputValue > data?.item_available_pattas
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleOnChange(e)}
                      defaultValue={quantity?.issued_dying_quantity}
                    />
                  </TableCell>
                  {/* )} */}
                </TableRow>
              </TableBody>
            </Table>
            <Div sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <LoadingButton
                loading={isSubmitting}
                disabled={quantity?.issued_dying_quantity <= 0}
                variant="contained"
                type="submit"
                sx={{ mx: "auto", textAlign: "center" }}
                onClick={() => handleIssueForSmoking()}
              >
                Issue For Dying
              </LoadingButton>
            </Div>
          </Div>
        </Div>
      </DialogContent>
    </Dialog>
  );
};
