import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Paper,
} from "@mui/material";
import { City, Country, State } from "country-state-city";
import ContactPersonCrad from "./ContactPersonCrad";
import Div from "@jumbo/shared/Div";
import { outerDiv1 } from "app/utils/constants/dropdowns";
import { Axios } from "index";
import Swal from "sweetalert2";

function BranchAccordionItem({
  branch,
  handleOpenModal,
  // handleUpdation,
  page,
  rowsPerPage,
  TotalPage,
  setToggle,
}) {
  const [expanded, setExpanded] = useState(false);
  const [branchData, setBranchData] = useState(branch);
  const [countryList] = useState(Country.getAllCountries());
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    if (branchData.country) {
      filterStates(Country.getCountryByCode(branchData.country)?.isoCode);
    }
  }, [branchData.country]);

  useEffect(() => {
    if (branchData.state) {
      filterCities(
        State.getStateByCode(branchData.state)?.isoCode,
        branchData.country
      );
    }
  }, [branchData.state]);

  const handleInputChange = (field, value) => {
    setBranchData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (branchData.country) {
      // Find the ISO code for the country based on the name
      const countryIsoCode = Country.getAllCountries().find(
        (country) => country.name === branchData.country
      )?.isoCode;

      if (countryIsoCode) {
        handleInputChange("countryIsoCode", countryIsoCode); // Update with country ISO code
        filterStates(countryIsoCode); // Fetch states for the selected country
      }
    }
  }, [branchData.country]);

  useEffect(() => {
    if (branchData.state && branchData.countryIsoCode) {
      // Find the ISO code for the state based on the name
      const stateIsoCode = State.getAllStates().find(
        (state) =>
          state.name === branchData.state &&
          state.countryCode === branchData.countryIsoCode
      )?.isoCode;

      if (stateIsoCode) {
        handleInputChange("stateIsoCode", stateIsoCode); // Update with state ISO code
        filterCities(stateIsoCode, branchData.countryIsoCode); // Fetch cities for the selected state and country
      }
    }
  }, [branchData.state, branchData.countryIsoCode]);

  // Filtering the states based on the country ISO code
  const filterStates = (countryIsoCode) => {
    const filteredStates = State.getAllStates().filter(
      (state) => state.countryCode === countryIsoCode
    );
    setStateList(filteredStates);
  };

  // Filtering the cities based on state and country ISO codes
  const filterCities = (stateIsoCode, countryCode) => {
    const filteredCities = City.getAllCities().filter(
      (city) =>
        city.stateCode === stateIsoCode && city.countryCode === countryCode
    );
    setCityList(filteredCities);
  };

  const handleUpdation = async (branchId) => {
    try {
      const updatedData = branchData;
      const { data } = await Axios.post(
        `/supplier-master/update-supplier-branch?id=${branchId}`,
        updatedData
      );
      if (data.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: data.message,
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
        setToggle((prevState) => !prevState);
      } else {
        Swal.fire({
          icon: "error",
          title: data.message,
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.log("err updating branch data => ", error.message);
    }
  };

  return (
    <Accordion
      component={Paper}
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
      sx={{ mb: 3 }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <div className="flex flex-row items-center justify-between w-full">
          <Typography variant="h5">{branch.branch_name}</Typography>
          <Button
            size="small"
            sx={{ marginRight: "10px" }}
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation();
              handleOpenModal(branch._id);
            }}
          >
            Add Contact Person
          </Button>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <ContactPersonCrad
          totalPages={TotalPage}
          personData={branch.contact_person}
          page={page}
          setToogle={setToggle}
          rowsPerPage={rowsPerPage}
        />
        <div className="grid grid-flow-row lg:grid-cols-4 grid-cols-2 gap-5 mt-6">
          <div className="flex flex-col items-start">
            <Typography variant="h5">Branch Name</Typography>
            <TextField
              size="small"
              fullWidth
              defaultValue={branch.branch_name}
              onChange={(e) => handleInputChange("branch_name", e.target.value)}
            />
          </div>
          <div className="flex flex-col items-start">
            <Typography variant="h5">Pincode</Typography>
            <TextField
              size="small"
              fullWidth
              defaultValue={branch.pincode}
              onChange={(e) => handleInputChange("pincode", e.target.value)}
            />
          </div>
          <div className="flex flex-col items-start">
            <Typography variant="h5">GST Number</Typography>
            <TextField
              size="small"
              fullWidth
              defaultValue={branch.gst_number}
              onChange={(e) => handleInputChange("gst_number", e.target.value)}
            />
          </div>
          <div className="flex flex-col items-start">
            <Typography variant="h5">Address</Typography>
            <TextField
              size="small"
              fullWidth
              defaultValue={branch.address}
              onChange={(e) => handleInputChange("address", e.target.value)}
            />
          </div>
          <Grid item xs={6} md={3}>
            <Div sx={outerDiv1}>
              <Typography variant="h5">Country</Typography>
              <Select
                fullWidth
                value={branchData.country || ""}
                size="small"
                onChange={(e) => {
                  const selectedCountry = e.target.value;
                  const selectedCountryIsoCode =
                    Country.getCountryByCode(selectedCountry)?.isoCode;
                  handleInputChange("country", selectedCountry);
                  handleInputChange("countryIsoCode", selectedCountryIsoCode);
                  filterStates(selectedCountryIsoCode);
                }}
              >
                <MenuItem value="">Select</MenuItem>
                {countryList.map((country) => (
                  <MenuItem value={country.name} key={country.isoCode}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
              <Div style={{ height: "30px" }}></Div>
            </Div>
          </Grid>
          <Grid item xs={6} md={3}>
            <Div sx={outerDiv1}>
              <Typography variant="h5">State</Typography>
              <Select
                fullWidth
                value={branchData.state || ""}
                size="small"
                onChange={(e) => {
                  const selectedState = e.target.value;
                  const selectedStateIsoCode =
                    State.getStateByCode(selectedState)?.isoCode;
                  handleInputChange("state", selectedState);
                  handleInputChange("stateIsoCode", selectedStateIsoCode);
                  filterCities(selectedStateIsoCode, branchData.countryIsoCode);
                }}
              >
                <MenuItem value="">Select</MenuItem>
                {stateList.map((state) => (
                  <MenuItem value={state.name} key={state.isoCode}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
              <Div style={{ height: "30px" }}></Div>
            </Div>
          </Grid>
          <Grid item xs={6} md={3}>
            <Div sx={outerDiv1}>
              <Typography variant="h5">City</Typography>
              <Select
                fullWidth
                value={branchData.city || ""}
                size="small"
                onChange={(e) => handleInputChange("city", e.target.value)}
              >
                <MenuItem value="">Select</MenuItem>
                {cityList.map((city) => (
                  <MenuItem value={city.name} key={city.isoCode}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
              <Div style={{ height: "30px" }}></Div>
            </Div>
          </Grid>
          <div className="flex flex-col items-start">
            <Typography variant="h5">Web URL</Typography>
            <TextField
              size="small"
              fullWidth
              defaultValue={branch.web_url}
              onChange={(e) => handleInputChange("web_url", e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-row items-center justify-center gap-6 mt-6">
          {/* <Button
            variant="contained"
            color="secondary"
            // sx={{ py: 1 }}
            size="small"
            onClick={() => {
              Swal.fire({
                title: "Are you sure you want to cancel?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
              }).then((result) => {
                if (result.isConfirmed) {
                  // navigate("/master/supplier");
                }
              });
            }}
          >
            Cancel
          </Button> */}
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              handleUpdation(branch._id);
            }}
          >
            Save
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default BranchAccordionItem;
